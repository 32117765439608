export const validateDecimalInput = value => value.match(/^(\d*\.{0,1}\d{0,2}$)/);

export const validateDecInputTo3Digits = value => value.match(/^(\d*\.{0,1}\d{0,3}$)/);

export const validateOTPInput = value => value.match(/^(\d{1,6}$)/);

export const validatePincodeInput = value => value.match(/^(\d{6}$)/)

export const validateUTRInput = value => !!value && value.match(/^(\d{12}$)/)

export const validateMobileNoInput = value => value.match(/^(\d{1,10}$)/);

export const validateNegDecimalInput = value => value.match(/^(-?\d*\.{0,1}\d{0,2}$)/);

export const validateEmailInput = email => 
  String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );