import { useEffect, useRef, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { FaEye } from 'react-icons/fa';

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import classes from './CompareTransactionsPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import { parseFloat2DecimalHeader, parseToUIDate } from '../../../../_helpers/utils';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';

const CompareTransactionsPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [walletFile, setWalletFile] = useState(null);
    const [bankFile, setBankFile] = useState(null);
    const [imported, setImported] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [enableExport, setEnableExport] = useState(false);
    const dateFieldsToParse = ['topupDate', 'bankDate'];
    const [exportTotalData, setExportTotalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    const filePopoverRef = useRef();
    const arrowStyle = {
        left: 'calc((0.5rem + 1px) * -1)',
        width: '0.5rem',
        height: '1rem',
        margin: '0.3rem 0'
    };

    const tableHeaders = ['Firm Name', 'Mobile Number', 'UTR', 'Topup Date', 'Topup Amount', 'Bank Date', 'Bank Amount', 'Status'];
    const dataFields = ['firmName', 'mobileNumber', 'utr', 'topupDate', 'topupAmount', 'bankDate', 'bankAmount', 'status'];
    const csvHeaders = [
        { label: 'Firm Name', key: 'firmName' },
        { label: 'Mobile Number', key: 'mobileNumber' },
        { label: 'UTR', key: 'utr' },
        { label: 'Topup Date', key: 'topupDate' },
        { label: 'Topup Amount', key: 'topupAmount' },
        { label: 'Bank Date', key: 'bankDate' },
        { label: 'Bank Amount', key: 'bankAmount' },
        { label: 'Status', key: 'status' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Firm Name': 'left',
            'Mobile Number': 'center',
            'UTR': 'center',
            'Topup Date': 'center',
            'Topup Amount': 'center',
            'Bank Date': 'center',
            'Bank Amount': 'center',
            'Status': 'center'
        },
        widthConfig: {
            'Firm Name': 'auto',
            'Mobile Number': '136px',
            'UTR': '130px',
            'Topup Date': '128px',
            'Topup Amount': '132px',
            'Bank Date': '128px',
            'Bank Amount': '128px',
            'Status': '128px'
        },
        alignConfig: {
            'firmName': 'left',
            'mobileNumber': 'right',
            'utr': 'right',
            'topupDate': 'center',
            'topupAmount': 'right',
            'bankDate': 'center',
            'bankAmount': 'right',
            'status': 'center'
        }
    }

    const bankCsvColumns = ['Txn Posted Date', 'Description', 'Transaction Amount(INR)'];
    const walletCsvColumns = ['Firm Name', 'Mobile No', 'Topup Date', 'Remarks', 'Ref No', 'Topup Amt'];
    const popoverContent =  (
        <>
            <div>
                <h5>Wallet CSV Headers :</h5>
                <table className='table-bordered'>
                    <thead className='thead-light'>
                        <tr>
                            { walletCsvColumns.map( (column, index) => <th key={`wallet-${index}`} className={classes.popoverTableRow}>{column}</th> )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            { walletCsvColumns.map( index => <td key={`wallet-${index}`} className={classes.popoverTableRow}>&nbsp;</td> )}
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr/>
            <div>
                <h5>Bank CSV Headers :</h5>
                <table className='table-bordered'>
                    <thead>
                        <tr>
                            { bankCsvColumns.map( (column, index) => <th key={`bank-${index}`} className={classes.popoverTableRow}>{column}</th> )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            { bankCsvColumns.map( index => <td key={`bank-${index}`} className={classes.popoverTableRow}>&nbsp;</td> )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
        )
    

    useEffect(() => {
        setErrorMessage(null);
    }, [walletFile, bankFile]);

    const compareClickHandler = e => {
        e.preventDefault(); // Stop form submit
        clearState();
        setIsLoading(true);
        if (walletFile != null  && bankFile != null) {
            if (checkFileType(walletFile) && checkFileSize(walletFile) && checkFileType(bankFile) && checkFileSize(bankFile)) {
                fileUpload();
            }
        } else {
            setIsLoading(false);
            setErrorMessage('Choose file(s) to upload.');
        }
    }

    const viewFormatClickHandler = e => {
        e.preventDefault();

    }

    const clearState = () => {
        setImported(false);
        setEnableExport(false);
        setErrorMessage(null);
        setSuccessMessage(null);
    }
    
    const walletFileChangeHandler = e => {
        clearState();
        setWalletFile(e.target.files[0]);
    }

    const bankFileChangeHandler = e => {
        clearState();
        setBankFile(e.target.files[0]);
    }

    const checkFileType = uploadedFile => {
        let err = '';
        const types = [
            'text/csv',
            // 'text/plain',
            'application/csv',
            'text/comma-separated-values',
            'application/excel',
            'application/vnd.ms-excel',
            'application/vnd.msexcel',
            // 'text/anytext',
            'application/octet-stream',
            // 'application/txt'
        ];
        if ( !types.includes(uploadedFile.type) ) {
            err += `${uploadedFile.name} file format not supported. Upload CSV file.\n`;
        }
        if (err !== '') {
            setIsLoading(false);
            setErrorMessage(err);
            return false; 
        }
        return true;
    }
    
    const checkFileSize = uploadedFile => {
        let size = 100000000;
        let err = ''; 
        if (uploadedFile.size > size) {
            err += `${uploadedFile.name} file size is more than 100MB.\n`;
        }
        if (err !== '') {
            setIsLoading(false);
            setErrorMessage(err);
            return false;
        }
        return true;
    }
    
    const fileUpload = () => {
        const formData = new FormData();
        formData.append('walletFile', walletFile);
        formData.append('bankFile', bankFile);
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Token': localStorage.getItem('accessToken')
            },
            body: formData
        };
        fetch(`${CONSTANTS.API_URL}DataUpload/FileCompare`, requestOptions)
        .then(handleResponse)
        .then(
            result => {
                if(result.data && result.data.results.length > 0) {
                    setIsLoading(false);
                    setImported(true);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    !!dateFieldsToParse && parseToUIDate(result.data.results, dateFieldsToParse);
                    setGridData(result.data.results);
                    const total = result.data.total[0];
                    const headerKeysToParse = ['topupAmount', 'bankAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        '',
                        total.topupAmount,
                        '',
                        total.bankAmount,
                        ''
                    ]);
                    setExportTotalData({
                        firmName: 'Total',
                        mobileNumber: '',
                        utr: '',
                        topupDate: '',
                        topupAmount: total.topupAmount,
                        bankDate: '',
                        bankAmount: total.bankAmount,
                        status: ''
                    });
                    setEnableExport(true);
                } else if(result.error) {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                    setEnableExport(false);
                } else {
                    setIsLoading(false);
                    setSuccessMessage('No mismatch found!');
                    setEnableExport(false);
                }
            },
            (error) => {
                setImported(false);
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {setExportFileName('CompareTransactions')}

    const toggle = () => setIsPopoverOpen(!isPopoverOpen)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.firmName && data.firmName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.mobileNumber && data.mobileNumber.toString().includes(filterKeyword.toLowerCase())) ||
            (data.utr && data.utr.toString().includes(filterKeyword.toLowerCase())) ||
            (data.topupDate && data.topupDate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.bankDate && data.bankDate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.bankAmount && data.bankAmount.toString().includes(filterKeyword.toLowerCase())) ||
            (data.status && data.status.toLowerCase().includes(filterKeyword.toLowerCase()))
        )
    }
    
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Compare Transactions'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '12px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Wallet Transaction File : </label>
                                    <input type='file' name='' accept='.csv' required className='form-control' onChange={walletFileChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Bank Transaction File : </label>
                                    <input type='file' name='' accept='.csv' required className='form-control' onChange={bankFileChangeHandler} />
                                </div>
                                <Button btnClass='btn btn-primary mr-3' onClick={compareClickHandler} btnText='Compare' btnType='Compare' />
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName}
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </form>
                            <div>
                                <Popover
                                    isOpen={isPopoverOpen}
                                    positions={['bottom']}
                                    padding={10}
                                    onClickOutside={() => setIsPopoverOpen(false)}
                                    ref={filePopoverRef} // if you'd like a ref to your popover's child, you can grab one here
                                    content={({ position, childRect, popoverRect }) => (
                                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                            position={position}
                                            childRect={childRect}
                                            popoverRect={popoverRect}
                                            arrowColor={'#313a46'}
                                            arrowSize={10}
                                            arrowStyle={{ opacity: 0.8 }}
                                            className='popover-arrow-container'
                                            arrowClassName='popover-arrow'
                                        >
                                            <div
                                                style={{ 
                                                    backgroundColor: '#313a46', 
                                                    color: '#eeee', 
                                                    opacity: 0.8, 
                                                    borderRadius: '4px', 
                                                    padding: '8px', 
                                                    width: 'auto',
                                                    fontSize: '.875rem',
                                                    wordWrap: 'break-word'
                                                }}
                                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                            >
                                                {popoverContent}
                                            </div>
                                        </ArrowContainer>
                                    )}
                                    >
                                    <button className='btn btn-primary' onClick={toggle}>
                                        File Format <FaEye className={classes.btnIcon} />   
                                    </button>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { successMessage && <Alert alertClassName='success' message={successMessage} /> }
                    { imported && gridData.length > 0 && (
                        <Table 
                            tableHeaders={tableHeaders} 
                            dataFields={dataFields} 
                            tableConfig={tableConfig}
                            pageCount={pageCount} 
                            sort={true}
                            actions={false}
                            edit={false} 
                            delete={false} 
                            filter={true}
                            paginate={false}
                            rowClick={false}
                            gridData={gridData}
                            totalData={totalData}
                            invalidCheck
                            invalidCheckKey='status'
                            invalidCheckValue='Not Matched'
                            filterKeyword={filterKeyword}
                            onFilterData={filterDataHandler}
                        />
                    )}
                    { !imported && (<div className={classes.noData}>Import Wallet and Bank transaction files to view comparison.</div>) }
                </PageLayout>
            )}
        </>
    );
}

export default CompareTransactionsPage;