import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';
import CONSTANTS from '../../../../_constants/constants';
import classes from './SupplierWiseCurrencyPurchasePage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseCurrencyPurchasePage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Supplier', 'Operator', 'Purchase', 'Commission', 'Adjustment', 'Total Received'];
    const dataFields = ['supplierName', 'operatorName', 'purchase', 'commission', 'adjustment', 'total'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Purchase', key: 'purchase' },
        { label: 'Commission', key: 'commission' },
        { label: 'Adjustment', key: 'adjustment' },
        { label: 'Total Received', key: 'total' },
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left', 
            'Operator': 'center', 
            'Purchase': 'center',
            'Commission': 'center',
            'Adjustment': 'center',
            'Total Received': 'center',
        },
        widthConfig: {
            'Supplier': 'auto', 
            'Operator': '122px', 
            'Purchase': '122px',
            'Commission': '122px',
            'Adjustment': '122px',
            'Total Received': '122px',
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'purchase': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'total': 'right'
        }
    };

    useEffect(() => {
        getSupplierList();
        getOperatorList();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate, supplierId, operatorId]);

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setSupplierId(result.data.results[0].supplierId);
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorId(result.data.results[0].operatorId);
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseSummary?startDate=${fromDate}&endDate=${toDate}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseSummary?startDate=${fromDate}&endDate=${toDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseSummary?startDate=${fromDate}&endDate=${toDate}&supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseSummary?startDate=${fromDate}&endDate=${toDate}&supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    const keysToParse = ['purchase', 'commission', 'adjustment', 'total'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['purchaseAmount', 'commission', 'adjustment', 'totalAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.purchaseAmount,
                        total.commission,
                        total.adjustment,
                        total.totalAmount
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        purchase: total.purchaseAmount,
                        commission: total.commission,
                        adjustment: total.adjustment,
                        total: total.totalAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const showClickHandler = () => {
        setIsLoading(true);
        getReportData();
    }

    const rowClickHandler = rowData => {
        let detailedPage = window.open('/pavathi/supplier-currency-purchase-detailed');
        detailedPage.startDate = fromDate;
        detailedPage.endDate = toDate;
        detailedPage.supplierId = rowData.supplierId;
        detailedPage.supplierName = rowData.supplierName;
        detailedPage.operatorId = rowData.operatorId;
        detailedPage.operatorName = rowData.operatorName;
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    const supplierChangeHandler = event => setSupplierId(event.target.value)
    
    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const getExportFileName = () => {
        let exportFilename = 'SupplierWiseCurrencyPurchase';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += supplierId !== '-1' ? '_' + supplierData.find(data => data.supplierId == supplierId)['supplierName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Supplier Wise Currency Purchase'>
                    <div className='row mb-1'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Supplier : </label>
                                    <select className='form-select customSelect' value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} className='dropdownItem' value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select className='form-select customSelect' value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} className='dropdownItem' value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' />
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        totalData={totalData}
                        pageCount={pageCount} 
                        sort={false}
                        actions={true} 
                        view={true}
                        paginate={true}
                        filter={false}
                        gridData={gridData}
                        onRowClick={rowClickHandler}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default SupplierWiseCurrencyPurchasePage;