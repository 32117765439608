import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseCurrencyPendingDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Supplier', 'Operator', 'Date', 'Opening', 'Purchase', 'Received', 'Commission', 'Pending'];
    const dataFields = ['supplierName', 'operatorName', 'transactionDate', 'totalOpening', 'totalPurchase', 'receivedAmount', 'receivedCommission', 'totalPending'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Date', key: 'transactionDate' },
        { label: 'Opening', key: 'totalOpening' },
        { label: 'Purchase', key: 'totalPurchase' },
        { label: 'Received', key: 'receivedAmount' },
        { label: 'Commission', key: 'receivedCommission' },
        { label: 'Pending', key: 'totalPending' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left', 
            'Operator': 'center', 
            'Date': 'center', 
            'Opening': 'center',
            'Purchase': 'center',
            'Received': 'center',
            'Commission': 'center',
            'Pending': 'center'
        },
        widthConfig: {
            'Supplier': 'auto', 
            'Operator': '154px', 
            'Date': '154px', 
            'Opening': '154px',
            'Purchase': '154px',
            'Received': '154px',
            'Commission': '154px',
            'Pending': '154px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'transactionDate': 'center',
            'totalOpening': 'right',
            'totalPurchase': 'right',
            'receivedAmount': 'right',
            'receivedCommission': 'right',
            'totalPending': 'right'
        }
    };

    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        let supplierId = window.supplierId;
        let operatorId = window.operatorId;
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPendingDetailed?startDate=${window.startDate}&endDate=${window.endDate}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPendingDetailed?startDate=${window.startDate}&endDate=${window.endDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPendingDetailed?startDate=${window.startDate}&endDate=${window.endDate}&supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPendingDetailed?startDate=${window.startDate}&endDate=${window.endDate}&supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.transactionDate = data.transactionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['totalOpening', 'totalPurchase', 'receivedAmount', 'receivedCommission', 'totalPending'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['openingAmount', 'purchaseAmount', 'receivedAmount', 'commission', 'pendingAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        total.openingAmount,
                        total.purchaseAmount,
                        total.receivedAmount,
                        total.commission,
                        total.pendingAmount
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        transactionDate: '',
                        totalOpening: total.openingAmount,
                        totalPurchase: total.purchaseAmount,
                        receivedAmount: total.receivedAmount,
                        receivedCommission: total.commission,
                        totalPending: total.pendingAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'SupplierWiseCurrencyPendingDetailed';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.supplierName !== '' ? '_' + window.supplierName : '';
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Supplier Wise Currency Pending Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default SupplierWiseCurrencyPendingDetailedPage;