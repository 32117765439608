import PageLayout from '../../../Layout/PageLayout/PageLayout';
import classes from './FAQPage.module.css';

const FAQPage = () => {

    const company = localStorage.getItem('company').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    const copyRight = localStorage.getItem('copyRight').split('.')[0].replace(/\d+/g, '');

    return (
        <PageLayout pageTitle='FAQ'>    
            <div className='text-center'>
                <h3>Frequently Asked Questions</h3>
                <p className='text-muted mt-3'> Here are some of the basic types of questions our customers might have. <br/>For more
                    information please contact us!</p>
                {/* <button type='button' className='btn btn-success btn-sm mt-2'><i className='mdi mdi-email-outline mr-1'></i> Email us your question</button> */}
            </div>

            <div className='row pt-5 pb-3'>
                <div className='col-lg-5 offset-lg-1'>
                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion} data-wow-delay='.1s'>What type of view does {company} offer?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>You can view Dashboard, Transactions and Reports and other pages with update feature. Contact Us via email or phone for more information.</p>
                    </div>

                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>What is the current pricing of the software?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>Please check the Plans List under Plans section. You can choose a plan that best suits your needs or contact us for more details on the benefits. </p>
                    </div>

                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>How will I know when my current plan expires?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>Current plan and expiry date will be visible on the page header post login. Pay before expiry and continue the same plan or choose a different plan.</p>
                    </div>

                    {/* <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion} data-wow-delay='.1s'>What is Lorem Ipsum?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    </div> */}

                </div>

                <div className='col-lg-5'>
                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>How do I get help with the Software?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>Use our dedicated support email provided below to send your issues or feedback. We will respond at the earliest. We are here to help you.</p>
                    </div>

                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>Will you regularly give updates of {company}?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>{company} software will be updated based on requirements, to improve user experience. All new updates will be made available to our customers.</p>
                    </div>

                    <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>License &amp; Copyright</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>{company} software is provided to you by {copyRight}. We offer other products as well. Contact us for more details.</p>
                        {/* <p className={`${classes.faqAnswer} mb-4`}>{company} software is provided to you by PaperFree Solutions. We offer other products as well. Please visit <a href='https://paperfreesolutions.com/' target='_blank'>PaperFree Solutions</a> website for details.</p> */}
                    </div>

                    {/* <div>
                        <div className={classes.faqQuestionBox}>Q.</div>
                        <h4 className={classes.faqQuestion}>Is safe use Lorem Ipsum?</h4>
                        <p className={`${classes.faqAnswer} mb-4`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    </div> */}

                </div>
            </div>
        </PageLayout>
    )
}

export default FAQPage;