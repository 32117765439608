import SetOpeningDateModal from './SetOpeningDateModal';
import DeleteLapuSalesModal from './DeleteLapuSales/DeleteLapuSalesModal';
import DeleteLapuTransactionsModal from './DeleteLapuTransactions/DeleteLapuTransactionsModal';

const SettingsPage = props => {

    return (
        <>
            { props.modalName === 'Set Opening Date' && 
                <SetOpeningDateModal 
                    modalName={props.modalName}
                    isModalOpened={props.modalIsOpen}
                    onCloseModal={props.onCloseModal}
                    />
                }
            { props.modalName === 'Delete Lapu Sales' && 
                <DeleteLapuSalesModal 
                    modalName={props.modalName}
                    isModalOpened={props.modalIsOpen}
                    onCloseModal={props.onCloseModal}
                />
            }
            { props.modalName === 'Delete All Lapu Transactions' && 
                <DeleteLapuTransactionsModal 
                    modalName={props.modalName}
                    isModalOpened={props.modalIsOpen}
                    onCloseModal={props.onCloseModal}
                />
            }
            {/* { props.settingsOption === 'Update Company' && <UpdateCompanyPage />} */}
            {/* <PageTitle title='Settings' />
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body customCardBody'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            <div className='row'>
                                <div className='col-12'>
                                    <h5>Lapu Sales</h5>
                                    <form className='form-inline'>
                                        <div className={`form-group ${classes.inputContainer}`}>
                                            <label className='col-form-label inputLabel'>Date : </label>
                                            <input type='date' required className='form-control inputField' value={date} onChange={dateChangeHandler} />
                                        </div>
                                        <div className={`form-group ${classes.inputContainer}`}>
                                            <label className='col-form-label inputLabel'>Provider : </label>
                                            <select value={providerId} className='form-select customSelect' onChange={providerChangeHandler} >
                                                { providerData.map( (value, index) => {
                                                    return <option key={`provider-${index}`} value={value.providerId} className='dropdownItem'>{value.providerName}</option>
                                                }) }
                                            </select>
                                        </div>
                                        <button type='button' className='btn btn-primary' onClick={deleteLapuSalesHandler}>
                                            <span className='btnText'>Delete Lapu Sales</span>
                                            <FaTrashAlt title='Delete Lapu Sales' className='addIcon' style={{paddingBottom: '2px'}} />  
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default SettingsPage;
