import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { getAPIUrl, parseToUIDate, uiMonthStartDate } from '../../../../_helpers/utils';
import classes from './PaymentLog.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';
import PaymentLogModal from './PaymentLogModal';

const PaymentLogPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fromDate, setFromDate] = useState(uiMonthStartDate());
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0,10));
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const dateFieldsToParse = ['paymentDate'];
    
    const tableHeaders = ['Date', 'Company Code', 'Company Name', 'Plan Name', 'Validity(days)', 'UTR No.', 'Amount', 'Amount Received', 'Plan Updated'];
    const dataFields = ['paymentDate', 'companyCode', 'companyName', 'planName', 'validity', 'utrNo', 'amount', 'amountReceived', 'planUpdated'];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'center', 
            'Company Code': 'center', 
            'Company Name': 'center', 
            'Plan Name': 'center', 
            'Validity(days)': 'center', 
            'UTR No.': 'center', 
            'Amount': 'center', 
            'Amount Received': 'center', 
            'Plan Updated': 'center'
        },
        widthConfig: {
            'Date': '140px', 
            'Company Code': '140px', 
            'Company Name': 'auto', 
            'Plan Name': '160px', 
            'Validity(days)': '120px', 
            'UTR No.': '150px', 
            'Amount': '100px', 
            'Amount Received': '140px', 
            'Plan Updated': '140px'
        },
        alignConfig: {
            'paymentDate': 'center',
            'companyCode': 'right',
            'companyName': 'center',
            'planName': 'center',
            'validity': 'right',
            'utrNo': 'right', 
            'amount': 'right',
            'amountReceived' : 'center',
            'planUpdated': 'center'
        }
    };

    useEffect(() => {
        getPaymentLogData();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
    }, [fromDate, toDate]);

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getPaymentLogData();
        }
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const getUrl = () => {
        const state = { startDate: fromDate, endDate: toDate };
        const url = `${CONSTANTS.API_URL}PaymentLog/AllPaymentLog`;
        return getAPIUrl(state, url);
    }

    const getPaymentLogData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = getUrl();
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    !!dateFieldsToParse && parseToUIDate(result.data.results, dateFieldsToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const showClickHandler = () => {
        setIsLoading(true);
        getPaymentLogData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Payment Log'>
                    <div className='row mb-1'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { modalIsOpen && <PaymentLogModal
                        modalData={modalData}
                        modalName={modalName}
                        isModalOpened={modalIsOpen}
                        onCloseModal={modalCloseHandler}
                        onAfterOpen={handleAfterOpen}
                    /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={false}
                        actions={true} 
                        edit={true}
                        delete={true}
                        view={false}
                        paginate={true}
                        filter={false}
                        gridData={gridData}
                        disableUpdateKey='amountReceived'
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default PaymentLogPage;