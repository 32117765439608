import ReactModal from 'react-modal';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import classes from './Modal.module.css';
import { useEffect, useState } from 'react';

const Modal = props => {

    const customStyles = {
        content: {
            top: '35%',
            left: '50%',
            right: 'auto',
            width: '30%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const [style, setStyle] = useState(customStyles);
    
    useEffect(() => {
        if(props.style?.top) {
            customStyles.content.top = props.style.top;
        }
        if(props.style?.width) {
            customStyles.content.width = props.style.width;
        }
        setStyle(customStyles);
    }, []);
    
    return (
        <ReactModal
            isOpen={props.isModalOpened}
            onAfterOpen={e => props.afterOpenModal(e)}
            style={style}
            ariaHideApp={false}
            preventScroll={false}
            onRequestClose={props.onModalClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            overlayClassName={classes.overlay}
            className='modal-content'
        >
            <ModalHeader onModalClose={props.onModalClose} modalName={props.modalName} />
            <ModalBody errorMessage={props.errorMessage}>
                {props.children}
            </ModalBody>
            <ModalFooter modalName={props.modalName} onModalClose={props.onModalClose} onSaveChanges={props.onSaveChanges} btnText={props.btnText} />
        </ReactModal>
    )
}

export default Modal;