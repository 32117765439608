import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

import classes from './ConsolidatedStockPage.module.css';

const ConsolidatedStockPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0,10));
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Date', 'Opening', 'Received', 'Consumption', 'Commission', 'Adjustment', 'Closing', 'Opening', 'Received', 'Consumption', 'Commission', 'Adjustment', 'Closing', 'Opening', 'Purchase', 'Received', 'Sales', 'Incentive', 'Adjustment', 'Closing'];
    const dataFields = ['stockDate', 'apiPurchaseOpening', 'apiPurchaseReceived', 'apiPurchaseConsumption', 'apiPurchaseCommission', 'apiPurchaseAdjustment', 'apiPurchaseClosing', 'apiSalesOpening', 'apiSalesReceived', 'apiSalesConsumption', 'apiSalesCommission', 'apiSalesAdjustment', 'apiSalesClosing', 'lapuOpening', 'lapuPurchase', 'lapuReceived', 'lapuSales', 'lapuIncentive', 'lapuAdjustment', 'lapuClosing'];
    const mergedHeaders = [
        { key: '', colspan: 2 },
        { key: 'API Purchase', colspan: 6 },
        { key: 'API Sales', colspan: 6 },
        { key: 'Lapu', colspan: 7 }
    ];
    const csvHeaders = [
        { label: 'Date', key: 'stockDate' },
        { label: 'API Purchase Opening', key: 'apiPurchaseOpening' },
        { label: 'API Purchase Received', key: 'apiPurchaseReceived' },
        { label: 'API Purchase Consumption', key: 'apiPurchaseConsumption' },
        { label: 'API Purchase Commission', key: 'apiPurchaseCommission' },
        { label: 'API Purchase Adjustment', key: 'apiPurchaseAdjustment' },
        { label: 'API Purchase Closing', key: 'apiPurchaseClosing' },
        { label: 'API Sales Opening', key: 'apiSalesOpening' },
        { label: 'API Sales Received', key: 'apiSalesReceived' },
        { label: 'API Sales Consumption', key: 'apiSalesConsumption' },
        { label: 'API Sales Commission', key: 'apiSalesCommission' },
        { label: 'API Sales Adjustment', key: 'apiSalesAdjustment' },
        { label: 'API Sales Closing', key: 'apiSalesClosing' },
        { label: 'Lapu Opening', key: 'lapuOpening' },
        { label: 'Lapu Purchase', key: 'lapuPurchase' },
        { label: 'Lapu Received', key: 'lapuReceived' },
        { label: 'Lapu Sales', key: 'lapuSales' },
        { label: 'Lapu Incentive', key: 'lapuIncentive' },
        { label: 'Lapu Adjustment', key: 'lapuAdjustment' },
        { label: 'Lapu Closing', key: 'lapuClosing' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'left', 
            'Opening': 'center',
            'Received': 'center', 
            'Consumption': 'center', 
            'Commission': 'center',
            'Adjustment': 'center',
            'Closing': 'center',
            'Purchase': 'center',
            'Sales': 'center',
            'Incentive': 'center'
        },
        widthConfig: {
            'Date': 'auto', 
            'Opening': '124px',
            'Received': '162px', 
            'Consumption': '162px',
            'Commission': '162px',
            'Adjustment': '162px', 
            'Closing': '162px',
            'Purchase': '162px',
            'Sales': '162px',
            'Incentive': '162px'
        },
        // headerAlignConfig: {
        //     'Date': 'left', 
        //     'API Purchase Opening': 'center',
        //     'API Purchase Received': 'center', 
        //     'API Purchase Consumption': 'center', 
        //     'API Purchase Commission': 'center',
        //     'API Purchase Adjustment': 'center',
        //     'API Purchase Closing': 'center',
        //     'API Sales Opening': 'center',
        //     'API Sales Received': 'center', 
        //     'API Sales Consumption': 'center', 
        //     'API Sales Commission': 'center',
        //     'API Sales Adjustment': 'center',
        //     'API Sales Closing': 'center',
        //     'Lapu Opening': 'center',
        //     'Lapu Purchase': 'center',
        //     'Lapu Received': 'center',
        //     'Lapu Sales': 'center',
        //     'Lapu Incentive': 'center',
        //     'Lapu Closing': 'center'
        // },
        // widthConfig: {
        //     'Date': 'auto', 
        //     'API Purchase Opening': '124px',
        //     'API Purchase Received': '162px', 
        //     'API Purchase Consumption': '162px',
        //     'API Purchase Commission': '162px',
        //     'API Purchase Adjustment': '162px', 
        //     'API Purchase Closing': '162px',
        //     'API Sales Opening': '124px',
        //     'API Sales Received': '162px', 
        //     'API Sales Consumption': '162px',
        //     'API Sales Commission': '162px',
        //     'API Sales Adjustment': '162px', 
        //     'API Sales Closing': '162px',
        //     'Lapu Opening': '162px',
        //     'Lapu Purchase': '162px',
        //     'Lapu Received': '162px',
        //     'Lapu Sales': '162px',
        //     'Lapu Incentive': '162px',
        //     'Lapu Closing': '162px'
        // },
        alignConfig: {
            'stockDate': 'left',
            'apiPurchaseOpening': 'right',
            'apiPurchaseReceived': 'right',
            'apiPurchaseConsumption': 'right',
            'apiPurchaseCommission': 'right',
            'apiPurchaseAdjustment': 'right',
            'apiPurchaseClosing': 'right',
            'apiSalesOpening': 'right',
            'apiSalesReceived': 'right',
            'apiSalesConsumption': 'right',
            'apiSalesCommission': 'right',
            'apiSalesAdjustment': 'right',
            'apiSalesClosing': 'right',
            'lapuOpening': 'right',
            'lapuPurchase': 'right',
            'lapuReceived': 'right',
            'lapuSales': 'right',
            'lapuIncentive' : 'right',
            'lapuClosing': 'right'
        }
    };

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate]);

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = `${CONSTANTS.API_URL}Reports/ConsolidatedStockReport?startDate=${fromDate}&endDate=${toDate}`;
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.stockDate = data.stockDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['apiPurchaseOpening', 'apiPurchaseReceived', 'apiPurchaseConsumption', 'apiPurchaseCommission', 'apiPurchaseAdjustment', 'apiPurchaseClosing', 'apiSalesOpening', 'apiSalesReceived', 'apiSalesConsumption', 'apiSalesCommission', 'apiSalesAdjustment', 'apiSalesClosing', 'lapuOpening', 'lapuPurchase', 'lapuReceived', 'lapuSales', 'lapuIncentive', 'lapuAdjustment', 'lapuClosing'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    let total = result.data.header;
                    const headerKeysToParse = ['apiPurchaseOpening', 'apiPurchaseReceived', 'apiPurchaseConsumption', 'apiPurchaseCommission', 'apiPurchaseAdjustment', 'apiPurchaseClosing', 'apiSalesOpening', 'apiSalesReceived', 'apiSalesConsumption', 'apiSalesCommission', 'apiSalesAdjustment', 'apiSalesClosing', 'lapuOpening', 'lapuPurchase', 'lapuReceived', 'lapuSales', 'lapuIncentive', 'lapuAdjustment', 'lapuClosing'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        total.apiPurchaseOpening,
                        total.apiPurchaseReceived,
                        total.apiPurchaseConsumption,
                        total.apiPurchaseCommission,
                        total.apiPurchaseAdjustment,
                        total.apiPurchaseClosing,
                        total.apiSalesOpening,
                        total.apiSalesReceived,
                        total.apiSalesConsumption,
                        total.apiSalesCommission,
                        total.apiSalesAdjustment,
                        total.apiSalesClosing,
                        total.lapuOpening,
                        total.lapuPurchase,
                        total.lapuReceived,
                        total.lapuSales,
                        total.lapuIncentive,
                        total.lapuAdjustment,
                        total.lapuClosing
                    ]);
                    setExportTotalData({
                        stockDate: 'Total',
                        apiPurchaseOpening: total.apiPurchaseOpening,
                        apiPurchaseReceived: total.apiPurchaseReceived,
                        apiPurchaseConsumption: total.apiPurchaseConsumption,
                        apiPurchaseCommission: total.apiPurchaseCommission,
                        apiPurchaseAdjustment: total.apiPurchaseAdjustment,
                        apiPurchaseClosing: total.apiPurchaseClosing,
                        apiSalesOpening: total.apiSalesOpening,
                        apiSalesReceived: total.apiSalesReceived,
                        apiSalesConsumption: total.apiSalesConsumption,
                        apiSalesCommission: total.apiSalesCommission,
                        apiSalesAdjustment: total.apiSalesAdjustment,
                        apiSalesClosing: total.apiSalesClosing,
                        lapuOpening: total.lapuOpening,
                        lapuPurchase: total.lapuPurchase,
                        lapuReceived: total.lapuReceived,
                        lapuSales: total.lapuSales,
                        lapuIncentive: total.lapuIncentive,
                        lapuAdjustment: total.lapuAdjustment,
                        lapuClosing: total.lapuClosing
                    });
                    setPageCount(Math.ceil(result.data.results.length/10));
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const showClickHandler = () => {
        setIsLoading(true);
        getReportData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    const getExportFileName = () => {
        let exportFilename = 'ConsolidatedStock';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Consolidated Stock'>
                    <div className='row mb-1'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField dateField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-3' onClick={showClickHandler} btnText='Show' />
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        mergedHeaders={mergedHeaders}
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        sort={false}
                        actions={false}
                        view={false} 
                        paginate={true}
                        filter={false}
                        gridData={gridData}
                        totalData={totalData}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default ConsolidatedStockPage;