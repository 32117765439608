export const uiDate = dateString => dateString && dateString.split(' ')[0].split('-').reverse().join('/');

export const apiDate = dateString => dateString.split('/').reverse().join('-');

export const uiDateToday = () => new Date().toISOString().slice(0,10);

export const uiMonthStartDate = () => { 
    const d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), 2).toISOString().slice(0,10)
}

export const daysDifference = date => { 
    var dateObject = new Date(date);
    const d = new Date();
    var diff = dateObject.getTime() - d.getTime();   
    var dayDiff = Math.ceil(diff / (1000 * 60 * 60 * 24));   
    return dayDiff;
}

export const parseToUIDate = (dataArray, keysToParse) => {
    return dataArray.map( data => {
        return Object.entries(data).forEach( ([key, value]) => {
            data[key] = keysToParse.includes(key) ? value && uiDate(value) : value
        });
    });
}

export const parseFloat2Decimal = (dataArray, keysToParse) => {
    return dataArray.map( data => {
        return Object.entries(data).forEach( ([key, value]) => {
            data[key] = keysToParse.includes(key) ? (key === 'commissionRate' ? value.toFixed(3) : value.toFixed(2)) : value
        });
    });
}

export const parseFloat2DecimalHeader = (dataObject, keysToParse) => {
    return Object.entries(dataObject).forEach( ([key, value]) => {
        dataObject[key] = keysToParse.includes(key) ? value.toFixed(2) : value
    });
}

export const parseStatusToBoolean = status => status === 'Yes' ? true : false;

export const formatInputOnBlur = (value, digits = 2) => value === '' || value === '-' ? (0).toFixed(digits) : parseFloat(value).toFixed(digits);

export const getAPIUrl = (state, urlPrefix) => {
    const paramRequired = !Object.values(state).every((v) => v === '-1');
    let url = urlPrefix;
    if(paramRequired) {
        url = `${url}?`;
        let paramCount = 0;
        Object.entries(state).forEach( ([key, value]) => {
            if(value !== '-1') {
                url = url + `${paramCount == 0 ? '' : '&'}${key}=${value}`;
                paramCount++;
            }
        });
    }
    return url;
}