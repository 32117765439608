import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
// import { history } from './_helpers/history';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/Wrapper/ErrorBoundary/ErrorBoundary';

const companyObject = window.location.href.includes('clareinfotech.in') ? 'clareinfotech': '';
const company = companyObject === '' && window.location.href.split('/')[3] === '' ? 'pavathi' : window.location.href.split('/')[3];
console.log('basename: ', company);

ReactDOM.render(
  <BrowserRouter basename={company}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
