import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';
import classes from './CustomerWiseAPISalesConsumptionPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerWiseAPISalesConsumptionPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0,10));
    const [customerId, setCustomerId] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Customer', 'Operator', 'Consumption', 'Commission', 'Difference(+/-)', 'Total'];
    const dataFields = ['customerName', 'operatorName', 'consumption', 'commission', 'adjustment', 'total'];
    const csvHeaders = [
        { label: 'Customer', key: 'customerName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Consumption', key: 'consumption' },
        { label: 'Commission', key: 'commission' },
        { label: 'Difference(+/-)', key: 'adjustment' },
        { label: 'Total Received', key: 'total' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left', 
            'Operator': 'center', 
            'Consumption': 'center',
            'Commission': 'center',
            'Difference(+/-)': 'center',
            'Total': 'center',
        },
        widthConfig: {
            'Customer': 'auto', 
            'Operator': '122px', 
            'Consumption': '122px',
            'Commission': '122px',
            'Difference(+/-)': '122px',
            'Total': '122px',
        },
        alignConfig: {
            'customerName': 'left',
            'operatorName': 'center',
            'consumption': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'total': 'right'
        }
    };

    useEffect(() => {
        getCustomerList();
        getOperatorList();
    }, []);

    useEffect(() => {
        setEnableExport(false);
        setErrorMessage(null);
    }, [fromDate, toDate, customerId, operatorId]);

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('filter').then( result => {
            if(result.data) {
                setCustomerId(result.data.results[0].customerId);
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorId(result.data.results[0].operatorId);
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        url = (customerId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionSummary?startDate=${fromDate}&endDate=${toDate}` :
                customerId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionSummary?startDate=${fromDate}&endDate=${toDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionSummary?startDate=${fromDate}&endDate=${toDate}&customerId=${customerId}` :
                        `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionSummary?startDate=${fromDate}&endDate=${toDate}&customerId=${customerId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    const keysToParse = ['consumption', 'commission', 'adjustment', 'total'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['consumptionAmount', 'commission', 'adjustment', 'totalAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.consumptionAmount,
                        total.commission,
                        total.adjustment,
                        total.totalAmount
                    ]);
                    setExportTotalData({
                        customerName: 'Total',
                        operatorName: '',
                        consumption: total.consumptionAmount,
                        commission: total.commission,
                        adjustment: total.adjustment,
                        total: total.totalAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const showClickHandler = () => {
        setIsLoading(true);
        getReportData();
    }

    const rowClickHandler = rowData => {
        let detailedPage = window.open('/pavathi/customer-api-sales-consumption-detailed');
        detailedPage.startDate = fromDate;
        detailedPage.endDate = toDate;
        detailedPage.customerId = rowData.customerId;
        detailedPage.customerName = rowData.customerName;
        detailedPage.operatorId = rowData.operatorId;
        detailedPage.operatorName = rowData.operatorName;
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    const customerChangeHandler = event => setCustomerId(event.target.value)
    
    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const getExportFileName = () => {
        let exportFilename = 'CustomerWiseAPISalesConsumption';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += customerId !== '-1' ? '_' + customerData.find(data => data.customerId == customerId)['customerName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Customer Wise API Sales Consumption'>
                    <div className='row mb-1'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Customer : </label>
                                    <select className='form-select customSelect' aria-label='.form-select-sm example' onChange={customerChangeHandler} value={customerId}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} className='dropdownItem' value={value.customerId}>{value.customerName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select className='form-select customSelect' value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} className='dropdownItem' value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' />
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        totalData={totalData}
                        pageCount={pageCount} 
                        sort={false}
                        actions={true} 
                        view={true}
                        paginate={true}
                        filter={false}
                        gridData={gridData}
                        onRowClick={rowClickHandler}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default CustomerWiseAPISalesConsumptionPage;