import classes from './Content.module.css';

const Content = props => {
    return (
        <div className={props.isLoggedIn ? `container-fluid ${classes.contentPage}` : ''}>
            {props.content}
        </div>
    )
}

export default Content;