import { useEffect, useState } from 'react';
import { FaUsers, FaMobileAlt, FaShoppingCart, FaShopify, FaRupeeSign, FaShoppingBag } from 'react-icons/fa';

import { handleResponse } from '../../../_helpers/handle-response';
import classes from './DashboardPage.module.css';
import Alert from '../../UI/Alert/Alert';
import Loader from '../../UI/Loader/Loader';
import CONSTANTS from '../../../_constants/constants';
import BarChart from './BarChart';
import PageTitle from '../../UI/PageTitle/PageTitle';
import { parseFloat2Decimal } from '../../../_helpers/utils';

const DashboardPage = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [monthData, setMonthData] = useState({});
    const [presentData, setPresentData] = useState({});
    const [todayData, setTodayData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [stockData, setStockData] = useState([]);
    const [totalStock, setTotalStock] = useState();
    const [chartData, setChartData] = useState({});
    const [apiPurchaseData, setApiPurchaseData] = useState([]);
    const [apiSalesData, setApiSalesData] = useState([]);

    useEffect(() => {
        getDashboardData();
    }, []);

    const getDashboardData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        fetch(`${CONSTANTS.API_URL}DashBoard/HomeScreen`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    Object.entries(result.data.month).forEach( ([key, value]) => {
                        result.data.month[key] = typeof(value) === 'number' ? parseFloat(value).toFixed(2) : value
                    });
                    setMonthData(result.data.month);
                    // result.data.present.totalStock = parseFloat(result.data.present.totalStock).toFixed(2);
                    // setPresentData(result.data.present);
                    result.data.today.sales = parseFloat(result.data.today.sales).toFixed(2);
                    result.data.today.purchase = parseFloat(result.data.today.purchase).toFixed(2);
                    setTodayData(result.data.today);
                    setCompanyData(result.data.company);
                    result.data.stock.map( data => data.stock = parseFloat(data.stock).toFixed(2));
                    setStockData(result.data.stock);
                    setTotalStock(parseFloat(result.data.totalStock.totalStock).toFixed(2));
                    setChartData(result.data.Graph);
                    const apiPurchaseKeys = ['openingStock', 'purchase', 'consumption', 'closingStock'];
                    parseFloat2Decimal(result.data.apiPurchase, apiPurchaseKeys);
                    setApiPurchaseData(result.data.apiPurchase[0]);
                    const apiSalesKeys = ['openingStock', 'sales', 'consumption', 'closingStock'];
                    parseFloat2Decimal(result.data.apiSales, apiSalesKeys);
                    setApiSalesData(result.data.apiSales[0]);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    return (
        <>
            <PageTitle title='Dashboard' />
            { isLoading && <Loader />}
            { !isLoading && errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
            { !isLoading && !errorMessage &&
                <>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-xl-6 col-lg-6 pr-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Monthly Purchase</h5>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaRupeeSign className={classes.greenIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Opening</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {monthData.opening}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingBag className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaShoppingBag className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Purchase</h5>
                                                <div className={`text-success ${classes.dataCardValue}`}><span>&#x20B9;</span> {monthData.purchase}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Commission</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {monthData.commission}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-xl-6 col-lg-6 pl-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Monthly Sales</h5>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingCart className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaShoppingCart className={classes.greenIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Sales</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {monthData.sales}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingBag className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaShoppingBag className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Incentive</h5>
                                                <div className={`text-success ${classes.dataCardValue}`}><span>&#x20B9;</span> {monthData.incentive}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Closing</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {monthData.closing}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 pr-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                    <h5 className={classes.dataCardTitle}>Yesterday</h5>
                                        <div className={`col-sm-6 col-xl-6 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingCart className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaShoppingCart className={`text-muted ${classes.greenIcon}`} style={{ fontSize: '24px', color: '#3688fc' }} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Sales</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {todayData.sales}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-6 col-xl-6 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShopify className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaShopify className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Purchase</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {todayData.purchase}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-xl-6 col-lg-6 pl-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Company Details</h5>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaUsers className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaUsers className={classes.greenIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Suppliers</h5>
                                                <div className={classes.dataCardValue}>{companyData.totalSuppliers}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaUsers className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaUsers className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Operators</h5>
                                                <div className={classes.dataCardValue}>{companyData.totalOperators}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-4 col-xl-4 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaMobileAlt className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaMobileAlt className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Sims</h5>
                                                <div className={classes.dataCardValue}>{companyData.totalSims}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-6 pr-2'>
                            <div className='card mb-0' style={{ height: '500px'}}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={`${classes.dataCardTitle}`}>Present Stock</h5>
                                        <div className={`card pl-4 pr-4 ${classes.stockTableCard}`} style={{ height: '440px'}}>
                                            <div className='table-responsive' style={{ overflowY: 'scrol', overflowX: 'hidden'}}>
                                                <table className={`table table-sm ${classes.stockTable}`}>
                                                    <thead className={classes.header}>
                                                        <tr>
                                                            <th className={classes.stockTablehead}>Operator</th>
                                                            <th className={classes.stockTablehead} style={{textAlign: 'right'}}>Stock</th>
                                                        </tr>
                                                        <tr className={classes.stockTableTotalRow}>
                                                            <th className={classes.stockTableTotalRowData}>Total</th>
                                                            <th className={classes.stockTableTotalRowData} style={{textAlign: 'right'}}>{totalStock}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stockData.map((stock, index) => {
                                                            return (
                                                                <tr key={index} style={{height: '36px'}}>
                                                                    <td style={{paddingTop: '0', paddingBottom: '0'}}>
                                                                        <div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                                                                            { stock.image !== null ? 
                                                                                (<img src={stock.image} alt=' ' height='25px' width='25px'  className={`text-muted ${classes.stockImage}`}/>)
                                                                                :
                                                                                (<span className={classes.noImage}></span>)
                                                                            }
                                                                            <p className='my-1 fw-normal mb-0' style={{ paddingLeft: '16px' }}>{stock.operatorName}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                                                        <p className='fw-normal mb-0' style={{ textAlign: 'right' }}>{stock.stock}</p>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-8 col-lg-6 pl-2'>
                            <div className='card mb-0' style={{ height: '500px' }}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Weekly Graph</h5>
                                        <div className='p-4 pt-0' >
                                            {!(Object.entries(chartData).length === 0 && chartData.constructor === Object) && <BarChart chartData={chartData} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-xl-12 col-lg-12 pr-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Monthly API Purchase</h5>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaRupeeSign className={classes.greenIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Opening</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiPurchaseData.openingStock}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingBag className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaShoppingBag className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Purchase</h5>
                                                <div className={`text-success ${classes.dataCardValue}`}><span>&#x20B9;</span> {apiPurchaseData.purchase}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Consumption</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiPurchaseData.consumption}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Closing</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiPurchaseData.closingStock}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-xl-12 col-lg-12 pr-2'>
                            <div className='card mb-0'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h5 className={classes.dataCardTitle}>Monthly API Sales</h5>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer} pl-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetGreen} float-end`}>
                                                    <FaRupeeSign className={classes.greenIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Opening</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiSalesData.openingStock}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaShoppingBag className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetPink} float-end`}>
                                                    <FaShoppingBag className={classes.pinkIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Sales</h5>
                                                <div className={`text-success ${classes.dataCardValue}`}><span>&#x20B9;</span> {apiSalesData.sales}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer}`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Consumption</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiSalesData.consumption}</div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-12 col-lg-3 col-xl-3 ${classes.dataCardContainer} pr-3`}>
                                            <div className={classes.dataCard}>
                                                <div className={`${classes.widgetIcon} ${classes.widgetBlue} float-end`}>
                                                    <FaRupeeSign className={classes.blueIcon} />
                                                </div>
                                                {/* <div className={`${classes.widgetIcon} ${classes.widgetOrange} float-end`}>
                                                    <FaRupeeSign className={classes.orangeIcon} />
                                                </div> */}
                                                <h5 className={classes.dataCardHeading}>Closing</h5>
                                                <div className={classes.dataCardValue}><span>&#x20B9;</span> {apiSalesData.closingStock}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default DashboardPage;