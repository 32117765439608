import React, { useState, useEffect } from 'react'; 

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import CustomerModal from './CustomerModal';
import classes from './CustomerPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import Button from '../../../UI/Button/Button';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    
    const tableHeaders = ['Customer'];
    const dataFields = ['customerName'];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left'
        },
        widthConfig: {
            'Customer': 'auto'
        },
        alignConfig: {
            'customerName': 'left'
        }
    }
    
    useEffect(() => {
        getCustomerData();
    }, []);
    
    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCustomerData();
        }
    }

    const getCustomerData = () => {
        const url = `${CONSTANTS.API_URL}Customer/AllCustomer`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setIsLoading(false);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            data.customerName && data.customerName.toLowerCase().includes(filterKeyword.toLowerCase())
        )
    }

    return (
        <>
            { isLoading && <Loader /> }
            { !isLoading && (
                <PageLayout pageTitle='Customer Master'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add Customer' btnTitle='Add Customer' btnType='Add' />
                                <CustomerModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        status={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default CustomerPage;