import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import Modal from '../../../UI/Modal/Modal';
import classes from './PlanModal.module.css';

const INITIAL_STATE = {
    planName: '',
    days: '',
    amount: '',
    lapu: ''
};

const PlanModal = props => {

    const [state, setState] = useState({...INITIAL_STATE});
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Plan`;

    useEffect(() => {
        return () => clearFields()
    }, []);

    const clearFields = () => {
        setState({...INITIAL_STATE});
    }
    
    // Modal Handlers
    const afterOpenModal = event => {
        setHasError(false);
        setErrorMessage(null);
        props.modalName === 'Edit' && setState({...props.modalData});
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        clearFields();
        props.onCloseModal('cancel');
    }
    
    // Change Handler
    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.type == 'checkbox' ? evt.target.checked : evt.target.value;
        setState({
          ...state,
          [name]: value
        });
    }

    // API functions
    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const getFormInvalid  = () => {
        return state.planName === '' || state.days <= 0 || state.amount <= 0 || state.lapu <= 0
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Plan/`;
        if(props.modalName !== 'Delete' && getFormInvalid() ) {
            setHasError(true);
        } else {
            setHasError(false);
            const apiBody = {
                planName: state.planName,
                days: state.days,
                amount: state.amount,
                lapu: state.lapu
            }
            if(props.modalName === 'Add') {
                ApiService.postData(`${apiUrl}AddPlan`, {...apiBody}).then(handleApiResponse);
            } else if(props.modalName === 'Edit') {
                ApiService.updateData(`${apiUrl}UpdatePlan?planId=${props.modalData.planId}`, {...apiBody}).then(handleApiResponse);
            } else if(props.modalName === 'Delete') {
                ApiService.deleteData(`${apiUrl}DeletePlan?planId=${props.modalData.planId}`, props.modalData).then(handleApiResponse);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            labelWidth={4}
            fieldWidth={8}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit')  &&
                <form>
                    <FormGroup 
                        label='Plan Name'
                        name='planName'
                        value={state.planName}
                        changeHandler={handleChange} 
                        hasError={hasError && state.planName === ''}
                        errorMessage='Please enter Plan Name.'
                        autoFocus
                    />
                    <FormGroup 
                        type='number'
                        label='Validity (in days)'
                        name='days'
                        value={state.days}
                        changeHandler={handleChange} 
                        hasError={hasError && state.days <= 0}
                        errorMessage='Please enter Validity in days.'
                        />
                    <FormGroup 
                        type='number'
                        label='Amount'
                        name='amount'
                        value={state.amount}
                        changeHandler={handleChange} 
                        hasError={hasError && state.amount <= 0}
                        errorMessage='Please enter valid amount.'
                        />
                    <FormGroup 
                        type='number'
                        label='No. of Lapu'
                        name='lapu'
                        value={state.lapu}
                        changeHandler={handleChange} 
                        hasError={hasError && state.lapu <= 0}
                        errorMessage='Please enter valid No. of Lapu.'
                    />
                </form>
            } 
            { props.modalName === 'Delete' &&
                <div className={classes.text}>Do you want to Delete Plan <b>({props.modalData.planName})</b>?</div>
            }
        </Modal>
        
    );

}

export default React.memo(PlanModal);