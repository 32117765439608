import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import classes from './SupplierPurchasePendingModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const SupplierPurchasePendingModal = props => {

    const [supplierId, setSupplierId] = useState('-2');
    const [operatorId, setOperatorId] = useState('-2');
    const [netCurrency, setNetCurrency] = useState();
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        getSupplierList();
        if(props.modalName === 'Edit') {
            getOperatorListBySupplier(props.modalData.supplierId);
        }
        return () => {
            setSupplierId('');
            setOperatorId('');
            setNetCurrency('');
        }
    }, []);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          width: '35%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal').then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    setSupplierId((!!props.modalData.supplierId) && props.modalData.supplierId);
                }
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorListBySupplier = supplierId => {
        DropdownDataService.getOperatorListBySupplier('modal', supplierId).then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    setOperatorId((!!props.modalData.operatorId) && props.modalData.operatorId);
                } 
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const afterOpenModal = () => {
        setSupplierId(!!props.modalData.supplierId ? props.modalData.supplierId : '-2');
        setOperatorId(!!props.modalData.operatorId ? props.modalData.operatorId : '-2');
        setNetCurrency(!!props.modalData.netCurrency ? props.modalData.netCurrency : '');
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel');
    }

    const clearMessages = () => {
        setErrorMessage(null);
        setHasError(false);
    }

    const validateInput = value => {
        return value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    }
    
    const supplierChangeHandler = event => {
        clearMessages();
        setSupplierId(event.target.value);
        getOperatorListBySupplier(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        clearMessages();
        setOperatorId(event.target.value);
    }
    
    const netCurrencyChangeHandler = event => {
        clearMessages();
        if(event.target.value === '') {
            setNetCurrency(0);
        } else {
            const value = event.target.value;
            if(validateInput(value)) {
                setNetCurrency(value);
            }
        }
    }
    
    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)
    
    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = 'https://royalinfo.in/api/pavathi/LapuOpening/' + url;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (supplierId === '-2' || operatorId === '-2' || netCurrency <= 0 )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                reqBody = {
                    supplierId,
                    operatorId,
                    netCurrency: Number(parseFloat(netCurrency).toFixed(2))
                };
                url = 'AddLapuOpening';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                reqBody = {
                    supplierId,
                    operatorId,
                    netCurrency: Number(parseFloat(netCurrency).toFixed(2))
                };
                url = `UpdateLapuOpening?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteLapuOpening?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} Purchase Pending</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select disabled={props.modalName === 'Edit'} className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Supplier.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                <div className='col-sm-8'>
                                    <select disabled={props.modalName === 'Edit'} className={`form-select inputField ${hasError && supplierId !== '-2' && operatorId === '-2' ? 'is-invalid': ''}`} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Net Pending : </label>
                                <div className='col-sm-8'>
                                    <input type='text' style={{textAlign:'right'}} className={`form-control inputField ${hasError && supplierId !== '-2' && operatorId !== '-2' && netCurrency <= 0 ? 'is-invalid': ''}`} value={netCurrency} onChange={netCurrencyChangeHandler} onBlur={ e => setNetCurrency(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter Net Pending greater than 0.</div>
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete Purchase Pending?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default SupplierPurchasePendingModal;