import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from 'react-icons/fa';

import classes from './ExportToCSV.module.css';

const ExportToCSV = props => {

    const [data, setData] = useState([]);
    const headerKeys = props.headers.map( data => data.key);
    const headers = props.headers;

    useEffect(() => {
        let gridData = [];
        let filteredArray = [];
        props.gridData.map( exportData => {
            filteredArray = Object.entries(exportData).filter( ([key, value]) => headerKeys.includes(key));
            gridData.push(Object.fromEntries(filteredArray));
        });
        !!props.exportTotalData ? setData([props.exportTotalData, ...gridData]) : setData(gridData);
    }, [props.exportTotalData, props.gridData]);

    const buttonContent = <FaDownload title='Export to CSV' className={classes.exportIcon} />

    return (
        <>
            { props.enableExport &&
                <CSVLink
                    data={data}
                    headers={headers}
                    onClick={props.exportClickHandler}
                    filename={props.exportFileName + '.csv'}
                    className='btn btn-primary'
                    title='Export to CSV'
                >
                    {buttonContent}
                </CSVLink>
            }
            { !props.enableExport &&
                <button type='button' className='btn btn-primary' disabled>
                    {buttonContent}
                </button>
            }
        </>
    )
}

export default React.memo(ExportToCSV);