import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseCurrencyPurchaseDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Supplier', 'Operator', 'Purchase Date', 'Purchase', 'Commission %', 'Commission', 'Adjustment', 'Total Received'];
    const dataFields = ['supplierName', 'operatorName', 'purchaseDate', 'purchase', 'commissionRate', 'commission', 'adjustment', 'total'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Purchase Date', key: 'purchaseDate' },
        { label: 'Purchase', key: 'purchase' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Adjustment', key: 'adjustment' },
        { label: 'Total Received', key: 'total' },
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left', 
            'Operator': 'center', 
            'Purchase Date': 'center',
            'Purchase': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Adjustment': 'center',
            'Total Received': 'center',
        },
        widthConfig: {
            'Supplier': 'auto', 
            'Operator': '122px', 
            'Purchase Date': '122px',
            'Purchase': '122px',
            'Commission %': '122px',
            'Commission': '122px',
            'Adjustment': '122px',
            'Total Received': '122px',
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'purchaseDate': 'center',
            'purchase': 'right',
            'commissionRate': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'total': 'right'
        }
    };

    useEffect(() => {
        getPurchaseDetailedData();
    }, []);
    
    const getPurchaseDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        const supplierId = window.supplierId;
        const operatorId = window.operatorId;
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseDetailed?startDate=${window.startDate}&endDate=${window.endDate}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseDetailed?startDate=${window.startDate}&endDate=${window.endDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseDetailed?startDate=${window.startDate}&endDate=${window.endDate}&supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}Reports/SupplierwiseCurrencyPurchaseDetailed?startDate=${window.startDate}&endDate=${window.endDate}&supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.purchaseDate = data.purchaseDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['purchase', 'commissionRate', 'commission', 'adjustment', 'total'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['purchaseAmount', 'commission', 'adjustment', 'totalAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        total.purchaseAmount,
                        '',
                        total.commission,
                        total.adjustment,
                        total.totalAmount,
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        purchaseDate: '',
                        purchase: total.purchaseAmount,
                        commissionRate: '',
                        commission: total.commission,
                        adjustment: total.adjustment,
                        total: total.totalAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'SupplierWiseCurrencyPurchaseDetailed';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.supplierName !== '' ? '_' + window.supplierName : '';
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        setExportFileName(exportFilename);
    }
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Supplier Wise Currency Purchase Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default SupplierWiseCurrencyPurchaseDetailedPage;