import { useHistory } from 'react-router';

import Button from '../../UI/Button/Button';
import ForbiddenErrorImg from '../../../assets/images/ForbiddenError.jpeg';
import classes from './AccessDenied.module.css';

const AccessDenied = () => {

    const history = useHistory();

    const onReturnClick = () => history.push('/dashboard')

    return (
        <div className={`container-fluid ${classes.accessDeniedPage}`}>
            <div className={classes.imgContainer}>
                <img src={ForbiddenErrorImg} alt='403 Error - Forbidden' className={`col-12 ${classes.image}`} />
                <h3 className={classes.errorText}>Access Denied</h3>
                <div className={classes.errorDescription}>You do not have permission to access this page.</div>
                <div>
                    <Button btnClass='btn btn-success' onClick={onReturnClick} btnText='Return to Dashoard' />
                </div>
            </div>
        </div>
    );
}

export default AccessDenied;