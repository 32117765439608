import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../_helpers/handle-response';
import CONSTANTS from '../../../_constants/constants';
import classes from './SetOpeningDateModal.module.css';

import Alert from '../../UI/Alert/Alert';
import Button from '../../UI/Button/Button';

const SetOpeningDateModal = props => {

    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);

    useEffect(() => getPeriod(), []);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          width: '40%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const afterOpenModal = () => {
        getPeriod();
        setErrorMessage(null);
    }
    
    const onModalClose = event => {
        props.onCloseModal();
    }

    const dateChangeHandler = event => {
        setDate(event.target.value);
    }

    const getPeriod = () => {
        let apiUrl = `${CONSTANTS.API_URL}Period/Period`;
        const requestOptions = {
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            }
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    result.data.startDate = result.data.startDate.split(' ')[0];
                    setDate(result.data.startDate);
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if( date === '') {
            setHasError(true);
        } else {
                const reqBody = {
                    startDate: date
                };
                setHasError(false);
                let apiUrl = `${CONSTANTS.API_URL}Period/UpdatePeriod`;
                const requestOptions = {
                    method: 'PUT',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Token': localStorage.getItem('accessToken')
                    },
                    body: JSON.stringify(reqBody)
                };
                fetch(apiUrl, requestOptions)
                .then(handleResponse)
                .then(
                    (result) => {
                        if(result.data) {
                            props.onCloseModal();
                        } else if(result.error) {
                            setErrorMessage(result.error.message);
                        }
                    },
                    error => setErrorMessage(error)
                );
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName}</h4>
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <form>
                        <div className={`form-group row ${classes.inputContainer}`}>
                            <label className='col-sm-4 col-form-label inputLabel'>Start Period : </label>
                            <div className='col-sm-8'>
                                <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={date} onChange={dateChangeHandler} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText='Cancel' />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText='Save' />
            </div>
          </ReactModal>
        </div>
    );

}

export default SetOpeningDateModal;