import CONSTANTS from '../../_constants/constants';
import { ApiService } from '../../_services/api.service';

export const getPlanStatus = () => {
    const url = `${CONSTANTS.API_URL}Company/Company`;
    let status = { status: CONSTANTS.PLAN_STATUS.ACTIVE };
    return ApiService.getData(url).then(
        result => {
            if(result.data) {
                status.expiryDate = result.data?.expiryDate;
                status.pendingDays = result.data?.pendingDays;
                status.status = result.data?.status || CONSTANTS.PLAN_STATUS.ACTIVE;
                return status;
            }
            return status;
        },
        error => error
    );
  }

