import classes from './InputGroup.module.css';

const InputGroup = props => {
    return (
        <div className={`input-group has-validation ${classes.inputContainer}`}>
            <label className={`col-form-label ${classes.inputLabel}`}>{props.label} : </label>
            <input 
                type={props.type} 
                readOnly={props.readOnly} 
                className={`form-control ${classes.inputField} ${props.hasError && props.value === '' ? 'is-invalid': ''}`} 
                value={props.value} 
                onChange={props.changeHandler} 
                autoFocus={props.autoFocus}
            />
            <div className={`invalid-feedback ${classes.feedback}`}>{props.errorMessage}</div>
        </div>
    )
}

export default InputGroup;