import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import APISalesCommissionModal from './APISalesCommissionModal';
import CONSTANTS from '../../../../_constants/constants';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { getAPIUrl, parseFloat2Decimal } from '../../../../_helpers/utils';
import classes from './APISalesCommissionPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const APISalesCommissionPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [customerId, setCustomerId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [regionId, setRegionId] = useState('-1');
    const [customerData, setCustomerData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');

    const tableHeaders = ['Customer', 'Operator', 'Region', 'Commission Type', 'Commission %'];
    const dataFields = ['customerName', 'operatorName', 'regionName', 'percentageTypeName', 'commissionRate'];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left',
            'Operator': 'center',
            'Region': 'center',
            'Commission Type': 'center',
            'Commission %': 'center'
        },
        widthConfig: {
            'Customer': 'auto',
            'Operator': '162px',
            'Region': '162px',
            'Commission Type': '162px',
            'Commission %': '162px'
        },
        alignConfig: {
            'customerName': 'left',
            'operatorName': 'center',
            'regionName': 'center',
            'percentageTypeName': 'center',
            'commissionRate': 'right'
        }
    }

    useEffect(() => { 
        getCustomerList();
        getOperatorList();
        getRegionList();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        getCommissionData();
    }, [customerId, operatorId, regionId]);
    
    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.customerName && data.customerName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.regionName && data.regionName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().toLowerCase().includes(filterKeyword.toLowerCase()))
        );
    }

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCommissionData();
        }
    }

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('filter').then( result => {
            if(result.data) {
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getRegionList = () => {
        DropdownDataService.getRegionList('filter').then( result => {
            if(result.data) {
                setRegionData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getUrl = () => {
        const state = { customerId, operatorId, regionId };
        const urlPrefix = `${CONSTANTS.API_URL}ApiOutput/AllApiOutput`;
        return getAPIUrl(state, urlPrefix);
    }

    const getCommissionData = () => {
        setErrorMessage(null);
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = getUrl();
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( d => d.commissionRate = d.commissionRate.toFixed(3));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({customerId}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value)

    const customerChangeHandler = event => setCustomerId(event.target.value)

    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const regionChangeHandler = event => setRegionId(event.target.value)

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='API Sales Commission'>
                    <div className='row mb-3'>
                        <div className='col-sm-8 col-md-8'>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Customer : </label>
                                    <select value={customerId} className='form-select customSelect' onChange={customerChangeHandler}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} value={value.customerId} className='dropdownItem'>{value.customerName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select value={operatorId} className='form-select customSelect' onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId} className='dropdownItem'>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Region : </label>
                                    <select value={regionId} className='form-select customSelect' onChange={regionChangeHandler}>
                                        { regionData.map( (value, index) => {
                                            return <option key={`region-${index}`} value={value.regionId} className='dropdownItem'>{value.regionName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className={`col-sm-4 col-md-4 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add Commission' btnTitle='Add API Purchase Commission' btnType='Add' />
                                { modalIsOpen && <APISalesCommissionModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default APISalesCommissionPage;