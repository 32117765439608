import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CONSTANTS from '../../../../_constants/constants';

import { handleResponse } from '../../../../_helpers/handle-response';
import { validateDecInputTo3Digits } from '../../../../_helpers/validators';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import classes from './APISalesCommissionModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const APISalesCommissionModal = props => {

    const [customerId, setCustomerId] = useState('-2');
    const [supplierName, setSupplierName] = useState('');
    const [operatorId, setOperatorId] = useState('-2');
    const [operatorName, setOperatorName] = useState('');
    const [regionId, setRegionId] = useState('-2');
    const [regionName, setRegionName] = useState('');
    const [percentageTypeName, setPercentageTypeName] = useState('');
    const [percentageTypeId, setPercentageTypeId] = useState('');
    const [commissionRate, setCommissionRate] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [percentageTypeData, setPercentageTypeData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if(props.modalName !== 'Delete') {
            getCustomerList();
            getOperatorList();
            getRegionList();
            getCommissionType();
        }
        return () => setCommissionRate('')
    }, [props.modalName]);

    const customStyles = {
        content: {
          top: '52%',
          left: '50%',
          right: 'auto',
          width: '38%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('modal').then( result => {
            if(result.data) {
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    // const getOperatorListBySupplier = supplierId => {
    //     DropdownDataService.getOperatorListBySupplier('modal', supplierId).then( result => {
    //         if(result.data) {
    //             if(props.modalName === 'Edit') {
    //                 setOperatorId((!!props.modalData.operatorId) && props.modalData.operatorId);
    //             } 
    //             setOperatorData(result.data.results);
    //         } else {
    //             setErrorMessage(result);
    //         }
    //     });
    // }

    // const getCommissionTypeBySupplierNOperator = (supplierId, operatorId) => {
    //     DropdownDataService.getCommissionTypeBySupplierNOperator(supplierId, operatorId).then( result => {
    //         if(result.data) {
    //             setPercentageTypeId(result.data.percentageTypeId);
    //             setPercentageTypeName(result.data.percentageTypeName);
    //         } else {
    //             setErrorMessage(result);
    //         }
    //     });
    // }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('modal').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getRegionList = () => {
        DropdownDataService.getRegionList('modal').then( result => {
            if(result.data) {
                setRegionData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getCommissionType = () => {
        DropdownDataService.getCommissionType('modal').then( result => {
            if(result.data) {
                setPercentageTypeData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = event => {
        setCustomerId(props.modalData.customerId !== '-1' ? props.modalData.customerId : '-2');
        // setSupplierName(!!props.modalData.supplierName ? props.modalData.supplierName : '');
        setOperatorId(!!props.modalData.operatorId && props.modalData.operatorId !== '-1' ? props.modalData.operatorId : '-2');
        setOperatorName(!!props.modalData.operatorName ? props.modalData.operatorName : '');
        setRegionId(!!props.modalData.regionId && props.modalData.regionId !== '-1' ? props.modalData.regionId : '-2');
        setRegionName(!!props.modalData.regionName ? props.modalData.regionName : '');
        setCommissionRate(props.modalData.commissionRate ? props.modalData.commissionRate : '');
        setPercentageTypeName(props.modalData.percentageTypeName && (props.modalData.percentageTypeName !== '') ? props.modalData.percentageTypeName : '');
        setPercentageTypeId(props.modalData.percentageTypeId && (props.modalData.percentageTypeId !== '-1') ? props.modalData.percentageTypeId : '-2');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => props.onCloseModal('cancel')

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)
    
    const customerChangeHandler = event => {
        setErrorMessage(null);
        setCustomerId(event.target.value);
    }

    const operatorChangeHandler = event => {
        setErrorMessage(null);
        setOperatorId(event.target.value);
    }
    
    const regionChangeHandler = event => {
        setErrorMessage(null);
        setRegionId(event.target.value);
    }
    
    const commTypeChangeHandler = event => {
        setErrorMessage(null);
        setPercentageTypeId(event.target.value);
    }

    const commRateChangeHandler = event => {
        const value = event.target.value;
        setErrorMessage(null);
        if(validateDecInputTo3Digits(value)) {
            setCommissionRate(value);
        }
    }

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = `${CONSTANTS.API_URL}ApiOutput/` + url;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (customerId === '-2' || operatorId === '-2'|| percentageTypeId === '' || commissionRate === '' )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                reqBody = { customerId, operatorId, regionId, percentageTypeId, commissionRate: parseFloat(commissionRate) };
                url = 'AddApiOutput';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                reqBody = { customerId, operatorId, regionId, percentageTypeId, commissionRate: parseFloat(commissionRate) };
                url = `UpdateApiOutput?apiOutputId=${props.modalData.apiOutPutId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteApiOutput?apiOutputId=${props.modalData.apiOutPutId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} API Sales Commission</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { props.modalName !== 'Delete' &&
                        <form>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Customer :</label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && customerId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={customerId} aria-label=".form-select-sm example" onChange={customerChangeHandler}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} value={value.customerId}>{value.customerName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select a Customer.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator :</label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select an Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Region :</label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && regionId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={regionId} aria-label=".form-select-sm example" onChange={regionChangeHandler}>
                                        { regionData.map( (value, index) => {
                                            return <option key={`region-${index}`} value={value.regionId}>{value.regionName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select a Region.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type :</label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && percentageTypeId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                        { percentageTypeData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Commission Type.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && commissionRate === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={commissionRate} onChange={commRateChangeHandler} onBlur={e=>setCommissionRate(onBlurHandler(e, 3))} />
                                    <div className='invalid-feedback'>Please enter Commission Rate.</div>
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete API Sales Commission?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(APISalesCommissionModal);