import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { validateDecimalInput } from '../../../../_helpers/validators';
import classes from './APIPurchaseAdjustmentModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import CONSTANTS from '../../../../_constants/constants';
import { formatInputOnBlur } from '../../../../_helpers/utils';

const APIPurchaseAdjustmentModal = props => {

    const [transactionDate, setTransactionDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('-2');
    const [supplierName, setSupplierName] = useState('');
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [percentageTypeId, setPercentageTypeId] = useState('-2');
    const [percentageTypeName, setPercentageTypeName] = useState();
    const [commissionRate, setCommissionRate] = useState(0);
    const [closingAmount, setClosingAmount] = useState(0);
    const [netCurrency, setNetCurrency] = useState();
    const [supplierData, setSupplierData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        if(props.modalName !== 'Delete') {
            getSupplierList();
            updateModal();
        }
        return () => {
            setTransactionDate('');
            setSupplierId('-2');
            setPercentageTypeId('-2');
            setPercentageTypeName('');
            setCommissionRate();
            setNetCurrency();
        }
    }, []);

    const customStyles = {
        content: {
            top: '56%',
            left: '50%',
            right: 'auto',
            width: '38%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    useEffect(() => getBalanceAmount(), [supplierId]);

    const updateModal = () => {
        setTransactionDate(!!props.modalData.transactionDate ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        setSupplierId(!!props.modalData.supplierId ? props.modalData.supplierId : '-2');
        setSupplierName(!!props.modalData.supplierName ? props.modalData.supplierName : '');
        setPercentageTypeId(!!props.modalData.percentageTypeId ? props.modalData.percentageTypeId : '-2');
        setPercentageTypeName(!!props.modalData.percentageTypeName ? props.modalData.percentageTypeName : '');
        setCommissionRate(!!props.modalData.commissionRate ? props.modalData.commissionRate : (0).toFixed(3));
        setNetCurrency(!!props.modalData.adjustment ? parseFloat(props.modalData.adjustment).toFixed(2) : (0).toFixed(2));
    }

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal', 'ApiPurchase').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
                getBalanceAmount();
            } else {
                setErrorMessage(result);
            }
        });
    }

    const afterOpenModal = () => {}
    
    const onModalClose = e => props.onCloseModal('cancel')

    const clearMessages = () => setErrorMessage(null)

    const clearFieldsRelatedToSupplier = () => {
        setBalanceAmount((0).toFixed(2));
        setClosingAmount((0).toFixed(2));
        setPercentageTypeName('');
        setCommissionRate((0).toFixed(3));
    }

    const adjustmentDateChangeHandler = event => {
        clearMessages();
        setTransactionDate(event.target.value);
    }

    const supplierChangeHandler = event => {
        clearMessages();
        setSupplierId(event.target.value);
        clearFieldsRelatedToSupplier();
    }
    
    const closingAmountChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setClosingAmount(value);
                setNetCurrency(value - balanceAmount);
            }
        } else {
            setClosingAmount(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const getBalanceAmount = () => {
        if(supplierId !== '-2') {
            let apiUrl = `${CONSTANTS.API_URL}ApiPurchaseAdjustment/ApiPurchaseAdjustmentBalance?purchaseDate=${transactionDate}&supplierId=${supplierId}`;
            const requestOptions = {
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('accessToken')
                }
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        let supplierBalanceAmount = result.data.results.balanceAmount.toFixed(2);
                        props.modalName === 'Add' && setBalanceAmount(supplierBalanceAmount);
                        props.modalName === 'Edit' && setClosingAmount(parseFloat(supplierBalanceAmount).toFixed(2));
                        props.modalName === 'Edit' && setBalanceAmount((parseFloat(supplierBalanceAmount) - parseFloat(props.modalData.adjustment)).toFixed(2));
                        setPercentageTypeId(result.data.results.percentageTypeId);
                        setPercentageTypeName(result.data.results.percentageTypeName);
                        setCommissionRate(result.data.results.commissionRate.toFixed(3));
                    } else if(result.error) {
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        }
    }

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = `${CONSTANTS.API_URL}ApiPurchaseAdjustment/${url}`;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (supplierId === '-2' || percentageTypeId === '-2' || commissionRate === '')) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {
                transactionDate,
                supplierID: supplierId,
                percentageTypeID: percentageTypeId,
                commissionRate: parseFloat(commissionRate), 
                netCurrency
            };
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                url = 'AddApiPurchaseAdjustment';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                url = `UpdateApiPurchaseAdjustment?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteApiPurchaseAdjustment?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
                reqBody = {};
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} API Purchase Adjustment</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Adjustment Date : </label>
                                <div className='col-sm-8'>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={transactionDate} onChange={adjustmentDateChangeHandler} />
                                </div>
                            </div>
                            { props.modalName === 'Edit' && <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' value={supplierName} />
                                </div>
                            </div> }
                            { props.modalName === 'Add' && <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Supplier.</div>
                                </div>
                            </div> }
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Balance Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={balanceAmount}/>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' value={percentageTypeName} />
                                </div>
                            </div>
                            {/* <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && percentageTypeId === '-2' ? 'is-invalid': ''}`} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                        { percentageTypeData.map( (value, index) => {
                                            return <option key={`commType-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Commission Type.</div>
                                </div>
                            </div> */}
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commissionRate} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Closing Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={closingAmount} onChange={closingAmountChangeHandler} onBlur={e=>setClosingAmount(onBlurHandler(e))} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Difference(+/-) : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={netCurrency} />
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete API Purchase Adjustment?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' disabled={errorMessage} onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(APIPurchaseAdjustmentModal);