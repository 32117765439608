import React, { useState, useEffect } from 'react'; 

import PlanModal from './PlanModal';
import CONSTANTS from '../../../../_constants/constants';
import classes from './PlanMasterPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';
import { ApiService } from '../../../../_services/api.service';

const PlanMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    
    const tableHeaders = ['Plan Name', 'Validity', 'Amount', 'No. of Lapu'];
    const dataFields = ['planName', 'days', 'amount', 'lapu'];
    const tableConfig = {
        headerAlignConfig: {
            'Plan Name': 'center',
            'Validity': 'center',
            'Amount': 'center',
            'No. of Lapu': 'center'
        },
        widthConfig: {
            'Plan Name': 'auto',
            'Validity': '140px',
            'Amount': '140px',
            'No. of Lapu': '140px'
        },
        alignConfig: {
            'planName': 'left',
            'days': 'right',
            'amount': 'right',
            'lapu': 'right'
        }
    };


    useEffect(() => {
        getPlanData();
    }, []);

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getPlanData();
        }
    }

    const getPlanData = () => {
        const url = `${CONSTANTS.API_URL}Plan/AllPlan`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => data.lapu = data.lapu === 0 ? 'Unlimited': data.lapu);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data => 
            (data.planName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.days.toString().includes(filterKeyword.toLowerCase())) ||
            (data.amount.toString().includes(filterKeyword.toLowerCase())) ||
            (data.lapu.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Plan Master'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary btn-block' onClick={e => modalOpenHandler('Add', '')} btnText='Add Plan' btnType='Add' />
                                { modalIsOpen && <PlanModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
            </PageLayout>
            )}    
        </>
    );
  
}

export default PlanMasterPage;