import classes from './SearchBar.module.css';

const SearchBar = props => {
    return (
        <div>
            <label>Search :</label>
            <input
                type='search'
                name='search'
                onChange={props.onFilterKeywordChange}
                className={`form-control ${classes.search}`}
            />
        </div>
    )
}

export default SearchBar;