import { useState, useEffect } from 'react';

import classes from './Layout.module.css';
import Navbar from './Navbar/Navbar';
import Content from './Content/Content';
import Footer from './Footer/Footer';
import { authenticationService } from '../../_services/authentication.service';
import Header from './Header/Header';
import CONSTANTS from '../../_constants/constants';
import { ApiService } from '../../_services/api.service';

const Layout = props => {
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [contentProps, setContentProps] = useState(false);
    const [headerProps, setHeaderProps] = useState(false);
    const [footerProps, setFooterProps] = useState(false);
    
    useEffect(() => {
        getCompanyDetails();
        const subscription = authenticationService.currentUserSubject.subscribe(result => {
            if (result) {
                setIsLoggedIn(true)
            } else {
                setIsLoggedIn(false)
            }
        });
        return subscription.unsubscribe;
    }, []);

    const getCompanyDetails = () => {
        authenticationService.masterLogin()
        .then( result => {
            if(result.data) {
                const { appLogoImage, contactNumber, email, copyRight, officeTimings } = result.data;
                setContentProps({ appLogoImage });
                setHeaderProps({ appLogoImage, contactNumber, email});
                setFooterProps({ copyRight, officeTimings });
            } else if(result.error) {
                console.log(result.error.message);
            }
        },
        error => console.log('Something went wrong!'))
    }

    return (
        <div className={classes.wrapper}>
            <div className={ isLoggedIn ? `${classes.layoutPage}` : 'ml-0'}>
                <div className={classes.content}>
                    { isLoggedIn && <Header props={props} {...headerProps} /> }
                    { isLoggedIn && <Navbar props={props} /> }
                    <Content content={props.children} isLoggedIn={isLoggedIn} {...contentProps} />
                    { isLoggedIn && <Footer {...footerProps} /> }
                </div>
            </div>
        </div>
    );
}

export default Layout;