import Button from '../Button/Button';

const ModalFooter = props => {
    const modalType = props.modalName.split(' ')[0];
    const btnText = props.btnText || (modalType === 'Add' || modalType === 'Edit' ? 'Save' : 'Yes');

    return (
        <div className='modal-footer' style={{textAlign: 'right'}}>
            <Button btnClass='btn btn-secondary' onClick={e => props.onModalClose(e)} btnText={modalType === 'Add' || modalType === 'Edit' ? 'Cancel' : 'No' } />
            <Button btnClass='btn btn-primary' onClick={props.onSaveChanges} btnText={btnText} />
        </div>
    )
}

export default ModalFooter;