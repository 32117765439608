import { useEffect, useState } from 'react';

import Loader from '../../UI/Loader/Loader';
import PageTitle from '../../UI/PageTitle/PageTitle';
import CONSTANTS from '../../../_constants/constants';
import Plans from './Plans';
import classes from './PlansPage.module.css';
import { ApiService } from '../../../_services/api.service';

const PlansPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [planOption, setPlanOption] = useState('monthly');
    const [planData, setPlanData] = useState({});
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        planOptionChangeHandler('monthly');
        getPlanData();
    }, []);

    useEffect(() => {
        !!planData && setSelectedPlans(planData[planOption]);
    }, [planData, planOption]);

    const planOptionChangeHandler = plan => {
        setPlanOption(plan);
    }

    const getPlanData = () => {
        const url = `${CONSTANTS.API_URL}Plan/AllPlan`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setIsLoading(false);
                    mapPlanData(result.data.results);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }
    
    const mapPlanData = planData => {
        planData.sort((a, b) => (a.planId > b.planId && 1) || -1);
        const yearlyPlans = planData.filter( plan => plan.planName.includes('Yearly'));
        yearlyPlans.map( yearlyPlan => yearlyPlan.planName = yearlyPlan.planName.split(' ')[0]);
        const monthlyPlans = planData.filter( plan => plan.planName.includes('Monthly'));
        monthlyPlans.map( monthlyPlan => monthlyPlan.planName = monthlyPlan.planName.split(' ')[0]);
        setPlanData({
            'monthly' : monthlyPlans,
            'yearly': yearlyPlans
        });
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <>
                    <PageTitle title='Plans' />
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className={classes.planOptions}>
                                        <ul className={`nav nav-tabs justify-content-center ${classes.customNav}`} id="plansTab" role="tablist">
                                            <li className='nav-item' role="presentation">
                                                <button className={`nav-link active ${classes.customNavLink}`} id="monthly-tab" data-toggle="tab" data-target="#monthly" type="button" role="tab" aria-controls="monthly" aria-selected="true" onClick={ e => planOptionChangeHandler('monthly')} autoFocus>Monthly</button>
                                            </li>
                                            <li className='nav-item' role="presentation">
                                                <button className={`nav-link ${classes.customNavLink}`} id="yearly-tab" data-toggle="tab" data-target="#yearly" type="button" role="tab" aria-controls="yearly" aria-selected="false" onClick={ e => planOptionChangeHandler('yearly')}>Yearly</button>
                                            </li>
                                        </ul>
                                        {/* <div className='form-check form-check-inline'>
                                            <input className={`form-check-input ${classes.radioBtn}`} checked={planOption === 'monthly'} type='radio' name='plansOptions' id='monthly' value='monthly' onChange={planOptionChangeHandler} />
                                            <label className={`form-check-label ${classes.radioBtnLabel}`} htmlFor='monthly'>Monthly</label>
                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input className={`form-check-input ${classes.radioBtn}`} checked={planOption === 'yearly'} type='radio' name='plansOptions' id='yearly' value='yearly' onChange={planOptionChangeHandler} />
                                            <label className={`form-check-label ${classes.radioBtnLabel}`} htmlFor='yearly'>Yearly</label>
                                        </div> */}
                                    </div>
                                    <div className='row'>
                                        { !!selectedPlans && selectedPlans.length > 0 && <Plans planOption={planOption} planData={selectedPlans} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PlansPage;