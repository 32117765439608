import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const LapuWiseDailyCurrencyPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Supplier', 'Operator', 'Lapu Number', 'Date', 'Opening Stock', 'Received', 'Total Stock', 'Sales', 'Incentive', 'Difference(+/-)', 'Closing Stock'];
    const dataFields = ['supplierName', 'operatorName', 'lapuNumber', 'stockDate', 'openingStock', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStock'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Lapu Number', key: 'lapuNumber' },
        { label: 'Date', key: 'stockDate' },
        { label: 'Opening Stock', key: 'openingStock' },
        { label: 'Received', key: 'received' },
        { label: 'Total Stock', key: 'totalStock' },
        { label: 'Sales', key: 'sales' },
        { label: 'Incentive', key: 'incentive' },
        { label: 'Difference(+/-)', key: 'difference' },
        { label: 'Closing Stock', key: 'closingStock' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left',
            'Operator': 'center',
            'Lapu Number': 'center',
            'Date': 'center',
            'Opening Stock': 'center',
            'Received': 'center',
            'Total Stock': 'center',
            'Sales': 'center', 
            'Incentive': 'center',
            'Difference(+/-)': 'center',
            'Closing Stock': 'center'
        },
        widthConfig: {
            'Supplier': 'auto',
            'Operator': '130px',
            'Lapu Number': '122px',
            'Date': '122px',
            'Opening Stock': '122px',
            'Received': '122px',
            'Total Stock': '122px',
            'Sales': '122px', 
            'Incentive': '122px',
            'Difference(+/-)': '122px',
            'Closing Stock': '122px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'lapuNumber': 'left',
            'stockDate': 'left',
            'openingStock': 'right',
            'received': 'right',
            'totalStock': 'right',
            'sales': 'right',
            'incentive': 'right',
            'difference': 'right',
            'closingStock': 'right'
        }
    };

    useEffect(() => {
        getPurchaseDetailedData();
    }, []);

    const getPurchaseDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = `${CONSTANTS.API_URL}Reports/LapuwiseDailyCurrencyReport?startDate=${window.startDate}&endDate=${window.endDate}`;
        let supplierId = window.supplierId;
        let operatorId = window.operatorId;
        let lapuId = window.lapuId;
        url = supplierId !== '-1' && `${url}&supplierId=${supplierId}`;
        url = operatorId !== '-1' && `${url}&operatorId=${operatorId}`;
        url = lapuId !== '-1' && `${url}&lapuId=${lapuId}`;
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.stockDate = data.stockDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['openingStock', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStock'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['openingStock', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStcok'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        '',
                        total.openingStock,
                        total.received,
                        total.totalStock,
                        total.sales,
                        total.incentive,
                        total.difference,
                        total.closingStcok
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        lapuNumber: '',
                        stockDate: '',
                        openingStock: total.openingStock,
                        received: total.received,
                        totalStock: total.totalStock,
                        sales: total.sales,
                        incentive: total.incentive,
                        difference: total.difference,
                        closingStock: total.closingStcok
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'LapuWiseDailyCurrency';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.supplierName !== '' ? '_' + window.supplierName : '';
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader /> }
            { !isLoading && (
                <PageLayout pageTitle='Lapu Wise Daily Currency'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                paginate={true}
                                filter={false}
                                view={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            ) }    
        </>
    );
  
}

export default LapuWiseDailyCurrencyPage;