import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import CONSTANTS from '../../../../_constants/constants';
import classes from './DeleteLapuTransactionsModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const DeleteLapuTransactionsModal = props => {

    const [supplierId, setSupplierId] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorId, setOperatorId] = useState('');
    const [operatorData, setOperatorData] = useState([]);
    const [lapuNumber, setLapuNumber] = useState('');
    const [deleteLapuAlso, setDeleteLapuAlso] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    useEffect(() => {
        getSupplierList();
        getOperatorList();
        return () => {
            setConfirmDelete(false);
            setShowDeleteWarning(false);
            setSupplierId('-2');
            setOperatorId('-2');
            setLapuNumber('');
            setDeleteLapuAlso(0);
            setErrorMessage(null);
            setHasError(false);
        }
    }, []);

    useEffect(() => {
        if(confirmDelete == false) {
            setErrorMessage(null);
        }
    }, [confirmDelete]);

    const customStyles = {
        content: {
            top: '45%',
            left: '50%',
            right: 'auto',
            width: '40%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',   
        }
    };

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal').then( result => {
            if(result.data) {
                setSupplierId(result.data.results[0].supplierId);
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('modal').then( result => {
            if(result.data) {
                setOperatorId(result.data.results[0].operatorId);
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const lapuNumberChangeHandler = e => {
        setErrorMessage(null);
        setLapuNumber(e.target.value);
    }

    const supplierChangeHandler = e => {
        setErrorMessage(null);
        setSupplierId(e.target.value);
    }

    const operatorChangeHandler = e => {
        setErrorMessage(null);
        setOperatorId(e.target.value);
    }

    const deleteLapuChangeHandler = e => {
        setErrorMessage(null);
        setDeleteLapuAlso(e.target.checked ? 1 : 0);
    }

    const afterOpenModal = () => {
        setHasError(false);
        setOperatorId('-2');
        setSupplierId('-2');
        setLapuNumber('');
        setConfirmDelete(false);
        setShowDeleteWarning(false);
        setErrorMessage(null);
    }
    
    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Lapu/DeleteLapuTransactions?supplierId=${supplierId}&operatorId=${operatorId}&lapuNumber=${lapuNumber}&deleteLapuAlso=${deleteLapuAlso}`;
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            }
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setConfirmDelete(false);
                    props.onCloseModal();
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const onModalClose = e => props.onCloseModal()

    const deleteClickHandler = () => {
        if(supplierId === '-2' || operatorId === '-2' || lapuNumber === '') {
            setHasError(true);
        } else {
            setHasError(false);
            setConfirmDelete(true);
        }
    }

    const confirmYesClickHandler = () => {
        setConfirmDelete(false);
        setShowDeleteWarning(true);
    }

    const warningNoClickHandler = () => {
        setConfirmDelete(false);
        setShowDeleteWarning(false);
    }

    const deleteLapuTransactionsContent = ( <form>
        <div className={`form-group row has-validation ${classes.inputContainer}`}>
            <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
            <div className='col-sm-8'>
                <select className={`form-select inputFieldXl ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                    { supplierData.map( (value, index) => {
                        return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                    }) }
                </select>
                <div className='invalid-feedback'>Please select a Supplier.</div>
            </div>
        </div>
        <div className={`form-group row has-validation ${classes.inputContainer}`}>
            <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
            <div className='col-sm-8'>
                <select className={`form-select inputFieldXl ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                    { operatorData.map( (value, index) => {
                        return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                    }) }
                </select>
                <div className='invalid-feedback'>Please select an Operator.</div>
            </div>
        </div>
        <div className={`form-group row ${classes.inputContainer}`}>
            <label className='col-sm-4 col-form-label inputLabel'>Lapu Number : </label>
            <div className='col-sm-8'>
                <input required className={`form-control inputFieldXl ${hasError && lapuNumber === '' ? 'is-invalid': ''}`} value={lapuNumber} onChange={lapuNumberChangeHandler} />
                <div className='invalid-feedback'>Please enter Lapu Number.</div>
            </div>
        </div>
        <div className="form-check" style={{marginLeft: '33%'}}>
            <input className="form-check-input" type="checkbox" value={deleteLapuAlso} id="flexCheckDefault" onChange={deleteLapuChangeHandler} />
            <label className="form-check-label" htmlFor="flexCheckDefault" value="1">Delete Lapu Also</label>
        </div>
    </form>
    );

    const confirmDeleteContent = (
        <div className={classes.text}>Do you want to Delete All Lapu Transactions for <b>{lapuNumber}</b>?</div>
    );

    const warningContent = (
        <div className={classes.text}>Lapu Transactions once deleted cannot be retrieved. Are you sure you want to continue?</div>
    );

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{confirmDelete ? 'Confirm Delete' : props.modalName}</h4>
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { !confirmDelete && !showDeleteWarning && deleteLapuTransactionsContent }
                    { confirmDelete && confirmDeleteContent }
                    { showDeleteWarning && warningContent }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                { !confirmDelete && !showDeleteWarning && 
                    <>
                        <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText='Cancel' />
                        <Button btnClass='btn btn-primary' onClick={deleteClickHandler} btnText='Delete' />
                    </>
                }
                { confirmDelete && 
                    <>
                        <Button btnClass='btn btn-secondary' onClick={e => setConfirmDelete(false)} btnText='No' />
                        <Button btnClass='btn btn-primary' onClick={confirmYesClickHandler} btnText='Yes' />
                    </>
                }
                { showDeleteWarning && 
                    <>
                        <Button btnClass='btn btn-secondary' onClick={warningNoClickHandler} btnText='No' />
                        <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText='Yes' />
                    </>
                }
            </div>
          </ReactModal>
        </div>
    );

}

export default DeleteLapuTransactionsModal;