import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import SupplierWiseCurrencyPurchaseTransactionModal from './SupplierWiseCurrencyPurchaseTransactionModal';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { parseFloat2Decimal, uiDateToday, uiMonthStartDate } from '../../../../_helpers/utils';
import classes from './SupplierWiseCurrencyPurchaseTransactionPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseCurrencyPurchaseTransactionPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [fromDate, setFromDate] = useState(uiMonthStartDate());
    const [toDate, setToDate] = useState(uiDateToday());
    const [supplierId, setSupplierId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Date', 'Supplier', 'Operator', 'Purchase Amount', 'Commission Type', 'Commission %', 'Commission', 'Difference(+/-)', 'Net Received'];
    const dataFields = ['transactionDate', 'supplierName', 'operatorName', 'netAmount', 'percentageTypeName', 'commissionRate', 'commission', 'adjustment', 'netCurrency'];
    const csvHeaders = [
        { label: 'Date', key: 'transactionDate' },
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Purchase Amount', key: 'netAmount' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Difference(+/-)', key: 'adjustment' },
        { label: 'Net Received', key: 'netCurrency' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'left',
            'Supplier': 'left',
            'Operator': 'center',
            'Purchase Amount': 'center',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Difference(+/-)': 'center',
            'Net Received': 'center'
        },
        widthConfig: {
            'Date': '96px',
            'Supplier': '300px',
            'Operator': '154px',
            'Purchase Amount': '154px',
            'Commission Type': '154px',
            'Commission %': '154px',
            'Commission': '154px',
            'Difference(+/-)': '154px',
            'Net Received': '154px'
        },
        alignConfig: {
            'transactionDate': 'left',
            'supplierName': 'left',
            'operatorName': 'center',
            'netAmount': 'right', 
            'percentageTypeName': 'center', 
            'commissionRate': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'netCurrency': 'right'
        }
    }

    useEffect(() => {
        getSupplierList();
        getOperatorList();
        getCurrencyPurchaseData();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate, supplierId, operatorId]);

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }
    
    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.transactionDate && data.transactionDate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.supplierName && data.supplierName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.netAmount && data.netAmount.toString().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.commission && data.commission.toString().includes(filterKeyword.toLowerCase())) ||
            (data.adjustment && data.adjustment.toString().includes(filterKeyword.toLowerCase())) ||
            (data.netCurrency && data.netCurrency.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    const modalCloseHandler = (status, message) => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCurrencyPurchaseData();
        }
    }

    const getCurrencyPurchaseData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}LapuPurchase/AllLapuPurchase?startDate=${fromDate}&endDate=${toDate}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}LapuPurchase/AllLapuPurchase?startDate=${fromDate}&endDate=${toDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}LapuPurchase/AllLapuPurchase?startDate=${fromDate}&endDate=${toDate}&supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}LapuPurchase/AllLapuPurchase?startDate=${fromDate}&endDate=${toDate}&supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.transactionDate = data.transactionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['netAmount', 'commissionRate', 'commission', 'adjustment', 'netCurrency'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    const totals = result.data.total[0];
                    setTotalData([
                        '',
                        '',
                        (totals.netAmount).toFixed(2),
                        '',
                        '',
                        (totals.commission).toFixed(2),
                        (totals.adjustment).toFixed(2),
                        (totals.netCurrency).toFixed(2),
                        ''
                    ]);
                    setExportTotalData({
                        transactionDate: 'Total',
                        supplierName: '',
                        operatorName: '',
                        netAmount: (totals.netAmount).toFixed(2),
                        percentageTypeName: '',
                        commissionRate: '',
                        commission: (totals.commission).toFixed(2),
                        adjustment: (totals.adjustment).toFixed(2),
                        netCurrency: (totals.netCurrency).toFixed(2)
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        setModalData(modalData === '' ? {}: modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const showClickHandler = () => {
        setIsLoading(true);
        getCurrencyPurchaseData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    const supplierChangeHandler = event => setSupplierId(event.target.value)
    
    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const getExportFileName = () => {
        let exportFilename = 'LapuPurchase';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += supplierId !== '-1' ? '_' + supplierData.find(data => data.supplierId == supplierId)['supplierName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                    <PageLayout pageTitle='Lapu Purchase'>
                        <div className='row mb-2'>
                            <div className={`col-12 ${classes.headerActions}`}>
                                <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                                <div>
                                    <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add Lapu Purchase' btnTitle='Add Lapu Purchase' btnType='Add' />
                                    { modalIsOpen && <SupplierWiseCurrencyPurchaseTransactionModal
                                        modalData={modalData}
                                        modalName={modalName}
                                        isModalOpened={modalIsOpen}
                                        onCloseModal={modalCloseHandler}
                                        onAfterOpen={handleAfterOpen}
                                    /> }
                                    
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                                <form className='form-inline'>
                                    {/* <div className={`form-group ${classes.inputContainer}`}>
                                        <label className='col-form-label inputLabel'>From Date: </label>
                                        <input type='date' required className='form-control form-control-sm inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                    </div> */}
                                    <div className={`form-group ${classes.inputContainer}`}>
                                        <label className='col-form-label inputLabel'>From Date : </label>
                                        <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                    </div>
                                    <div className={`form-group ${classes.inputContainer}`}>
                                        <label className='col-form-label inputLabel'>To Date : </label>
                                        <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                    </div>
                                    <div className={`form-group ${classes.inputContainer}`}>
                                        <label className='col-form-label inputLabel'>Supplier : </label>
                                        <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={supplierChangeHandler} value={supplierId}>
                                            { supplierData.map( (value, index) => {
                                                return <option key={`supplier-${index}`} className='dropdownItem' value={value.supplierId}>{value.supplierName}</option>
                                            }) }
                                        </select>
                                    </div>
                                    <div className={`form-group ${classes.inputContainer}`}>
                                        <label className='col-form-label inputLabel'>Operator : </label>
                                        <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={operatorChangeHandler} value={operatorId}>
                                            { operatorData.map( (value, index) => {
                                                return <option key={`operator-${index}`} className='dropdownItem' value={value.operatorId}>{value.operatorName}</option>
                                            }) }
                                        </select>
                                    </div>
                                </form>
                                <div>
                                    <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' btnTitle='Show' />
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            </div>
                        </div>
                        { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                        <Table 
                            tableHeaders={tableHeaders} 
                            dataFields={dataFields} 
                            tableConfig={tableConfig}
                            pageCount={pageCount} 
                            onModalOpen={modalOpenHandler} 
                            sort={true}
                            actions={true}
                            edit={true} 
                            delete={true} 
                            paginate={true}
                            filter={true}
                            rowClick={false}
                            gridData={gridData}
                            totalData={totalData}
                            filterKeyword={filterKeyword}
                            onFilterData={filterDataHandler}
                        />
                </PageLayout>
            )}
        </>
    );
  
}

export default SupplierWiseCurrencyPurchaseTransactionPage;