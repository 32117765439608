import { useEffect, useMemo, useState } from 'react';
import { FaPencilAlt, FaTrashAlt, FaEye, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import Pagination from '../Pagination/Pagination';

import CONSTANTS from '../../../_constants/constants';
import classes from './Table.module.css';

const Table = props => {

    const filter = !!props.filterKeyword && props.filterKeyword;
    const doFilter = props.filter;

    const [tableConfig, setTableConfig] = useState({});
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [forcePageChange, setForcePageChange] = useState(0);
    const [pageData, setPageData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterKeyword, setFilterKeyword] = useState(filter);
    const [recordsPerPage, setRecordsPerPage] = useState(CONSTANTS.RECORDS_PER_PAGE);
    const [startIndex, setStartIndex] = useState(1);

    useEffect(() => {
        setTableConfig(props.tableConfig);
    }, []);
    
    useEffect(() => {
        doFilter && setFilterKeyword(filter);
    }, [filter, doFilter]);

    useEffect(() => {
        setGridData(props.gridData);
        doFilter && setFilteredData(props.gridData);
    }, [doFilter, props.gridData]);

    useEffect(() => {
        props.paginate && setPageData(gridData.slice(currentPage * recordsPerPage, currentPage * recordsPerPage + recordsPerPage)); 
        !props.paginate && setPageData(gridData); 
    }, [currentPage, gridData]);

    useEffect(() => {
        setPageData(gridData.slice(currentPage * recordsPerPage, currentPage * recordsPerPage + recordsPerPage)); 
        let start = currentPage * recordsPerPage + 1;
        setStartIndex(start > gridData.length ? 1 : start);
    }, [recordsPerPage]);

    useMemo(() => {
        props.sort && setGridData(gridData.sort((a,b) => sortOrder ? ((a[sortField] > b[sortField]) ? 1 : -1) : ((b[sortField] > a[sortField]) ? 1 : -1)));
        props.sort && props.paginate && setPageData(gridData.slice(currentPage * recordsPerPage, currentPage * recordsPerPage + recordsPerPage)); 
        props.sort && !props.paginate && setPageData(gridData); 
    }, [sortField, sortOrder]);

    useMemo(() => {
        if(doFilter) {
            setGridData(props.onFilterData(filteredData));
            setCurrentPage(0);
            setForcePageChange(0);
        }
    }, [filterKeyword]);
    
    const sortHandler = sortField => {
        setSortField(sortField);
        setSortOrder(!sortOrder);
    }

    // const sortHandler = (sortField, sortOrder) => {
    //     setSortField(sortField);
    //     setSortOrder(sortOrder);
    // }

    const handlePageChange = page => {
        setCurrentPage(page.selected);
        let start = page.selected * recordsPerPage + 1;
        setStartIndex(start > gridData.length ? 1 : start);
    }

    const actionClickHandler = (modalName, index) => {
        const modalData = index > -1 ? pageData[index]: '';
        props.onModalOpen(modalName, modalData);
    }

    const rowClickHandler = index => props.view && props.onRowClick(pageData[index])

    const entriesChangeHandler = entries => {
        setRecordsPerPage(entries);
        if(currentPage > (gridData.length / entries)) {
            const page = Math.ceil(gridData.length/entries)-1;
            setForcePageChange(page);
            handlePageChange({selected: page});
        } else {
            setForcePageChange(currentPage);
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    { props.paginate && props.gridData.length > 0 && 
                        <Pagination 
                            pageCount={props.pageCount} 
                            recordCount={props.gridData.length} 
                            onPageChange={handlePageChange} 
                            startIndex={startIndex} 
                            onEntriesChange={entriesChangeHandler}
                            forcePageChange={forcePageChange}
                        />}
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12'>
                <div className={classes.tableContainer}>
                    <table className={`table table-bordered table-nowrap ${classes.dataTable}`} role='grid'>
                        { props.mergedHeaders && 
                            <thead style={{height: '40px'}}>
                                <tr role='row'>
                                    {props.mergedHeaders.map((header, index) => {
                                        return (
                                            <th 
                                                scope='col' 
                                                className={classes.thead} 
                                                key={`MergedHeader-${index}`} 
                                                colSpan={header.colspan}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {header.key}
                                                {
                                                    props.sort && 
                                                    <div className={classes.sortIcons}>
                                                        <i className='fa fa-sort' aria-hidden='true'></i>
                                                        {/* <i className='fa fa-sort' aria-hidden='true' onClick={e => sortHandler(props.dataFields[index])}></i> */}
                                                        {/* <FaSortUp style={{verticalAlign: 'bottom'}} onClick={e => sortHandler(props.dataFields[index], true)} /> */}
                                                        {/* <FaSortDown style={{verticalAlign: 'top'}} onClick={e => sortHandler(props.dataFields[index], false)}/> */}
                                                        {/* <FaAngleUp onClick={e => sortHandler(props.dataFields[index], true)} /> */}
                                                        {/* <FaAngleDown onClick={e => sortHandler(props.dataFields[index], false)}/> */}
                                                    </div>
                                                }
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                        }
                        <thead>
                            <tr role='row'>
                                <th scope='col' className={classes.thead} style={{textAlign: 'center', width: '52px'}}>#</th>
                                { props.tableHeaders.map((header, index) => {
                                    return (
                                        <th 
                                            scope='col' 
                                            className={classes.thead} 
                                            key={`Header-${index}`} 
                                            style={{ 
                                                cursor: props.sort ? 'pointer' : 'auto',
                                                width: (!!tableConfig.widthConfig) && tableConfig.widthConfig[header],
                                                minWidth: (!!tableConfig.widthConfig) && tableConfig.widthConfig[header],
                                                textAlign: (!!tableConfig.headerAlignConfig) && tableConfig.headerAlignConfig[header]
                                            }}
                                            onClick={props.sort ? e=>sortHandler(props.dataFields[index]) : undefined}
                                        >
                                            {header}
                                            {
                                                props.sort && 
                                                <div className={classes.sortIcons}>
                                                    <i className='fa fa-sort' aria-hidden='true'></i>
                                                    {/* <i className='fa fa-sort' aria-hidden='true' onClick={e => sortHandler(props.dataFields[index])}></i> */}
                                                    {/* <FaSortUp style={{verticalAlign: 'bottom'}} onClick={e => sortHandler(props.dataFields[index], true)} /> */}
                                                    {/* <FaSortDown style={{verticalAlign: 'top'}} onClick={e => sortHandler(props.dataFields[index], false)}/> */}
                                                    {/* <FaAngleUp onClick={e => sortHandler(props.dataFields[index], true)} /> */}
                                                    {/* <FaAngleDown onClick={e => sortHandler(props.dataFields[index], false)}/> */}
                                                </div>
                                            }
                                        </th>
                                    );
                                })}
                                {props.actions && <th scope='col' className={classes.thead} style={{width: '70px', minWidth: '70px', maxWidth: '70px', textAlign: 'center'}}>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            { gridData.length === 0 && (
                                <tr className={classes.noDataRow}>
                                    <td colSpan={props.actions ? props.dataFields.length + 2 : props.dataFields.length + 1 } className={classes.noData}>
                                        Data not found.
                                    </td>
                                </tr>
                            )}
                            { gridData.length > 0 && props.totalData &&
                                (<tr key='totals' className={classes.totalRow}>
                                    <td key='total#'></td>
                                    <td key='total' style={{textAlign: 'left'}}>Total</td>
                                    { props.totalData.map((total, index) => {
                                        return (
                                            <td key={`${'total-' + index}`} style={{textAlign: 'right'}}>{total}</td>
                                        )
                                    })}
                                </tr>)
                            }
                            { pageData.length > 0 && pageData.map(( tableData, index ) => {
                                return (
                                    <tr 
                                        key={index} 
                                        className={`
                                            ${props.invalidCheck && tableData[props.invalidCheckKey] === props.invalidCheckValue ? classes.isInvalid: ''}
                                            ${props.status && tableData.isDeactivated ? classes.inactive: ''}
                                            ${classes.tableRow}
                                        `} 
                                    >
                                    <td key={index} style={{textAlign: 'right'}}>{index+1}</td>
                                    { props.dataFields.map((field, index) => {
                                        return (
                                            <td 
                                                key={`${'field-' + index}`} 
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig[field]) && tableConfig.alignConfig[field]
                                                }}
                                            >
                                                {typeof tableData[field] === 'boolean' ? (tableData[field] ? 'Yes' : 'No') : tableData[field]}
                                            </td>
                                        )
                                    })}
                                    { props.actions && <td>
                                        { props.status && !tableData.isDeactivated && <span className={classes.actionBtn}><FaToggleOn title='Click to Deactivate' style={{color: '#42d29d'}} onClick={e => actionClickHandler('Deactivate', index)} /></span> }
                                        { props.status && tableData.isDeactivated && <span className={classes.actionBtn}><FaToggleOff title='Click to Activate' style={{color: '#919ca7', opacity: '1!important'}} onClick={e => actionClickHandler('Activate', index)} /></span> }
                                        { props.view && <span className={classes.actionBtn}><FaEye title='View' style={{color: '#17a2b8'}} onClick={e => rowClickHandler(index)} /></span> }
                                        { props.edit && <span className={classes.actionBtn}><FaPencilAlt title='Edit' style={{color: '#ffc107'}} className={`${!!tableData[props.disableUpdateKey] ? classes.disabledIcon : ''}`} onClick={e => actionClickHandler('Edit', index)} /></span> }
                                        { props.delete && <span className={classes.actionBtn}><FaTrashAlt title='Delete' style={{color: '#dc3545'}} className={`${!!tableData[props.disableUpdateKey] ? classes.disabledIcon : ''}`} onClick={e => actionClickHandler('Delete', index)} /></span> }
                                    </td> }
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </>
    );
}

export default Table;