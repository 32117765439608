import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import classes from './Pagination.module.css';
import CONSTANTS from '../../../_constants/constants';

const Pagination = props => {

    const {startIndex, recordCount} = props;
    const [pageCount, setPageCount] = useState(props.pageCount);
    const [showEntries, setShowEntries] = useState(CONSTANTS.RECORDS_PER_PAGE);
    let end = startIndex + parseInt(showEntries) - 1;
    let endIndex = end > recordCount ? recordCount : end;

    useEffect(() => {
        setPageCount(Math.ceil(recordCount/showEntries));
        props.onEntriesChange(showEntries);
    }, [showEntries]);

    const entriesChangeHandler = e => {
        setShowEntries(parseInt(e.target.value));
    }

    return (
        <div className={classes.paginationPage}>
            <div className={`row ${classes.paginationContainer}`}>
                <div className={`col-sm-12 col-md-3 ${classes.showEntries}`}>
                    Show 
                    <select value={showEntries} className={`form-select ${classes.entriesSelect}`} onChange={entriesChangeHandler} >
                        { CONSTANTS.ENTRIES.map( (value, index) => {
                            return <option key={`entry-${index}`} value={value} className='dropdownItem'>{value}</option>
                        }) }
                    </select>
                    entries
                </div>
                <div className='col-sm-12 col-md-6'>
                    <ReactPaginate
                        onPageChange={props.onPageChange}
                        forcePage={props.forcePageChange}
                        pageCount={pageCount}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        containerClassName={classes.container}
                        previousLabel={<FaAngleLeft style={{paddingBottom: '2px'}}/>}
                        previousClassName={classes.previous}
                        previousLinkClassName={classes.previousLink}
                        breakClassName={classes.break}
                        nextLabel={<FaAngleRight style={{paddingBottom: '2px'}}/>}
                        nextClassName={classes.next}
                        nextLinkClassName={classes.nextLink}
                        pageClassName={classes.page}
                        disabledClassName={classes.disabled}
                        activeClassName={classes.active}
                    />
                </div>
                <div className={`col-sm-12 col-md-3 ${classes.recordCount}`}>
                    Showing {startIndex} to {endIndex} of {props.recordCount} entries
                </div>
            </div>
        </div>
    );
}

export default Pagination;