import classes from './Plans.module.css';
import Plan from './Plan';
import { useEffect, useState } from 'react';
import { ApiService } from '../../../_services/api.service';
import CONSTANTS from '../../../_constants/constants';

const Plans = props => {

    const [currentPlanId, setCurrentPlanId] = useState('');

    useEffect(() => {
        getCurrentPlanDetails();
    }, []);

    const getCurrentPlanDetails = () => {
        const url = `${CONSTANTS.API_URL}Company/Company`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                  setCurrentPlanId(result.data.planId);
                } else {
                    setCurrentPlanId('');
                }
            },
            () => setCurrentPlanId('')
        );
    }

    return (
        <div className='tab-content' id='myTabContent'>
            <div className={`tab-pane show ${classes.plans}`} id={props.planOption} aria-labelledby={`${props.planOption}-tab`}>
                { props.planData.map(plan => {
                    return <Plan 
                        key={plan.planName}
                        planId={plan.planId}
                        planName={plan.planName}
                        amount={plan.amount}
                        days={plan.days}
                        lapu={plan.lapu == 0 ? 'Unlimited' : plan.lapu} 
                        isCurrent={currentPlanId == plan.planId}
                    />
                })}
            </div>
            {/* <div className={`tab-pane fade ${classes.plans}`} id='yearly' aria-labelledby='yearly-tab'>
                { props.planOption === 'yearly' && props.planData.map(plan => {
                    return <Plan key={plan.planName}
                        planName={plan.planName}
                        amount={plan.amount}
                        days={plan.days}
                        lapu={plan.lapu} 
                    />
                })}
            </div> */}
        </div>
    )
}

export default Plans;