import { FaCheckCircle } from 'react-icons/fa';
import { useCallback, useEffect, useState } from 'react';
import useRazorpay from'react-razorpay';
import PaymentModal from './Payments/PaymentModal';
// import hmacSHA256 from 'crypto-js/hmac-sha256';
// import Base64 from 'crypto-js/enc-base64';

import classes from './Plan.module.css';

const Plan = props => {

  const { planName, amount, days, lapu, isCurrent } = props;
  const [orderId, setOrderId] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const planBenefits = [`Add ${lapu} Lapu`, 'Unlimited Providers', 'Unlimited Operators', 'Unlimited Users'];

  const RazorpayUI = useRazorpay();

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
     document.body.appendChild(script);
   });
  };

  // const purchaseClickHandler = useCallback(() => {

  //   createOrder();

  //   const options = {
  //     key: "rzp_test_FEbw8S1WaJCmkx", // Replce with API key from backend
  //     name: "Pavathi Plan Purchase",
  //     description: `Plan - ${planName}, Validity - ${days} days`,
  //     order_id: 'order_IheS0OAiBg7iFo', // Replace with orderId
  //     handler: (res) => verifyPayment(res), // Signature verification will be done in backend. Send response to backend
  //     prefill: {
  //       name: `Plan - ${planName}, Validity - ${days} days`,
  //       amount: amount * 100,
  //       email: "support@gmail.com", //Replace with user API email
  //       contact: 9999999012 // Replace with user mobile number
  //     },
  //     notes: {
  //       plan: planName,
  //       validity: `${days} days`
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const rzpay = new RazorpayUI(options);
  //   rzpay.open();

  // }, [RazorpayUI]);

  // const createOrder = () =>{
  //   // Call order API & setOrderId
  // }

  // const verifyPayment = res => {
  //   // Call API & provide payment API response
  // }

  // const verifySignature = res => {
  //   const secret = 'BL0nyHAv782W1IDvR1aj0xc2';
  //   const hashDigest = hmacSHA256( orderId + "|" + res.razorpay_payment_id, secret);
  //   console.log(hashDigest, res.razorpay_signature)
  //   if(hashDigest === res.razorpay_signature) {
  //     console.log('Payment verified!');
  //   } else {
  //     console.log('Payment not verified!');
  //   }
  // }

  const purchaseClickHandler = () => {
    setModalIsOpen(true);
    setModalData(props);
  }

  const modalCloseHandler = status => {
    setModalIsOpen(false);
    // if(status === 'save') {
        // getCompanyData();
    // }
  }

  const handleAfterOpen = (event, data) => console.log(event, data)

  return (
    <div className={`card mb-0 ${classes.planCard}`}>
        <div className={`card-body ${classes.plan}`}>
            <div className='p-2'>
                { isCurrent && 
                  <div className={classes.currentPlan}>
                    <span className={classes.currentPlanBadge}>Current</span>
                  </div>
                }
                <h5 className='font-16'>{planName}</h5>
                <h1 className='mt-3'>
                    ₹{amount}
                    <span className='text-muted font-16 fw-medium'> / {days} Days</span>
                </h1>
                <div className='mt-4 pt-2'>
                    { planBenefits.map( (planBenefit, index) => 
                      <p className='mb-3 font-15' key={index}><FaCheckCircle className='text-primary font-18 me-2' />{planBenefit}</p>
                    )}
                </div>
                <div className='mt-4 pt-2'>
                    <button className='btn btn-outline-primary btn-block' onClick={purchaseClickHandler}>Purchase</button>
                    { modalIsOpen && <PaymentModal
                        modalData={modalData}
                        isModalOpened={modalIsOpen}
                        onCloseModal={modalCloseHandler}
                        onAfterOpen={handleAfterOpen}
                    />}
                </div>
            </div>
        </div>
    </div>
  );
}

export default Plan;