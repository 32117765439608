import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import SupplierCommissionModal from './SupplierCommissionModal';
import classes from './SupplierCommMasterPage.module.css';
import CONSTANTS from '../../../../_constants/constants';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { parseFloat2Decimal } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import Button from '../../../UI/Button/Button';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierCommMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [supplierId, setSupplierId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');

    const tableHeaders = ['Supplier', 'Operator', 'Commission Type', 'Commission %'];
    const dataFields = ['supplierName', 'operatorName', 'percentageTypeName', 'commissionRate'];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left',
            'Operator': 'center',
            'Commission Type': 'center',
            'Commission %': 'center'
        },
        widthConfig: {
            'Supplier': 'auto',
            'Operator': '162px',
            'Commission Type': '162px',
            'Commission %': '162px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'percentageTypeName': 'center',
            'commissionRate': 'right'
        }
    }

    useEffect(() => { 
        getSupplierList();
        getOperatorList();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getCommissionData();
    }, [supplierId, operatorId]);
    
    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.supplierName && data.supplierName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().toLowerCase().includes(filterKeyword.toLowerCase()))
        );
    }

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            setIsLoading(true);
            getCommissionData();
        }
    }

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getCommissionData = () => {
        setErrorMessage(null);
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}LapuInput/AllLapuInput` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}LapuInput/AllLapuInput?operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}LapuInput/AllLapuInput?supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}LapuInput/AllLapuInput?supplierId=${supplierId}&operatorId=${operatorId}`;
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( d => d.commissionRate = d.commissionRate.toFixed(3));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({supplierId}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const supplierChangeHandler = event => {
        setErrorMessage(null);
        setSupplierId(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        setErrorMessage(null);
        setOperatorId(event.target.value);
    }
    
    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value)
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Supplier Commission Master'>
                    <div className='row mb-3'>
                        <div className='col-sm-6 col-md-6'>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Supplier : </label>
                                    <select value={supplierId} className='form-select customSelect' onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId} className='dropdownItem'>{value.supplierName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select  value={operatorId} className='form-select customSelect' onChange={operatorChangeHandler} >
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId} className='dropdownItem'>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className={`col-sm-6 col-md-6 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add Commission' btnTitle='Add Supplier Commission' btnType='Add' />
                                { modalIsOpen && <SupplierCommissionModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default SupplierCommMasterPage;