import PageTitle from '../../UI/PageTitle/PageTitle';

const HelpPage = () => {
    return (
        <>
            <PageTitle title='Help' />
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <p style={{textAlign: 'center', fontWeight: 'bold', top: '50%', height: '52vh'}}>Help Section in progress!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpPage;