import { useEffect } from 'react';
import { FaCopy, FaEye, FaFileExport, FaFileImport, FaFilter, FaPrint, FaSms, FaTrash, FaUserPlus } from 'react-icons/fa';

import classes from './Button.module.css';

const Button = props => {
    
    const { btnClass, btnText, btnTitle, btnType, dataToggle, dataTitle, dataContent, disabled, onClick, isPopover } = props;

    useEffect(() => {
        isPopover && window.jQuery('#popoverExample').popover();
    }, [isPopover])

    return (
        <button type='button' className={btnClass} disabled={disabled} onClick={onClick} data-trigger='click' data-toggle={dataToggle} data-container="body" data-placement="bottom" title={dataTitle} data-content={dataContent}>
            <span className={classes.btnText}>{btnText}</span>
            { btnType === 'Close' && <span aria-hidden='true'>&times;</span> }
            { btnType === 'Add' && <FaUserPlus title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Filter' && <FaFilter title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Delete' && <FaTrash title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Print' && <FaPrint title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Send' && <FaSms title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Export' && <FaFileExport title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Import' && <FaFileImport title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'Compare' && <FaCopy title={btnTitle} className={classes.btnIcon} /> }
            { btnType === 'View' && <FaEye title={btnTitle} className={classes.btnIcon} /> }
        </button>
    )
}

export default Button;