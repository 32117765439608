import React from 'react';
import _ from 'lodash';

import warning from '../../../assets/images/warning_img.png';
import classes from './ErrorBoundary.module.css';

import Button from '../../UI/Button/Button';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    reloadClickHandler = () => {
      window.location.reload();
    }
    
    componentDidCatch() {
      this.setState({ hasError: true, errorIndex: _.random(1, 3, false) });
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <div className={`container-fluid ${classes.errorBoundary}`}>
                <div className={classes.imgContainer}>
                    <img src={warning} alt='Something went wrong!' className={` col-12 ${classes.image}`} />
                </div>
                <div>
                    <div className={classes.errorText}>Something went wrong!</div>
                    <div>
                      <Button btnClass='btn btn-link' onClick={this.reloadClickHandler} btntext='Reload' />
                    </div>
                </div>
            </div>
        )
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary;