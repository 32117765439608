import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import LocalMarketCommissionModal from './LocalMarketCommissionModal';
import classes from './LocalMarketCommMasterPage.module.css';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import Button from '../../../UI/Button/Button';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const LocalMarketCommMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');

    const tableHeaders = ['Operator', 'Market Commission %'];
    const dataFields = ['operatorName', 'commissionRate'];
    const tableConfig = {
        headerAlignConfig: {
            'Operator': 'left',
            'Market Commission %': 'center'
        },
        widthConfig: {
            'Operator': 'auto',
            'Market Commission %': '186px'
        },
        alignConfig: {
            'operatorName': 'left',
            'commissionRate': 'right'
        }
    }

    useEffect(() => { getCommissionData() }, []);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().toLowerCase().includes(filterKeyword.toLowerCase()))
        )       
    }

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCommissionData();
        }
    }

    const getCommissionData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        fetch(`${CONSTANTS.API_URL}LapuOutput/AllLapuOutput`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( d => d.commissionRate = d.commissionRate.toFixed(3));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value)

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && ( 
                <PageLayout pageTitle='Local Market Commission Master'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add Local Commission' btnTitle='Add Local Commission' btnType='Add' />
                                <LocalMarketCommissionModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true}  
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                    <div className={classes.note}>
                        <h5>Note: Enter Distributor Commission + Retailer Commission. Example: TATASKY Distributor Commission = 1% and Retailer Commission = 2% then TATASKY Local Market Commission = 3.5%</h5>
                    </div>
                </PageLayout>
            )}
        </>
    );
  
}

export default LocalMarketCommMasterPage;