import React, { useState, useEffect } from 'react'; 

import CompanyModal from './CompanyModal';
import CONSTANTS from '../../../../_constants/constants';
import classes from './CompanyMasterPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';
import { uiDate } from '../../../../_helpers/utils';
import { ApiService } from '../../../../_services/api.service';

const CompanyMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    
    const tableHeaders = ['Company Name', 'Company Code', 'Address Line 1', 'Address Line 2', 'City', 'Pincode', 'State', 'GSTIN', 'Phone Number', 'Email', 'Plan Name', 'Expiry Date'];
    const dataFields = ['companyName', 'companyCode', 'address1', 'address2', 'city', 'pincode', 'state', 'gstin', 'phoneNumber', 'email', 'planName', 'expiryDate'];
    const tableConfig = {
        headerAlignConfig: {
            'Company Name': 'center',
            'Company Code': 'center',
            'Address Line 1': 'center',
            'Address Line 2': 'center',
            'City': 'center',
            'Pincode': 'center',
            'State': 'center',
            'GSTIN': 'center',
            'Phone Number': 'center',
            'Email': 'center',
            'Plan Name': 'center',
            'Expiry Date': 'center'
        },
        widthConfig: {
            'Company Name': 'auto',
            'Company Code': '140px',
            'Address Line 1': '140px',
            'Address Line 2': '140px',
            'City': '140px',
            'Pincode': '140px',
            'State': '140px',
            'GSTIN': '140px',
            'Phone Number': '140px',
            'Email': '140px',
            'Plan Name': '140px',
            'Expiry Date': '140px'
        },
        alignConfig: {
            'companyName': 'left',
            'companyCode': 'right',
            'address1': 'left',
            'address2': 'left',
            'city': 'center',
            'pincode': 'right',
            'state': 'center',
            'gstin': 'right',
            'phoneNumber': 'right',
            'email': 'center',
            'planName': 'center',
            'expiryDate': 'center'
        }
    };


    useEffect(() => {
        getCompanyData();
    }, []);

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCompanyData();
        }
    }

    const getCompanyData = () => {
        const url = `${CONSTANTS.API_URL}Company/AllCompany`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => data.expiryDate = uiDate(data.expiryDate));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data => 
            (data.companyName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.companyCode.toString().includes(filterKeyword.toLowerCase())) ||
            (data.address1.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.address2.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.city.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.pincode.toString().includes(filterKeyword.toLowerCase())) ||
            (data.state.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.gstin.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.phoneNumber.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.email.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.planName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.expiryDate.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Company Master'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                { modalIsOpen && <CompanyModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default CompanyMasterPage;