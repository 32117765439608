import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CONSTANTS from '../../../../_constants/constants';

import { handleResponse } from '../../../../_helpers/handle-response';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecimalInput } from '../../../../_helpers/validators';
import { ApiService } from '../../../../_services/api.service';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import classes from './LapuSimModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const LapuSimModal = props => {

    const [lapuNumber, setLapuNumber] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [lapuOpening, setLapuOpening] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [enableLapuNumber, setEnableLapuNumber] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        props.modalName === 'Add' && getSupplierList();
        if(props.modalName === 'Edit') {
            getOperatorList();
            getOperatorWiseSupplierList(props.modalData.operatorId);
            // (props.modalData.supplierId !== '-1') && getOperatorListBySupplier(props.modalData.supplierId);
            !!props.modalData.lapuId && getTransactionCount();
        }
        return () => setLapuNumber('')
    }, []);

    const customStyles = {
        content: {
          top: '45%',
          left: '50%',
          right: 'auto',
          width: '34%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getOperatorListBySupplier = supplierId => {
        DropdownDataService.getOperatorListBySupplier('modal', supplierId).then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    !!props.modalData.operatorId &&setOperatorId(props.modalData.operatorId);
                }
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorWiseSupplierList = operatorId => {
        DropdownDataService.getOperatorWiseSupplierList('modal', operatorId).then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('modal').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = event => {
        setLapuNumber(!!props.modalData.lapuNumber ? props.modalData.lapuNumber : '');
        setSupplierId(!!props.modalData.supplierId && props.modalData.supplierId !== '-1' ? props.modalData.supplierId : '-2');
        setOperatorId(!!props.modalData.operatorId && props.modalData.operatorId !== '-1' ? props.modalData.operatorId : '-2');
        setLapuOpening(!!props.modalData.lapuOpening ? props.modalData.lapuOpening : (0).toFixed(2));
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel');
    }
    
    const lapuNumberChangeHandler = event => {
        setErrorMessage(null);
        setLapuNumber(event.target.value);
    }
    
    const supplierChangeHandler = event => {
        setErrorMessage(null);
        setSupplierId(event.target.value);
        // setOperatorId('-2');
        props.modalName === 'Add' && getOperatorListBySupplier(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        setErrorMessage(null);
        setOperatorId(event.target.value);
    }

    const lapuOpeningChangeHandler = event => {
        setErrorMessage(null);
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setLapuOpening(value);
            }
        } else {
            setLapuOpening(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const getTransactionCount = () => {
        const url = `${CONSTANTS.API_URL}Lapu/LapuTransactionCount?lapuId=${props.modalData.lapuId}`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setEnableLapuNumber(result.data.transactionCount === 0);
                } else {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChanges = (reqBody, url, reqMethod) => {
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(`${CONSTANTS.API_URL}Lapu/${url}`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(supplierId === '-2' || operatorId === '-2' || lapuNumber === '' || lapuOpening === '' ) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                reqBody = {
                    lapuNumber,
                    supplierId: supplierId,
                    operatorId: operatorId,
                    lapuOpening
                };
                url = 'AddLapu';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                reqBody = {
                    lapuNumber,
                    supplierId: supplierId,
                    operatorId: operatorId,
                    lapuOpening
                };
                url = `UpdateLapu?lapuId=${props.modalData.lapuId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteLapu?lapuId=${props.modalData.lapuId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} Lapu Sim</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Lapu Number : </label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled={props.modalName === 'Edit' && !enableLapuNumber} className={`form-control inputField ${hasError && lapuNumber === '' ? 'is-invalid': ''}`} value={lapuNumber} onChange={lapuNumberChangeHandler} autoFocus />
                                    <div className='invalid-feedback'>Please enter Lapu Number.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select value={supplierId} className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select a Supplier.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                <div className='col-sm-8'>
                                    <select disabled={props.modalName === 'Edit'} className={`form-select inputField ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} value={operatorId} onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select an Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Lapu Opening :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && lapuOpening === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={lapuOpening} onChange={lapuOpeningChangeHandler} onBlur={ e => setLapuOpening(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter Lapu Opening.</div>
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete Lapu/Sim Number?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(LapuSimModal);