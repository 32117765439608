const TERMS_N_CONDITIONS = [
    {
        'title': 'Membership',
        'text': 'The membership of this Website is available only to those who register with us, if we fund fraud in membership and illegal while using our services, though You are a member with us by paying, we have a right to terminate the service before membership term ends. we reserve all rights to terminate your membership / account on knowledge of you being a minor. If any of the Services you want to use after login in, you must complete the registration process by providing us with current, complete and accurate information as prompted in the website, and further if we require any more information .by the applicable registration form. You also will choose a password, user name and a Mobile number to login. You are entirely responsible for maintaining the confidentiality of your password and account, we will not be liable for any loss that you may incur as a result of someone else using your password / account.'
    },
    {
        'title': 'Communications',
        'text': 'Once you register with us in our website, it is deemed that you have consented to receive calls, messages and emails through provided contact information at any time in future. The sharing of the information provided by you will be governed by the Privacy Policy and We will not give out such contact information of yours to third parties not connected with the Website.'
    },
    {
        'title': 'Charges',
        'text': 'The membership of this Website is free of cost. all services provide by us are according to the plan you select, once you select the plan or upgrade the plan accordingly you will be charged, at a give duration. Company has all right to change the plan any time, without prior notice.'
    },
    {
        'title': 'Refund',
        'text': 'Once the plan is selected and purchased, we are liable only to the available service in the website. Any customization will be done with a separate cost. Once the payment is done there is no refund. In case customer says he is not satisfied with the service, there for we request the customer to select initially monthly services and use our services and if he is satisfied, he can use yearly plans, once the payment done to us. We will not refund, once the payment done, he is entitled to use the given services only. If we know any type of fraud, then we block your account and we are not given refund.',
    },
    {   'title': 'User Errors',
        'text': 'If you erroneously send a payment to the wrong party or wrong biller or double pay or send a payment for the wrong amount (for instance a typographical error at your end) you  will find out whom you have made payment and will contact the Merchant /Party to whom you have sent the payment and ask them to refund the amount. We will not reimburse you or reverse a payment that you have erroneously made. You agree that all risks arising from online transactions will be borne by You. And we are not Responsible to any.We are not responsible for any OTP shared by you to third party while using our any services. We do not call for any OTP.'
    },
    {
        'title': 'Disclaimer of Warranties and Liabilities',
        'text': 'YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: THE WEBSITE, SERVICES AND ALL TARIFF PLANS AND UPGRADE OF PLANS ARE PROVIDED BY THIS WEBSITE IS ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) YOUR REQUIREMENTS WILL BE MET OR THAT SERVICES PROVIDED WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (II), (III) ANY ERRORS OR DEFECTS IN THE WEBSITE SERVICES OR OTHER MATERIALS INCLUDING TARIFF PLANS WILL BE CORRECTED. THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL OR INFORMATION OBTAINED THROUGH THE WEBSITE AND IS DONE ENTIRELY AT THEIR OWN DISCRETION AND RISK AND THEY WILL BE SOLELY FOR THE SAME. WE ARE NOT RESPONSIBLE FOR ANY TYPOGRAPHICAL ERRORS WHOLE DISPLAYING THE INFORMATION PROVIDED TO US. WITH RESPECT TO ANY INFORMATION PROVIDED TO YOU WHETHER ON BEHALF OF ITSELF OR THIRD PARTIES. WE SHALL NOT BE LIABLE FOR ANY THIRD-PARTY PRODUCT OR SERVICES. ANY ADVERTISEMENT AVAILABLE ON E-MAIL OR WEBSITE SMS WITH RESPECT TO THE THIRD-PARTY WEBSITE OR THE PRODUCTS AND SERVICES ARE FOR INFORMATION PURPOSE ONLY AND WE HOLD NO LIABILITY FOR SUCH INFORMATION OR YOUR ACTIONS UPON BECOMING AWARE OF SUCH INFORMATION.'
    },
];

export default TERMS_N_CONDITIONS;