import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CONSTANTS from '../../../../_constants/constants';

import { handleResponse } from '../../../../_helpers/handle-response';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecInputTo3Digits } from '../../../../_helpers/validators';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import classes from './LocalMarketCommissionModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const LocalMarketCommissionModal = props => {

    const [operatorId, setOperatorId] = useState();
    const [commissionRate, setCommissionRate] = useState('');
    const [operatorData, setOperatorData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        props.modalName !== 'Delete' && getOperatorList();
        return () => {
            setOperatorId('');
            setHasError(false);
        }
    }, []);

    const customStyles = {
        content: {
          top: '41%',
          left: '50%',
          right: 'auto',
          width: '33%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getOperatorList = () => {
        DropdownDataService.getOperatorList().then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = event => {
        setCommissionRate( props.modalData.commissionRate ?  props.modalData.commissionRate : '');
        setOperatorId(!!props.modalData.operatorId && props.modalData.operatorId !== '-1' ? props.modalData.operatorId : '-2');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setHasError(false);
        props.onCloseModal('cancel');
    }
    
    const operatorChangeHandler = event => {
        setErrorMessage(null);
        // if(event.target.value !== '-2') {
        //     setHasError(false);
        // }
        setOperatorId(event.target.value);
    }
    
    const commRateChangeHandler = event => {
        setErrorMessage(null);
        // if(event.target.value !== '') {
        //     setHasError(false);
        // }
        const value = event.target.value;
        if(validateDecInputTo3Digits(value)) {
            setCommissionRate(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const saveChanges = (reqBody, url, reqMethod) => {
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        };
        fetch(`${CONSTANTS.API_URL}LapuOutput/${url}`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(operatorId === '-2' || commissionRate === '' ) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                reqBody = { operatorId, commissionRate: parseFloat(commissionRate) };
                url = 'AddLapuOutput';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                reqBody = { operatorId, commissionRate: parseFloat(commissionRate) };
                url = `UpdateLapuOutput?lapuOutputId=${props.modalData.lapuOutputId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteLapuOutput?lapuOutputId=${props.modalData.lapuOutputId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} Local Commission</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select an Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && commissionRate === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={commissionRate} onChange={commRateChangeHandler} onBlur={ e => setCommissionRate(onBlurHandler(e, 3))} />
                                    <div className='invalid-feedback'>Please enter Commission Rate.</div>
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete Local Market Commission?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default  React.memo(LocalMarketCommissionModal);