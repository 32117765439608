import classes from './Alert.module.css';

const Alert = props => {

    const { alertClassName, message } = props;

    return (
        <div className={`alert alert-dismissible fade show alert-${alertClassName} ${classes.alertClass}`} role='alert'>
            {message}
            {/* <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
            </button> */}
        </div>
    );
}

export default Alert;