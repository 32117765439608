import Button from '../Button/Button';

const ModalHeader = props => {
    return (
        <div className='modal-header'>
            <h4>{props.modalName}</h4>
            <Button btnClass='close' onClick={e => props.onModalClose(e)} btnType='Close' />
        </div>
    )
}

export default ModalHeader;