import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader, parseToUIDate } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const OperatorWiseSupplierWiseCurrencyPage = () => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Operator', 'Stock Date', 'Opening Stock', 'Purchase', 'Received', 'Total Stock', 'Sales', 'Incentive', 'Difference(+/-)', 'Closing Stock'];
    const dataFields = ['operatorName', 'stockDate', 'openingStock', 'purchase', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStock'];
    const csvHeaders = [
        { label: 'Operator', key: 'operatorName' },
        { label: 'StockDate', key: 'stockDate' },
        { label: 'Opening Stock', key: 'openingStock' },
        { label: 'Purchase', key: 'purchase' },
        { label: 'Received', key: 'received' },
        { label: 'Total Stock', key: 'totalStock' },
        { label: 'Sales', key: 'sales' },
        { label: 'Incentive', key: 'incentive' },
        { label: 'Difference(+/-)', key: 'difference' },
        { label: 'Closing Stock', key: 'closingStock' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Operator': 'left',
            'Stock Date': 'center',
            'Opening Stock': 'center',
            'Purchase': 'center',
            'Received': 'center',
            'Total Stock': 'center',
            'Sales': 'center', 
            'Incentive': 'center',
            'Difference(+/-)': 'center',
            'Closing Stock': 'center'
        },
        widthConfig: {
            'Operator': 'auto',
            'Stock Date': '122px',
            'Opening Stock': '122px',
            'Purchase': '122px',
            'Received': '122px',
            'Total Stock': '122px',
            'Sales': '122px', 
            'Incentive': '122px',
            'Difference(+/-)': '122px',
            'Closing Stock': '122px'
        },
        alignConfig: {
            'operatorName': 'left',
            'stockDate': 'center',
            'openingStock': 'right',
            'purchase': 'right',
            'received': 'right',
            'totalStock': 'right',
            'sales': 'right',
            'incentive': 'right',
            'difference': 'right',
            'closingStock': 'right'
        }
    };

    useEffect(() => {
        getPurchaseDetailedData();
    }, []);
    
    const getPurchaseDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        const operatorId = window.operatorId;
        url = operatorId === '-1' ?
                `${CONSTANTS.API_URL}Reports/OperatorwiseSupplierwiseCurrencyReport?startDate=${window.startDate}&endDate=${window.endDate}` :
                `${CONSTANTS.API_URL}Reports/OperatorwiseSupplierwiseCurrencyReport?startDate=${window.startDate}&endDate=${window.endDate}&operatorId=${operatorId}`;
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    const keysToParse = ['openingStock', 'purchase', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStock'];
                    parseToUIDate(result.data.results, ['stockDate']);
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['openingStock',  'purchase', 'received', 'totalStock', 'sales', 'incentive', 'difference', 'closingStcok'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.openingStock,
                        total.purchase,
                        total.received,
                        total.totalStock,
                        total.sales,
                        total.incentive,
                        total.difference,
                        total.closingStcok
                    ]);
                    setExportTotalData({
                        operatorName: 'Total',
                        stockDate: '',
                        openingStock: total.openingStock,
                        purchase: total.purchase,
                        received: total.received,
                        totalStock: total.totalStock,
                        sales: total.sales,
                        incentive: total.incentive,
                        difference: total.difference,
                        closingStock: total.closingStcok
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'OperatorWiseCurrency';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Operator Wise Currency'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default OperatorWiseSupplierWiseCurrencyPage;