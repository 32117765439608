import { FaCopyright, FaUserClock } from 'react-icons/fa';

import classes from './Footer.module.css';

const Footer = props => {
    const { copyRight, officeTimings } = props;

    return (
        <footer className={classes.footer}>
            <div className='container-fluid'>
                <div className={classes.footerContent}>
                {/* <div> */}
                    <p className={classes.timeSection}><FaUserClock className={classes.footerIcon} /> {officeTimings}</p>
                    {/* <p className={classes.timeSection}><FaUserClock className={classes.footerIcon} /> Mon - Fri 10:00 - 18:00</p> */}
                    {/* <div className={classes.divider}></div> */}
                    {/* <span className={classes.contactSection}> */}
                        {/* <p className={classes.contactDetail}>Contact Us: </p> */}
                        {/* <p className={`${classes.contactDetail} ml-0`}><FaMobileAlt className={classes.footerIcon} /> +91 9845399724 </p> */}
                        {/* <p className={`${classes.contactDetail} mr-0`}><FaEnvelope className={classes.footerIcon} /> support@paperfreesolutions.com</p> */}
                    {/* </span> */}
                    {/* <div className={classes.divider}></div> */}
                    {/* <p className={classes.copyrightSection}>2021 <FaCopyright className={classes.footerIcon} /> Paperfree Solutions. All rights reserved.</p> */}
                    {/* <p className={classes.copyrightSection}>2021 <FaCopyright className={classes.footerIcon} /> Paperfree Solutions. All rights reserved.</p> */}
                    <p className={classes.copyrightSection}><FaCopyright className={classes.footerIcon} /> {copyRight}</p>
                {/* </div> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer;