import classes from './TermsNConditionsPage.module.css';
import TERMS_N_CONDITIONS from '../../../../_data/termsNConditionsData';
import { useEffect, useState } from 'react';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const TermsNConditionPage = props => {

    const [termsNConditions, setTermsNConditions] = useState([]);
    useEffect(() => {
        setTermsNConditions(TERMS_N_CONDITIONS);
    }, []);

    return (
        <PageLayout pageTitle='Terms and Conditions'>
            { termsNConditions.map( (data, index) => {
                return (
                    <div key={index}>
                        <h4 style={{textDecoration:'underline'}}>{index+1}. {data.title}</h4>
                        <p className={classes.textJustify}>{data.text}</p>
                    </div>
                )
            })}
        </PageLayout>
    );
}

export default TermsNConditionPage;