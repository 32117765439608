import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import Modal from '../../../UI/Modal/Modal';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import FormSelect from '../../../UI/Form/FormSelect/FormSelect';
import classes from './PaymentLogModal.module.css';
import { parseStatusToBoolean } from '../../../../_helpers/utils';

const OperatorMappingModal = props => {

    const { planId, paymentDate, companyCode, companyName, planName, validity, amount, utrNo } = props.modalData;
    const [ statusData, setStatusData] = useState([]);
    const [ amountReceived, setAmountReceived] = useState();
    const [ errorMessage, setErrorMessage] = useState();
    const modalName = `${props.modalName} Payment`;
    
    useEffect(() => {
        return () => setErrorMessage(null)
    }, []);

    useEffect(() => {
        getStatusData();
        setErrorMessage(null);
    }, [amountReceived]);

    const getStatusData = () => setStatusData(DropdownDataService.getStatusList())

    const afterOpenModal = event => {
        setAmountReceived(props.modalData.amountReceived ? 'Yes' : 'No');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setErrorMessage(null);
        props.onCloseModal('cancel');
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const editData = (url, reqBody) => ApiService.updateData(url, reqBody).then(handleApiResponse)

    const deleteData = (url, reqBody) => ApiService.deleteData(url, reqBody).then(handleApiResponse)

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}PaymentLog/`;
        let reqBody = {};
        let url = '';
        if(props.modalName === 'Edit') {
            reqBody = { planId, validity, amount, utrNo, amountReceived: parseStatusToBoolean(amountReceived) };
            url = `UpdatePaymentLog?paymentId=${props.modalData.paymentId}`;
            editData(apiUrl+url, reqBody);
        } else if(props.modalName === 'Delete') {
            url = `DeletePaymentLog?paymentId=${props.modalData.paymentId}`;
            deleteData(apiUrl+url, reqBody);
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            style={{top: '47%', width: '32%'}}
        >
            { (props.modalName === 'Edit') &&
                <form>
                    <FormGroup 
                        label='Date'
                        value={paymentDate}
                        disabled
                    />
                    <FormGroup 
                        label='Company Code'
                        value={companyCode}
                        disabled
                    />
                    <FormGroup 
                        label='Company Name'
                        value={companyName}
                        disabled
                    />
                    <FormGroup 
                        label='Plan Name'
                        value={planName}
                        disabled
                    />
                    <FormGroup 
                        label='Validity (days)'
                        value={validity}
                        disabled
                    />
                    <FormGroup 
                        label='UTR No.'
                        value={utrNo}
                        disabled
                    />
                    <FormGroup 
                        label='Amount'
                        value={amount}
                        disabled
                    />
                    <FormSelect 
                        label='Amount Received'
                        value={amountReceived}
                        defaultValue='No'
                        onChange={e => setAmountReceived(e.target.value)} 
                        selectData={statusData}
                        dataKeyPrefix='status'
                        selectDataKey='statusName'
                        selectDataValue='statusName'
                    />
                </form>
            }
            { props.modalName === 'Delete' && 
                <div className={classes.text}>Do you want to Delete Payment Entry?</div>
            }
        </Modal>
    );

}

export default React.memo(OperatorMappingModal);