import { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';

import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { ApiService } from '../../../../_services/api.service';
import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import ImportLapuSalesModal from './ImportLapuSalesModal';
import classes from './ImportLapuSalesPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const ImportLapuSalesPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [dataConfig, setDataConfig] = useState({});
    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [providerId, setProviderId] = useState('');
    const [transactionDate, setTransactionDate] = useState();
    const [files, setFiles] = useState(null);
    const [providerData, setProviderData] = useState([]);
    const [imported, setImported] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalName, setModalName] = useState('');
    const [changeIndex, setChangeIndex] = useState();
    const [buttonStatus, setButtonStatus] = useState('Sales');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Date', 'Supplier', 'Operator', 'Provider', 'Lapu Number', 'Opening Balance', 'Received', 'Sales', 'Incentive', 'Difference(+/-)', 'Failed', 'Pending', 'Closing Balance', 'Success Count', 'Failed Count', 'Pending Count'];
    const dataFields = ['transactionDate', 'supplierName', 'operatorName', 'providerName', 'lapuNumber', 'openingBalance', 'received', 'sales', 'incentive', 'difference', 'failed', 'pending', 'closingBalance', 'successCount', 'failedCount', 'pendingCount'];
    const csvHeaders = [
        { label: 'Date', key: 'transactionDate' },
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Provider', key: 'providerName' },
        { label: 'Lapu Number', key: 'lapuNumber' },
        { label: 'Opening Balance', key: 'openingBalance' },
        { label: 'Received', key: 'received' },
        { label: 'Sales', key: 'sales' },
        { label: 'Incentive', key: 'incentive' },
        { label: 'Difference(+/-)', key: 'difference' },
        { label: 'Failed', key: 'failed' },
        { label: 'Pending', key: 'pending' },
        { label: 'Closing Balance', key: 'closingBalance' },
        { label: 'Success Count', key: 'successCount' },
        { label: 'Failed Count', key: 'failedCount' },
        { label: 'Pending Count', key: 'pendingCount' }
    ];
    const config = {
        headerAlignConfig: {
            'Date': 'left',
            'Supplier': 'left',
            'Operator': 'center',
            'Provider': 'center',
            'Lapu Number': 'left',
            'Opening Balance': 'center',
            'Received': 'center',
            'Sales': 'center',
            'Incentive': 'center',
            'Difference(+/-)': 'center',
            'Failed': 'center',
            'Pending': 'center',
            'Closing Balance': 'center',
            'Success Count': 'center',
            'Failed Count': 'center',
            'Pending Count': 'center'
        },
        widthConfig: {
            'Date': '96px',
            'Supplier': '250px',
            'Operator': '122px',
            'Provider': '122px',
            'Lapu Number': '122px',
            'Opening Balance': '122px',
            'Received': '122px',
            'Sales': '122px',
            'Incentive': '122px',
            'Difference(+/-)': '122px',
            'Failed': '122px',
            'Pending': '122px',
            'Closing Balance': '122px',
            'Success Count': '122px',
            'Failed Count': '122px',
            'Pending Count': '122px'
        },
        alignConfig: {
            'transactionDate': 'left',
            'supplierName': 'left',
            'operatorName': 'center',
            'providerName': 'center',
            'lapuNumber': 'left', 
            'openingBalance': 'right',
            'received': 'right',
            'sales': 'right',
            'incentive': 'right',
            'difference': 'right',
            'failed': 'right',
            'pending': 'right',
            'closingBalance': 'right',
            'successCount': 'right',
            'failedCount': 'right',
            'pendingCount': 'right'
        }
    }

    useEffect(() => {
        setDataConfig(config);
        getProviderList();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [date, providerId, files]);

    const getProviderList = () => {
        DropdownDataService.getProviderList().then( result => {
            if(result.data) {
                setProviderId(result.data.results[0].providerId);
                setProviderData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const uploadHandler = () => {
        setModalIsOpen(false);
        setModalName('');
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        fetch(`${CONSTANTS.API_URL}DraftSales/AddLapuTransactions?providerId=${providerId}&transactionDate=${transactionDate}`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                setIsLoading(false);
                if(result.data) {
                    setSuccessMessage('Data uploaded successfully!');
                } else {
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const uploadClickHandler = e => {
        e.preventDefault(); // Stop form submit
        setErrorMessage(null);
        setSuccessMessage(null);
        setIsLoading(true);
        const differenceError = gridData.filter( data => data.difference !== (0).toFixed(2) );
        if(differenceError.length !== 0) {
            setIsLoading(false);
            setModalName('Upload');
            setModalIsOpen(true);
        } else {
            uploadHandler();
        }
    }

    const importClickHandler = e => {
        e.preventDefault(); // Stop form submit
        clearState();
        setIsLoading(true);
        if (files != null ) {
            let validFiles = 0;
            for (const key in files) {
                if (checkFileType(files[key]) && checkFileSize(files[key])) {
                    validFiles = validFiles + 1;
                }
            }
            if(validFiles === files.length) { 
                fileUpload();
            }
        } else {
            setIsLoading(false);
            setErrorMessage('Choose file(s) to upload.');
        }
    }

    const getButtonStatus = date => {
        const url = `${CONSTANTS.API_URL}DraftSales/ButtonStatus?transactionDate=${date}`;
        ApiService.getData(url).then( result => {
            if(result.data) {
                setButtonStatus(result.data.Status);
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        },
        error => setErrorMessage(error)
        )
    }

    const updateToLapuOpeningClickHandler = () => {
        const url = `${CONSTANTS.API_URL}DraftSales/AddLapuOpeningBalance?providerId=${providerId}&transactionDate=${date}`;
        ApiService.postData(url, {}).then( 
            result => {
                if(result.data) {
                    setSuccessMessage('Lapu Opening Balance updated successfully!');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const clearState = () => {
        setImported(false);
        setErrorMessage(null);
        setSuccessMessage(null);
    }
    
    const dateChangeHandler = e => {
        clearState();
        const date = e.target.value;
        setDate(date);
        getButtonStatus(date);
    }
    
    const providerChangeHandler = e => {
        clearState();
        setProviderId(e.target.value);
    }
    
    const fileChangeHandler = e => {
        clearState();
        let filesToUpload = [];
        for(const key in e.target.files) {
            if(e.target.files[key] instanceof File) {
                filesToUpload.push(e.target.files[key]);
            }
        }
        setFiles(filesToUpload);
    }

    const checkFileType = uploadedFile => {
        let err = '';
        const types = [
            'text/csv',
            // 'text/plain',
            'application/csv',
            'text/comma-separated-values',
            'application/excel',
            'application/vnd.ms-excel',
            'application/vnd.msexcel',
            // 'text/anytext',
            'application/octet-stream',
            // 'application/txt'
        ];
        if ( !types.includes(uploadedFile.type) ) {
            err += `${uploadedFile.name} file format not supported. Upload CSV file.\n`;
        }
        if (err !== '') {
            setIsLoading(false);
            setErrorMessage(err);
            return false; 
        }
        return true;
    }
    
    const checkFileSize = uploadedFile => {
        let size = 100000000;
        let err = ''; 
        if (uploadedFile.size > size) {
            err += `${uploadedFile.name} file size is more than 100MB.\n`;
        }
        if (err !== '') {
            setIsLoading(false);
            setErrorMessage(err);
            return false;
        }
        return true;
    }
    
    const fileUpload = () => {
        const formData = new FormData();
        formData.append('inputDate', date);
        formData.append('providerId', providerId);
        for (const key in files) {
            formData.append('dataFile[]', files[key]);
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Token': localStorage.getItem('accessToken')
            },
            body: formData
        };
        fetch(`${CONSTANTS.API_URL}DataUpload/FileUpload`, requestOptions)
        .then(handleResponse)
        .then(
            result => {
                if(result.data && result.data.results.length > 0) {
                    setIsLoading(false);
                    setImported(true);
                    let keysToMap = ['openingBalance', 'received', 'sales', 'incentive', 'difference', 'failed', 'pending', 'closingBalance'];
                    result.data.results.map( data => {
                        return Object.entries(data).forEach( ([key, value]) => {
                            data[key] = keysToMap.includes(key) ? value.toFixed(2) : value
                        });
                    });
                    result.data.results.map( data => 
                        data.transactionDate = data.transactionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    setTransactionDate(result.data.results && result.data.results[0].transactionDate.split('/').reverse().join('-'));
                    setGridData(result.data.results);
                    const totals = result.data.total[0];
                    setTotalData([
                        '',
                        '',
                        '',
                        '',
                        (totals.openingBalance).toFixed(2),
                        (totals.received).toFixed(2),
                        (totals.sales).toFixed(2),
                        (totals.incentive).toFixed(2),
                        (totals.difference).toFixed(2),
                        (totals.failed).toFixed(2),
                        (totals.pending).toFixed(2),
                        (totals.closingBalance).toFixed(2),
                        totals.successCount,
                        totals.failedCount,
                        totals.pendingCount,
                        ''
                    ]);
                    setExportTotalData({
                        transactionDate: 'Total',
                        supplierName: '',
                        operatorName: '',
                        providerName: '',
                        lapuNumber: '',
                        openingBalance: (totals.openingBalance).toFixed(2),
                        received: (totals.received).toFixed(2),
                        sales: (totals.sales).toFixed(2),
                        incentive: (totals.incentive).toFixed(2),
                        difference: (totals.difference).toFixed(2),
                        failed: (totals.failed).toFixed(2),
                        pending: (totals.pending).toFixed(2),
                        closingBalance: (totals.closingBalance).toFixed(2),
                        successCount: totals.successCount,
                        failedCount: totals.failedCount,
                        pendingCount: totals.pendingCount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else if(result.error) {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                } else {
                    setIsLoading(false);
                    setErrorMessage('Results not found!');
                }
            },
            (error) => {
                setImported(false);
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const handleAfterOpen = (event, data) => console.log(event, data)

    const modalOpenHandler = index => {
        setErrorMessage(null);
        setChangeIndex(index);
        setModalIsOpen(true);
        setModalData(gridData[index]);
    }

    const modalCloseHandler = (status, data) => {
        setModalIsOpen(false);
        setModalName('');
        if(status === 'save' && Object.keys(data).length > 0) {
            editRow(data);
        }
    }

    const editRow = data => { 
        let changedData = gridData;
        changedData[changeIndex] = data;
        setGridData(changedData);
    }

    const getExportFileName = () => {
        let exportFilename = 'ExportLapuSales';
        exportFilename += '_' + date;
        exportFilename += providerId !== '-1' ? '_' + providerData.find(data => data.providerId == providerId)['providerName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Import Lapu Sales'>
                    <div className='row'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '12px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={date} onChange={dateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Provider : </label>
                                    <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={providerChangeHandler} value={providerId}>
                                        { providerData.map( (value, index) => {
                                            return <option key={`provider-${index}`} className='dropdownItem' value={value.providerId}>{value.providerName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>File : </label>
                                    <input type='file' name='' accept='.csv' required className='form-control' onChange={fileChangeHandler} multiple/>
                                </div>
                                <Button btnClass='btn btn-primary mr-3' onClick={importClickHandler} btnText='Import Data' btnTitle='Import Data' />
                                { buttonStatus === 'Sales' && 
                                    <Button btnClass='btn btn-primary mr-3' disabled={!imported} onClick={uploadClickHandler} btnText='Upload Data' btnTitle='Upload Data' />  
                                }
                                { buttonStatus === 'Opening' && 
                                    <Button btnClass='btn btn-primary mr-3' onClick={updateToLapuOpeningClickHandler} btnText='Update to Lapu Opening Balance' btnTitle='Update to Lapu Opening Balance' />  
                                }
                                { modalIsOpen && <ImportLapuSalesModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                    uploadHandler={uploadHandler}
                                /> }
                                </form>
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { successMessage && <Alert alertClassName='success' message={successMessage} /> }
                    { imported && gridData.length > 0 && (
                        <div className={classes.tableContainer} style={{height: gridData.length > 0 ? '60vh': 'auto'}}>
                            <table className={`table table-bordered ${classes.importLapuTable}`}>
                                <thead className={classes.header} style={{height: '40px'}}>
                                    <tr role='row'>
                                        <th scope='col' className={classes.thead} style={{textAlign: 'center', width: '52px'}}>#</th>
                                        { tableHeaders.map( (header, i) => {
                                            return (
                                                <th 
                                                    className={classes.thead}
                                                    style={{ 
                                                        width: (!!dataConfig['widthConfig']) && dataConfig['widthConfig'][header],
                                                        minWidth: (!!dataConfig['widthConfig']) && dataConfig['widthConfig'][header],
                                                        textAlign: (!!dataConfig['headerAlignConfig']) && dataConfig['headerAlignConfig'][header]
                                                    }}
                                                    key={`header-${i}`}
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                        <th scope='col' className={classes.thead} style={{width: '70px', minWidth: '70px', maxWidth: '70px', textAlign: 'center'}}>Actions</th>
                                    </tr>
                                    { gridData.length > 0 && totalData &&
                                        (<tr key='totals' className={classes.totalRow}>
                                            <td key='total#' className={classes.totalRowData}></td>
                                            <td key='total' className={classes.totalRowData} style={{textAlign: 'left'}}>Total</td>
                                            { totalData.map((total, index) => {
                                                return (
                                                    <td key={`${'total-' + index}`} className={classes.totalRowData} style={{textAlign: 'right'}}>{total}</td>
                                                )
                                            })}
                                        </tr>)
                                    }
                                </thead>
                                <tbody>
                                    { gridData.map((tableData, index) => {
                                        return (
                                            <tr 
                                                key={index} 
                                                className={`${tableData.supplierId === 0 ? classes.isInvalid: ''} ${classes.tableRow}`} 
                                                onDoubleClick={e => modalOpenHandler(index)}
                                            >
                                                <td key={index} style={{textAlign: 'right'}}>{index+1}</td>
                                                <td 
                                                    key='transactionDate'
                                                    style={{textAlign: (!!dataConfig.alignConfig['transactionDate']) && dataConfig.alignConfig['transactionDate']}}
                                                >
                                                    {tableData['transactionDate']}
                                                </td>
                                                <td 
                                                    key='supplierName'
                                                    style={{textAlign: (!!dataConfig.alignConfig['supplierName']) && dataConfig.alignConfig['supplierName']}}
                                                >
                                                    {tableData['supplierName']}
                                                </td>
                                                <td 
                                                    key='operatorName'
                                                    style={{textAlign: (!!dataConfig.alignConfig['operatorName']) && dataConfig.alignConfig['operatorName']}}
                                                >
                                                    {tableData['operatorName']}
                                                </td>
                                                <td 
                                                    key='providerName'
                                                    style={{textAlign: (!!dataConfig.alignConfig['providerName']) && dataConfig.alignConfig['providerName']}}
                                                >
                                                    {tableData['providerName']}
                                                </td>
                                                <td 
                                                    key='lapuNumber'
                                                    style={{textAlign: (!!dataConfig.alignConfig['lapuNumber']) && dataConfig.alignConfig['lapuNumber']}}
                                                >
                                                    {tableData['lapuNumber']}
                                                </td>
                                                <td 
                                                    key='openingBalance'
                                                    style={{textAlign: (!!dataConfig.alignConfig['openingBalance']) && dataConfig.alignConfig['openingBalance']}}
                                                >
                                                    {tableData['openingBalance']}
                                                </td>
                                                <td 
                                                    key='received'
                                                    className={`${(parseFloat(tableData.received) < 500 && parseFloat(tableData.received) !== 0)  ? classes.isInvalid: ''}`}
                                                    style={{textAlign: (!!dataConfig.alignConfig['received']) && dataConfig.alignConfig['received']}}
                                                >
                                                    {tableData['received']}
                                                </td>
                                                <td 
                                                    key='sales'
                                                    style={{textAlign: (!!dataConfig.alignConfig['sales']) && dataConfig.alignConfig['sales']}}
                                                >
                                                    {tableData['sales']}
                                                </td>
                                                <td 
                                                    key='incentive'
                                                    className={`${parseFloat(tableData.incentive) >= 500 ? classes.isInvalid: ''}`}
                                                    style={{textAlign: (!!dataConfig.alignConfig['incentive']) && dataConfig.alignConfig['incentive']}}
                                                >
                                                    {tableData['incentive']}
                                                </td>
                                                <td 
                                                    key='difference'
                                                    className={`${parseFloat(tableData.difference) !== 0 ? classes.isInvalid: ''}`}
                                                    style={{textAlign: (!!dataConfig.alignConfig['difference']) && dataConfig.alignConfig['difference']}}
                                                >
                                                    {tableData['difference']}
                                                </td>
                                                <td 
                                                    key='failed'
                                                    style={{textAlign: (!!dataConfig.alignConfig['failed']) && dataConfig.alignConfig['failed']}}
                                                >
                                                    {tableData['failed']}
                                                </td>
                                                <td 
                                                    key='pending'
                                                    style={{textAlign: (!!dataConfig.alignConfig['pending']) && dataConfig.alignConfig['pending']}}
                                                >
                                                    {tableData['pending']}
                                                </td>
                                                <td 
                                                    key='closingBalance'
                                                    style={{textAlign: (!!dataConfig.alignConfig['closingBalance']) && dataConfig.alignConfig['closingBalance']}}
                                                >
                                                    {tableData['closingBalance']}
                                                </td>
                                                <td 
                                                    key='successCount'
                                                    style={{textAlign: (!!dataConfig.alignConfig['successCount']) && dataConfig.alignConfig['successCount']}}
                                                >
                                                    {tableData['successCount']}
                                                </td>
                                                <td 
                                                    key='failedCount'
                                                    style={{textAlign: (!!dataConfig.alignConfig['failedCount']) && dataConfig.alignConfig['failedCount']}}
                                                >
                                                    {tableData['failedCount']}
                                                </td>
                                                <td 
                                                    key='pendingCount'
                                                    style={{textAlign: (!!dataConfig.alignConfig['pendingCount']) && dataConfig.alignConfig['pendingCount']}}
                                                >
                                                    {tableData['pendingCount']}
                                                </td>
                                                <td>
                                                    <span className={classes.actionBtn}>
                                                        <FaPencilAlt title='Edit' style={{color: '#ffc107'}} onClick={e => modalOpenHandler(index)} />
                                                    </span>
                                                </td>
                                            </tr>
                                                /* className={`${(tableData.supplierId === 0 || parseFloat(tableData.received) < 500 || parseFloat(tableData.incentive) >= 500 || parseFloat(tableData.difference) !== 0) ? classes.isInvalid: ''} ${classes.tableRow}`}  */
                                                /* { dataFields.map( (field, index) => 
                                                    <td 
                                                        key={`${'field-' + index}`} 
                                                        className={`${(tableData.supplierId === 0 || parseFloat(tableData.received) < 500 || parseFloat(tableData.incentive) >= 500 || parseFloat(tableData.difference) !== 0) ? classes.isInvalid: ''} ${classes.tableRow}`} 
                                                        style={{
                                                            textAlign: (!!dataConfig.alignConfig[field]) && dataConfig.alignConfig[field]
                                                        }}
                                                    >
                                                        {tableData[field]}
                                                    </td> )
                                                } */
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    { !imported && (<div className={classes.noData}>Import Data from CSV to view details</div>) }
                </PageLayout>
            )}
        </>
    );
}

export default ImportLapuSalesPage;