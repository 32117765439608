import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecInputTo3Digits } from '../../../../_helpers/validators';

import classes from './APISalesModal.module.css';

const APISalesModal = props => {

    const [transactionDate, setTransactionDate] = useState(new Date().toISOString().slice(0,10));
    const [customerId, setCustomerId] = useState('-2');
    const [netAmount, setNetAmount] = useState();
    const [percentageTypeId, setPercentageTypeId] = useState();
    const [percentageTypeName, setPercentageTypeName] = useState();
    const [commissionRate, setCommissionRate] = useState();
    const [commission, setCommission] = useState();
    const [netCurrency, setNetCurrency] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [percentageTypeData, setPercentageTypeData] = useState([]);

    useEffect(() => {
        if(props.modalName != 'Delete') {
            updateModal();
            getCustomerList();
            getCommissionType();
        }
        return () => {
            setTransactionDate('');
            setCustomerId('');
            setNetAmount('');
            setPercentageTypeId('');
            setCommissionRate('');
            setCommission('');
            setNetCurrency('');
        }
    }, []);

    const customStyles = {
        content: {
            top: '56%',
            left: '50%',
            right: 'auto',
            width: '38%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    useEffect(() => {
        if(!!percentageTypeName) {
            var commission = 0;
            let amount = netAmount === '' ? 0 : parseFloat(netAmount);
            let commRate = commissionRate === '' ? 0 : parseFloat(commissionRate);
            if(percentageTypeName === 'ADD ON') {
                commission = amount * commRate / (100 - commRate);
            } else if(percentageTypeName === 'DIRECT') {
                commission = amount * (commRate / 100);
            }
            setCommission(commission.toFixed(2));
        }
    }, [netAmount, percentageTypeName, commissionRate]);

    useEffect(() => {
        let amount = netAmount === '' ? 0 : parseFloat(netAmount);
        const currency = (amount + parseFloat(commission)).toFixed(2);
        setNetCurrency(currency);
    }, [netAmount, commission]);

    const updateModal = () => {
        setTransactionDate(!!props.modalData.transactionDate ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        setCustomerId(!!props.modalData.customerId ? props.modalData.customerId : '-2');
        setNetAmount(!!props.modalData.netAmount ? props.modalData.netAmount : (0).toFixed(2));
        setPercentageTypeId(!!props.modalData.percentageTypeId ? props.modalData.percentageTypeId : '-2');
        setPercentageTypeName(!!props.modalData.percentageTypeName ? props.modalData.percentageTypeName : '');
        setCommissionRate(!!props.modalData.commissionRate && !!props.modalData.percentageTypeName && props.modalData.percentageTypeName !== 'ADD ON' ? props.modalData.commissionRate : (0).toFixed(3));
        setCommission(!!props.modalData.commission ? props.modalData.commission : (0).toFixed(2));
        setNetCurrency(!!props.modalData.netCurrency ? props.modalData.netCurrency : (0).toFixed(2));
    }

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('modal').then( result => {
            if(result.data) {
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getCommissionType = () => {
        DropdownDataService.getCommissionType('modal').then( result => {
            if(result.data) {
                setPercentageTypeData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = () => {
        // setTransactionDate(Object.keys(props.modalData).length > 0 ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        // setSupplierId(Object.keys(props.modalData).length > 0 ? props.modalData.supplierId : supplierData[0].supplierId);
        // setOperatorId(Object.keys(props.modalData).length > 0 ? props.modalData.operatorId : operatorData[0].operatorId);
        // setNetAmount(Object.keys(props.modalData).length > 0 ? props.modalData.netAmount : '');
        // setPercentageTypeId(Object.keys(props.modalData).length > 0 ? props.modalData.percentageTypeId : percentageTypeData[0].percentageTypeId);
        // setCommissionRate(Object.keys(props.modalData).length > 0 ? props.modalData.commissionRate : '');
        // setCommission(Object.keys(props.modalData).length > 0 ? props.modalData.commission : '');
        // setAdjustment(Object.keys(props.modalData).length > 0 ? props.modalData.adjustment : '');
        // setNetCurrency(Object.keys(props.modalData).length > 0 ? props.modalData.netCurrency : '');
    }
    
    const onModalClose = event => props.onCloseModal('cancel')

    const clearMessages = () => setErrorMessage(null)

    const validateInput = value => {
        return value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    }
    
    const transactionDateChangeHandler = event => {
        clearMessages();
        setTransactionDate(event.target.value);
    }

    const customerChangeHandler = event => {
        clearMessages();
        setCustomerId(event.target.value);
    }
    
    const netAmountChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value === '') {
            setNetAmount(value);
        } else {
            if(validateInput(value)) {
                setNetAmount(value);
            }
        }
    }

    const commTypeChangeHandler = event => {
        const value = event.target.value;
        clearMessages();
        setPercentageTypeId(value);
        const commTypeName = percentageTypeData.find(data => data.percentageTypeId == value)['percentageTypeName'];
        setPercentageTypeName(commTypeName);
        if(commTypeName === 'ADD ON') {
            setCommissionRate((0).toFixed(3));
        }
    }

    const commissionRateChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value === '') {
            setCommissionRate(value);
        } else {
            if(validateDecInputTo3Digits(value)) {
                setCommissionRate(value);
            }
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = 'https://royalinfo.in/api/pavathi/ApiSales/' + url;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (customerId === '-2' || percentageTypeId === '-2' || commissionRate === ''|| netAmount === '0.00' )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {
                transactionDate,
                customerId,
                netAmount: parseFloat(netAmount),
                percentageTypeId,
                commissionRate: parseFloat(commissionRate),
                commission: parseFloat(commission),
                netCurrency: parseFloat(netCurrency)
            };
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                url = 'AddApiSales';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                url = `UpdateApiSales?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteApiSales?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
                reqBody = {};
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} API Sales</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Purchase Date : </label>
                                <div className='col-sm-8'>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={transactionDate} onChange={transactionDateChangeHandler} />
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Customer : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && customerId === '-2' ? 'is-invalid': ''}`} value={customerId} aria-label=".form-select-sm example" onChange={customerChangeHandler}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} value={value.customerId}>{value.customerName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Customer.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && netAmount === '0.00' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={netAmount} onChange={netAmountChangeHandler} onBlur={e=>setNetAmount(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter Amount greater than 0.</div>
                                </div>
                            </div>
                            {/* <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                <input type='text' readOnly className='form-control inputField' value={percentageTypeName} />
                                </div>
                            </div> */}
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && percentageTypeId === '-2' ? 'is-invalid': ''}`} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                        { percentageTypeData.map( (value, index) => {
                                            return <option key={`commType-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Commission Type.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled={percentageTypeName === 'ADD ON'} className={`form-control inputField ${hasError && commissionRate === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={commissionRate} onChange={commissionRateChangeHandler} onBlur={e=>setCommissionRate(onBlurHandler(e, 3))} />
                                    <div className='invalid-feedback'>Please enter Commission %.</div>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commission} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Net Received : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={netCurrency} />
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete API Sales?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' disabled={errorMessage} onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(APISalesModal);