import classes from './AuthImage.module.css';

const AuthImage = props => {
    const { desktopImage, appName } = props;
    const imgStyles = { height: '100vh', width: '100%', filter: 'brightness(0.6)' };

    return (
        <>
            <div className={`${classes.authFluidRight}`}>
                <img src={desktopImage} alt={appName} style={imgStyles} />
            </div>
            <div className={`${classes.authUserTestimonial}`}>
                <h2 className='mb-3'>Digitized Sales Tracker!</h2>
                <p className={classes.lead}>Tracking and Accounting in minutes.</p>
                {/* <p className={classes.author}>- Pavathi Admin</p> */}
            </div>
        </>
    );
}

export default AuthImage;