import React, { useState, useEffect } from 'react'; 

import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';
import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerWiseAPIStockDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Customer', 'Date', 'Opening Stock', 'Issued', 'Consumption', 'Adjustment', 'Closing Stock'];
    const dataFields = ['customerName', 'date', 'openingStock', 'issued', 'consumption', 'adjustment', 'closingStock'];
    const csvHeaders = [
        { label: 'Customer', key: 'customerName' },
        { label: 'Date', key: 'date' },
        { label: 'Opening Stock', key: 'openingStock' },
        { label: 'Issued', key: 'issued' },
        { label: 'Consumption', key: 'consumption' },
        { label: 'Adjustment', key: 'adjustment' },
        { label: 'Closing Stock', key: 'closingStock' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left', 
            'Date': 'center',
            'Opening Stock': 'center',
            'Issued': 'center',
            'Consumption': 'center',
            'Adjustment': 'center',
            'Closing Stock': 'center'
        },
        widthConfig: {
            'Customer': 'auto', 
            'Date': '154px',
            'Opening Stock': '154px',
            'Issued': '154px',
            'Consumption': '154px',
            'Adjustment': '154px',
            'Closing Stock': '154px'
        },
        alignConfig: {
            'customerName': 'left',
            'date': 'center',
            'openingStock': 'right',
            'issued': 'right',
            'consumption' : 'right',
            'adjustment' : 'right',
            'closingStock': 'right'
        }
    };

    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        const startDate = window.startDate;
        const endDate = window.endDate;
        const customerId = window.customerId;
        let url = customerId === '-1' ?
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiStockDetailed?startDate=${startDate}&endDate=${endDate}` :
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiStockDetailed?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.date = data.date.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['openingStock', 'issued', 'consumption', 'adjustment', 'closingStock'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['openingStock', 'issued', 'consumption', 'adjustment', 'closingStock'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.openingStock,
                        total.issued,
                        total.consumption,
                        total.adjustment,
                        total.closingStock
                    ]);
                    setExportTotalData({
                        customerName: 'Total',
                        date: '',
                        openingStock: total.openingStock,
                        issued: total.issued,
                        consumption: total.consumption,
                        adjustment: total.adjustment,
                        closingStock: total.closingStock
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'CustomerWiseAPIStockDetailed';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.customerName !== '' ? '_' + window.customerName : '';
        setExportFileName(exportFilename);
    }
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Customer Wise API Stock Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default CustomerWiseAPIStockDetailedPage;