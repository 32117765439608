import { authenticationService } from '../_services/authentication.service';

export const handleResponse = response => {
    const company = localStorage.getItem('company');

    return response.text()
        .then( text => {
                const resp = text && JSON.parse(text);
                if (resp && resp.error) {
                    if ([401, 403].indexOf(resp.statusCode) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        window.location.pathname = `/${company}/login`;
                    }
                    // throw(resp.error.message);
                }
                return resp;
            },
            error => error
        );

}