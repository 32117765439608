import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FaCog, FaColumns, FaExchangeAlt, FaFileExcel, FaQuestionCircle, FaRegChartBar, FaRegNewspaper, FaServer, FaTag, FaUserCog } from 'react-icons/fa';

import SettingsPage from '../../Pages/Settings/SettingsPage';
import { ApiService } from '../../../_services/api.service';
import CONSTANTS from '../../../_constants/constants';
import classes from './Navbar.module.css';

const Navbar = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [showLapuOptions, setShowLapuOptions] = useState(false);
    const [showApiOptions, setShowApiOptions] = useState(false);
    const [showTransactionOptions, setShowTransactionOptions] = useState(false);
    const [showLapuCurrencyReports, setShowLapuCurrencyReports] = useState(false);
    const [showApiCurrencyReports, setShowApiCurrencyReports] = useState(false);
    const [showReportOptions, setShowReportOptions] = useState(false);
    const [showUtilsOptions, setShowUtilsOptions] = useState(false);
    const [showSettingsOptions, setShowSettingsOptions] = useState(false);
    const [showPlansOptions, setShowPlansOptions] = useState(false);
    const [showHelpOptions, setShowHelpOptions] = useState(false);
    const [showAdminPanelOptions, setShowAdminPanelOptions] = useState(false);
    const [userType, setUserType] = useState('');

    useEffect(() => {
        getUserType();
    }, []);

    const getUserType = () => {
        const url = `${CONSTANTS.API_URL}User/User`;
        ApiService.getData(url).then( 
            result => {
                if(result.data) {
                    !!result.data.userType && setUserType(result.data.userType);
                } else if(result.error) {
                    setUserType('');
                }
            },
            error => setUserType('')
        );
    }

    const modalOpenHandler = modalName => {
        setShowSettingsOptions(false);
        setModalIsOpen(true);
        setModalName(modalName);
    }

    const modalCloseHandler = () => setModalIsOpen(false)

    const reportsDropdownCloseHandler = () => {
        setShowReportOptions(false);
        setShowLapuCurrencyReports(false);
        setShowApiCurrencyReports(false);
    }

    return (
        <div className={`shadow-sm ${classes.topnav}`}>
            <div className='container-fluid'>
                <nav className={`navbar navbar-light navbar-expand ${classes.navBar}`}>
                {/* <nav className={`navbar navbar-light navbar-expand-lg ${classes.navBar}`}> */}
                    {/* <div className='collapse navbar-collapse' id='navbarMenu'> */}
                    <div id='navbarMenu'>
                        { userType === 'Superuser' && 
                            <ul className='nav navbar-nav'>
                                <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowAdminPanelOptions(false)} onMouseEnter={e => setShowAdminPanelOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='adminPanelDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaUserCog className={classes.headerIcon} style={{ fontSize: '18px'}}/>
                                        Admin Panel
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showAdminPanelOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='adminPanelDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/operator-mapping' onClick={e => setShowAdminPanelOptions(false)}>Operator Mapping Master</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/company' onClick={e => setShowAdminPanelOptions(false)}>Company Master</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/plan' onClick={e => setShowAdminPanelOptions(false)}>Plan Master</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/payment' onClick={e => setShowAdminPanelOptions(false)}>Payment Log</Link>
                                        </div>
                                    }
                                </li>
                            </ul>
                        }
                        { userType !== 'Superuser' && 
                            <ul className='nav navbar-nav'>
                                { userType !== 'Superuser' && <li className={`nav-item active ${classes.navItem}`}>
                                    <Link className={`nav-link active ${classes.navLink}`} to='/dashboard'>
                                        <FaRegChartBar className={classes.headerIcon}/>
                                        Dashboard
                                    </Link>
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowLapuOptions(false)} onMouseEnter={e => setShowLapuOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} id='lapuDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaColumns className={classes.headerIcon}/>
                                        Lapu
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showLapuOptions && 
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='lapuDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier' onClick={e => setShowLapuOptions(false)}>Supplier</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-commission' onClick={e => setShowLapuOptions(false)}>Supplier Commission</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/local-market-commission' onClick={e => setShowLapuOptions(false)}>Local Market Commission</Link>
                                            {/* <Link className={`dropdown-item ${classes.dropdownItem}`} to='/region' onClick={e => setShowLapuOptions(false)}>Region</Link> */}
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/lapu-sim' onClick={e => setShowLapuOptions(false)}>Lapu/Demo Sim</Link>
                                            {/* <Link className={`dropdown-item ${classes.dropdownItem}`} to='/staff-master'>Staff</Link> */}
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-purchase-pending' onClick={e => setShowLapuOptions(false)}>Supplier Purchase Pending</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowApiOptions(false)} onMouseEnter={e => setShowApiOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='apiDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaServer className={classes.headerIcon}/>
                                        API
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showApiOptions && 
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='apiDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/customer' onClick={e => setShowApiOptions(false)}>Customer</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-purchase-commission' onClick={e => setShowApiOptions(false)}>API Purchase Commission</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-sales-commission' onClick={e => setShowApiOptions(false)}>API Sales Commission</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-api-purchase-pending' onClick={e => setShowApiOptions(false)}>Supplier Wise API Purchase Pending</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/customer-api-sales-pending' onClick={e => setShowApiOptions(false)}>Customer Wise API Sales Pending</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowTransactionOptions(false)} onMouseEnter={e => setShowTransactionOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='transactionsDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaExchangeAlt className={classes.headerIcon}/>
                                        Transactions
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showTransactionOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='transactionsDropdown'>
                                            {/* <Link className={`dropdown-item ${classes.dropdownItem}`} to='/currency-opening'>Currency Opening</Link> */}
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/lapu-purchase' onClick={e => setShowTransactionOptions(false)}>Lapu Purchase</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/import-lapu-sales' onClick={e => setShowTransactionOptions(false)}>Import Lapu Sales</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/lapu-sales' onClick={e => setShowTransactionOptions(false)}>Lapu Sales</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-purchase' onClick={e => setShowTransactionOptions(false)}>API Purchase</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-purchase-consumption' onClick={e => setShowTransactionOptions(false)}>API Purchase Consumption</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-purchase-adjustment' onClick={e => setShowTransactionOptions(false)}>API Purchase Adjustment</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-sales' onClick={e => setShowTransactionOptions(false)}>API Sales</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-sales-consumption' onClick={e => setShowTransactionOptions(false)}>API Sales Consumption</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/api-sales-adjustment' onClick={e => setShowTransactionOptions(false)}>API Sales Adjustment</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={reportsDropdownCloseHandler} onMouseEnter={e => setShowReportOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='reportsDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaRegNewspaper className={classes.headerIcon}/>
                                        Reports
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showReportOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='reportsDropdown'>
                                            <div className='dropdown' onMouseLeave={e => setShowLapuCurrencyReports(false)} onMouseEnter={e => setShowLapuCurrencyReports(true)}>
                                                <a className={`dropdown-item drpodown-toggle arrow-none ${classes.dropdownItem}`} href='#' id='lapuCurrReportsDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                    Lapu Currency Reports
                                                    <div className={`${classes.arrowRight}`}></div>
                                                </a>
                                                { showLapuCurrencyReports &&
                                                    <div className={`dropdown-menu dropdown-menu-right ${classes.dropdownMenu}`} style={{width: '16rem', left: '13rem', top: '0px'}} aria-labelledby='lapuCurrReportsDropdown'>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-currency-purchase' onClick={e => setShowReportOptions(false)}>Supplier Wise Currency Purchase</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-currency-pending' onClick={e => setShowReportOptions(false)}>Supplier Wise Currency Pending</Link>
                                                        <div className='dropdown-divider'></div>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-currency-sales' onClick={e => setShowReportOptions(false)}>Supplier Wise Currency Sales</Link>
                                                        <div className='dropdown-divider'></div>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/operator-currency' onClick={e => setShowReportOptions(false)}>Operator Wise Currency Report</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-currency' onClick={e => setShowReportOptions(false)}>Supplier Wise Currency Report</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/lapu-currency' onClick={e => setShowReportOptions(false)}>Lapu Wise Currency Report</Link>
                                                        <div className='dropdown-divider'></div>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-profit' onClick={e => setShowReportOptions(false)}>Supplier Wise Profit Report</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/operator-profit' onClick={e => setShowReportOptions(false)}>Operator Wise Profit Report</Link>
                                                    </div>
                                                }
                                            </div>
                                            <div className='dropdown-divider'></div>
                                            <div className='dropdown' onMouseLeave={e => setShowApiCurrencyReports(false)} onMouseEnter={e => setShowApiCurrencyReports(true)}>
                                                <a className={`dropdown-item drpodown-toggle arrow-none ${classes.dropdownItem}`} href='#' id='apiCurrReportsDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                    API Currency Reports
                                                    <div className={`${classes.arrowRight} float-end`}></div>
                                                </a>
                                                { showApiCurrencyReports &&
                                                    <div className={`dropdown-menu dropdown-menu-right ${classes.dropdownMenu}`} style={{width: '19.5rem', left: '13rem', top: '0px'}} aria-labelledby='apiCurrReportsDropdown'>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-api-purchase' onClick={e => setShowReportOptions(false)}>Supplier Wise API Purchase</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-api-purchase-consumption' onClick={e => setShowReportOptions(false)}>Supplier Wise API Purchase Consumption</Link>
                                                        <div className='dropdown-divider'></div>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/customer-api-sales' onClick={e => setShowReportOptions(false)}>Customer Wise API Sales</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/customer-api-sales-consumption' onClick={e => setShowReportOptions(false)}>Customer Wise API Sales Consumption</Link>
                                                        <div className='dropdown-divider'></div>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/supplier-api-stock' onClick={e => setShowReportOptions(false)}>Supplier Wise API Stock</Link>
                                                        <Link className={`dropdown-item ${classes.dropdownItem}`} to='/customer-api-stock' onClick={e => setShowReportOptions(false)}>Customer Wise API Stock</Link>
                                                    </div>
                                                }
                                            </div>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/daily-profit' onClick={e => setShowReportOptions(false)}>Daily Profit Report</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/consolidated-stock' onClick={e => setShowReportOptions(false)}>Consolidated Stock Report</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowPlansOptions(false)} onMouseEnter={e => setShowPlansOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='plansDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaTag className={classes.headerIcon}/>
                                        Plans
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showPlansOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='plansDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/plans-list' onClick={e => setShowPlansOptions(false)}>Plans List</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowUtilsOptions(false)} onMouseEnter={e => setShowUtilsOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='utilssDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaFileExcel className={classes.headerIcon}/>
                                        Utils
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showUtilsOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='utilssDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/compare-transactions' onClick={e => setShowUtilsOptions(false)}>Compare Transactions</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowSettingsOptions(false)} onMouseEnter={e => setShowSettingsOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='settingsDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaCog className={classes.headerIcon}/>
                                        Settings
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showSettingsOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='settingsDropdown'>
                                            { userType === 'Admin' && <Link className={`dropdown-item ${classes.dropdownItem}`} to='#' onClick={e => modalOpenHandler('Set Opening Date')}>Set Opening Date</Link> }
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='#' onClick={e => modalOpenHandler('Delete Lapu Sales')}>Delete Lapu Sales</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='#' onClick={e => modalOpenHandler('Delete All Lapu Transactions')}>Delete All Lapu Transactions</Link>
                                            {/* <Link className={`dropdown-item ${classes.dropdownItem}`} to='/set-opening-date' onClick={e => setShowSettingsOptions(false)}>Set Opening Date</Link> */}
                                            {/* <Link className={`dropdown-item ${classes.dropdownItem}`} to='/delete-lapu-sales' onClick={e => setShowSettingsOptions(false)}>Delete Lapu Sales</Link> */}
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/update-company' onClick={e => setShowSettingsOptions(false)}>Update Company</Link>
                                            <div className='dropdown-divider'></div>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/staff' onClick={e => setShowSettingsOptions(false)}>Staff</Link>
                                        </div>
                                    }
                                </li>
                                }
                                { userType !== 'Superuser' && <li className={`nav-item dropdown ${classes.navItem}`} onMouseLeave={e => setShowHelpOptions(false)} onMouseEnter={e => setShowHelpOptions(true)}>
                                    <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='helpDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                        <FaQuestionCircle className={classes.headerIcon}/>
                                        Help
                                        <div className={classes.arrowDown}></div>
                                    </a>
                                    { showHelpOptions &&
                                        <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='helpDropdown'>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/faq' onClick={e => setShowHelpOptions(false)}>FAQ</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='#' onClick={e => setShowHelpOptions(false)}>Tutorial</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/refund-policy' onClick={e => setShowHelpOptions(false)}>Refund Policy</Link>
                                            <Link className={`dropdown-item ${classes.dropdownItem}`} to='/terms-and-conditions' onClick={e => setShowHelpOptions(false)}>Terms and Conditions</Link>
                                            <a className={`dropdown-item ${classes.dropdownItem}`} href='https://paperfreesolutions.com/' target='_blank' onClick={e => setShowHelpOptions(false)}>About Us</a>
                                        </div>
                                    }
                                </li>
                                }
                            </ul>
                        }
                        <SettingsPage modalIsOpen={modalIsOpen} modalName={modalName} onCloseModal={modalCloseHandler} />
                        {/* <li className='nav-item'>
                            <Link className={classes.navLink} to='/help'>
                                <FaQuestionCircle className={classes.headerIcon}/>
                                Help
                            </Link>
                        </li> */}
                        {/* <li className='nav-item'>
                                <Link className={classes.navLink} to='/settings'>
                                    <FaCog className={classes.headerIcon}/>
                                    Settings
                                </Link>
                            </li> */}
                            {/* <li className='nav-item'>
                                <Link className={classes.navLink} to='/plans'>
                                    <FaTag className={classes.headerIcon}/>
                                    Plans
                                </Link>
                            </li> */}
                        {/* <ul className='nav navbar-nav'>
                            <li className='nav-item dropdown'>
                                <a className={`nav-link dropdown-toggle ${classes.navLink}`} href='#' id='userDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                    <FaUserCircle className={classes.headerIcon} style={{fontSize: '32px'}} />
                                    <div className={classes.arrowDown}></div>
                                </a>
                                <div className={`dropdown-menu dropdown-menu-right ${classes.dropdownMenu}`} aria-labelledby='userDropdown'>
                                    <a className={`dropdown-item ${classes.dropdownItem}`} onClick={handleLogout}>Logout</a>
                                </div>
                            </li>
                        </ul> */}
                    </div>
                </nav>
            </div>
        </div>
    ); 
}

export default withRouter(Navbar);
