import React from 'react';
import { useHistory } from 'react-router';

import Modal from '../../components/UI/Modal/Modal';
import { uiDate } from '../../_helpers/utils';
import classes from './WarningModal.module.css';

const WarningModal = props => {

    const { expiryDate } = props.modalData;

    const history = useHistory();

    const closeHandler = () => {
        history.push('/dashboard');
        props.onCloseModal();
    }

    const purchaseClickHandler = () => {
        history.push('/plans-list');
        props.onCloseModal();
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={closeHandler}
            afterOpenModal={e => {}}
            modalName='Renew Plan'
            onSaveChanges={purchaseClickHandler}
            btnText='Purchase'
        >
            <div className={classes.text}>Plan expired on <b>({uiDate(expiryDate)})</b>. Click on Purchase to view and buy a plan.</div>
        </Modal>
    );

}

export default React.memo(WarningModal);