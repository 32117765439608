import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerWiseAPISalesConsumptionDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Customer', 'Operator', 'Consumption Date', 'Consumption', 'Commission Type', 'Commission %', 'Commission', 'Difference(+/-)', 'Total'];
    const dataFields = ['customerName', 'operatorName', 'consumptionDate', 'consumption', 'percentageTypeName', 'commissionRate', 'commission', 'adjustment', 'total'];
    const csvHeaders = [
        { label: 'Customer', key: 'customerName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Consumption Date', key: 'consumptionDate' },
        { label: 'Consumption', key: 'consumption' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Difference(+/-)', key: 'adjustment' },
        { label: 'Total', key: 'total' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left', 
            'Operator': 'center', 
            'Consumption Date': 'center',
            'Consumption': 'center',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Difference(+/-)': 'center',
            'Total': 'center',
        },
        widthConfig: {
            'Customer': 'auto', 
            'Operator': '122px', 
            'Consumption Date': '122px',
            'Consumption': '122px',
            'Commission Type': '122px',
            'Commission %': '122px',
            'Commission': '122px',
            'Difference(+/-)': '122px',
            'Total': '122px',
        },
        alignConfig: {
            'customerName': 'left',
            'operatorName': 'center',
            'consumptionDate': 'center',
            'consumption': 'right',
            'percentageTypeName': 'center',
            'commissionRate': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'total': 'right'
        }
    };

    useEffect(() => {
        getPurchaseDetailedData();
    }, []);
    
    const getPurchaseDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        const customerId = window.customerId;
        const operatorId = window.operatorId;
        url = (customerId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionDetailed?startDate=${window.startDate}&endDate=${window.endDate}` :
                customerId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionDetailed?startDate=${window.startDate}&endDate=${window.endDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionDetailed?startDate=${window.startDate}&endDate=${window.endDate}&customerId=${customerId}` :
                        `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesConsumptionDetailed?startDate=${window.startDate}&endDate=${window.endDate}&customerId=${customerId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.consumptionDate = data.consumptionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['consumption', 'commissionRate', 'commission', 'adjustment', 'total'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['consumptionAmount', 'commission', 'adjustment', 'totalAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        total.consumptionAmount,
                        '',
                        '',
                        total.commission,
                        total.adjustment,
                        total.totalAmount,
                    ]);
                    setExportTotalData({
                        customerName: 'Total',
                        operatorName: '',
                        consumptionDate: '',
                        consumption: total.consumptionAmount,
                        percentageTypeName: '',
                        commissionRate: '',
                        commission: total.commission,
                        adjustment: total.adjustment,
                        total: total.totalAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'CustomerWiseAPISalesConsumptionDetailed';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.customerName !== '' ? '_' + window.customerName : '';
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        setExportFileName(exportFilename);
    }
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Customer Wise API Sales Consumption Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default CustomerWiseAPISalesConsumptionDetailedPage;