import classes from './PageTitle.module.css';

const PageTitle = props => {
    return (
        <div className='row'>
            <div className='col-12'>
                <div className={classes.pageTitleBox}>
                    <h4 className={classes.pageTitle}>{props.title}</h4>
                </div>
            </div>
        </div>
    );
}

export default PageTitle;