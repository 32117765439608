import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';

import CONSTANTS from '../../../../../_constants/constants';
import AuthImage from '../../AuthImage/AuthImage';
import { handleResponse } from '../../../../../_helpers/handle-response';
import { validateMobileNoInput } from '../../../../../_helpers/validators';
import { authenticationService } from '../../../../../_services/authentication.service';
import Alert from '../../../../UI/Alert/Alert';
import Button from '../../../../UI/Button/Button';

import classes from './ForgotPasswordPage.module.css';

const ForgotPasswordPage = () => {

    const history = useHistory();

    const [mobileNumber, setMobileNumber] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [OTP, setOTP] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [appLogoProps, setAppLogoProps] = useState({});
    const [authImageProps, setAuthImageProps] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => getCompanyDetails(), []);

    const getCompanyDetails = () => {
        authenticationService.masterLogin()
        .then( result => {
            if(result.data) {
                const { appLogoImage, desktopImage, appName } = result.data;
                setAppLogoProps({ appLogoImage, appName });
                setAuthImageProps({ desktopImage, appName });
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        },
        error => setErrorMessage('Something went wrong!'))
    }

    const submitClickHandler = e => {
        if(newPassword === confirmPassword) {
            setErrorMessage(null);
            let apiUrl = `${CONSTANTS.API_URL}Authentication/ForgotPassword`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    companyCode,
                    otp: OTP,
                    password: newPassword,
                    clientId: authenticationService.getClientId()
                })
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        setSuccessMessage('Password changed successfully! Click Return to Login button below to login to your account.');
                    } else if(result.error) {
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        } else {
            setErrorMessage('New and Confirm Passwords must be same!')
        }
    }

    const validateMobileNumber = e => {
        e.preventDefault();
        setErrorMessage(null);
        if(mobileNumber === '') {
            setHasError(true);
        } else {
            let apiUrl = `${CONSTANTS.API_URL}Authentication/ValidateForgotMobileNo`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ mobileNumber, clientId: authenticationService.getClientId() })
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        setCompanyCode(result.data.companyCode);
                        setShowAdditionalFields(true);
                    } else if(result.error) {
                        setShowAdditionalFields(true);
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        }
    }

    const resendOTPClickHandler = () => {
        setErrorMessage(null);
        let apiUrl = `${CONSTANTS.API_URL}Authentication/ReSendOTP`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({  mobileNumber })
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const loginClickHandler = () => {
        history.push('/login');
    }

    const mobileNumberChangeHandler = e => {
        setHasError(false);
        const value = e.target.value;
        if(validateMobileNoInput(value) || value === '') {
            setMobileNumber(value);
            setShowAdditionalFields(false);
        }
    }

    const newPasswordChangeHandler = e => {
        setErrorMessage(null);
        setNewPassword(e.target.value);
    }
    
    const confirmPasswordChangeHandler = e => {
        setErrorMessage(null);
        setConfirmPassword(e.target.value);
    }

    // useMemo(() => {
    //     errorMessage === '' ? setError(false): setError(true);
    // }, [errorMessage]);

    return (
        <div className={`${classes.authBg} m-0 p-0`} data-layout-config='{"darkMode":false}'>

        <div className={classes.authFluid}>

            <AuthImage {...authImageProps} />
            
            <div className={classes.authFluidFormBox}>
                <div className={`${classes.alignItemsCenter} d-flex`}>
                    <div className="card-body">
                        <div className={`${classes.authBrand} mb-2`}>
                            <a href="#" className={classes.logoLight}>
                            <span><img src={appLogoProps.appLogoImage} alt={appLogoProps.appName} height="56" /></span>
                            </a>
                        </div>
                        { !successMessage &&
                            <>
                                <h4 className="mt-0">Forgot Password</h4>
                                <p className="text-muted mb-3">Enter Mobile Number to reset your password.</p>
                                <form>
                                    <div className="form-group has-validation">
                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                        <input className={`form-control ${hasError && mobileNumber === '' ? 'is-invalid': ''}`} type="text" id="mobileNumber" placeholder="Enter Mobile Number" value={mobileNumber} onChange={mobileNumberChangeHandler} autoFocus />
                                        <div className='invalid-feedback'>Please enter Mobile Number to continue.</div>
                                    </div>
                                    { !showAdditionalFields &&
                                        <div className="form-group mb-0 text-center mt-3">
                                            <Button btnClass='btn btn-primary btn-block' onClick={validateMobileNumber} btnText='Next' />
                                        </div>
                                    }
                                    { showAdditionalFields &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="otp">OTP</label>
                                                <input className="form-control" type="text" required id="otp" placeholder="Enter OTP" onChange={e => setOTP(e.target.value)} />
                                                <p className="text-muted float-right" onClick={resendOTPClickHandler} style={{textDecoration: 'underline', display:'inline', cursor: 'pointer'}}><small>Resend OTP</small></p>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='new-password'>New Password : </label>
                                                <div className='input-group'>
                                                    <input type={showNewPassword ? 'text' : 'password'} id='new-password' className='form-control' value={newPassword} onChange={newPasswordChangeHandler} />
                                                    <div className='input-group-addon'>
                                                        <div className='input-group-text'>
                                                            <span>
                                                                { showNewPassword && <FaEyeSlash title='Hide Password' onClick={e=>setShowNewPassword(false)}/> }
                                                                { !showNewPassword && <FaRegEye title='Show Password' onClick={e=>setShowNewPassword(true)} /> }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='confirm-password'>Confirm Password : </label>
                                                <div className='input-group'>
                                                    <input type='password'  type={showConfirmPassword ? 'text' : 'password'} id='confirm-password' className='form-control' value={confirmPassword} onChange={confirmPasswordChangeHandler} />
                                                    <div className='input-group-addon'>
                                                        <div className='input-group-text'>
                                                            <span>
                                                                { showConfirmPassword && <FaEyeSlash title='Hide Password' onClick={e=>setShowConfirmPassword(false)}/> }
                                                                { !showConfirmPassword && <FaRegEye title='Show Password' onClick={e=>setShowConfirmPassword(true)} /> }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 text-center">
                                                <Button btnClass='btn btn-primary btn-block' onClick={submitClickHandler} btnText='Submit' />
                                            </div>
                                        </>
                                    }
                                </form>
                                { errorMessage && <div className={`${'alert alert-danger'} ${classes.errorMessage} mt-3`} role="alert">{errorMessage}</div> }
                            </>
                        }
                        { successMessage && 
                            <div className='mt-4'>
                                <Alert alertClassName='success' message={successMessage} />
                                <div className="form-group mb-0 text-center">
                                    <Button btnClass='btn btn-primary btn-block' onClick={loginClickHandler} btnText='Return to Login' />
                                </div>
                            </div>
                        }
                        { !successMessage && 
                            <footer className={`${classes.footer} ${classes.footerAlt}`}>
                                <span className="text-muted">Return to
                                    <p onClick={loginClickHandler} className="authPageLink">Login</p>
                                </span>
                            </footer>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ForgotPasswordPage;