import { useEffect, useState } from 'react';

import PageTitle from '../../UI/PageTitle/PageTitle';
import { getPlanStatus } from '../../../_helpers/functions/utils';
import CONSTANTS from '../../../_constants/constants';
import WarningModal from '../../../common/WarningModal/WarningModal';
import { authenticationService } from '../../../_services/authentication.service';

import classes from './PageLayout.module.css';

const PageLayout = props => {

    const { children, pageTitle } = props;

    const [modalData, setModalData] = useState({});
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [blockPage, setBlockPage] = useState(false);

    useEffect(() => {
        getCurrentPlanStatus();
        getPageBlockStatus();
    }, [children]);

    const getCurrentPlanStatus = () => {
        getPlanStatus().then(
            result => {
                setIsModalOpened(result.status === CONSTANTS.PLAN_STATUS.EXPIRED);
                setModalData(result);
            }
        );
    }

    const getPageBlockStatus = () => {
        const block = authenticationService.blockRoute();
        setBlockPage(block);
    }

    const onCloseModal = () => setIsModalOpened(false);

    return (
        <>
            <PageTitle title={pageTitle} />
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className={`card-body ${classes.customCardBody}`}>
                            {children}
                            <WarningModal 
                                modalData={modalData}
                                isModalOpened={isModalOpened && blockPage}
                                onCloseModal={onCloseModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageLayout;