import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateNegDecimalInput } from '../../../../_helpers/validators';
import classes from './SupplierWiseCurrencyPurchaseTransactionModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const SupplierWiseCurrencyPurchaseTransactionModal = props => {

    const [transactionDate, setTransactionDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('-2');
    const [operatorId, setOperatorId] = useState('-2');
    const [netAmount, setNetAmount] = useState();
    const [percentageTypeId, setPercentageTypeId] = useState();
    const [percentageTypeName, setPercentageTypeName] = useState();
    const [commissionRate, setCommissionRate] = useState();
    const [commission, setCommission] = useState();
    const [adjustment, setAdjustment] = useState(0.00);
    const [netCurrency, setNetCurrency] = useState();
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);
    // const [percentageTypeData, setPercentageTypeData] = useState([]);

    useEffect(() => {
        updateModal();
        getSupplierList();
        if(props.modalName === 'Edit') {
            getOperatorListBySupplier(props.modalData.supplierId);
            getCommissionTypeBySupplierNOperator(props.modalData.supplierId, props.modalData.operatorId);
        }
        return () => {
            setTransactionDate('');
            setSupplierId('');
            setOperatorId('');
            setNetAmount('');
            setPercentageTypeId('');
            setCommissionRate('');
            setCommission('');
            setAdjustment('');
            setNetCurrency('');
        }
    }, []);

    const customStyles = {
        content: {
            top: '56%',
            left: '50%',
            right: 'auto',
            width: '38%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    useEffect(() => {
        if(!!netAmount && !!percentageTypeName && !!commissionRate) {
            var commission = 0;
            if(percentageTypeName === 'ADD ON') {
                commission = parseFloat(netAmount) * parseFloat(commissionRate) / (100 - parseFloat(commissionRate));
            } else if(percentageTypeName === 'DIRECT') {
                commission = parseFloat(netAmount) * (parseFloat(commissionRate) / 100);
            }
            setCommission(commission.toFixed(2));
            if(adjustment === '' || adjustment === '-') {
                setNetCurrency((parseFloat(netAmount) + commission).toFixed(2));
            } else {
                setNetCurrency((parseFloat(netAmount) + commission + parseFloat(adjustment)).toFixed(2));
            }
        }
    }, [netAmount, adjustment, percentageTypeName, commissionRate]);

    const updateModal = () => {
        setTransactionDate(Object.keys(props.modalData).length > 0 ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        setSupplierId(!!props.modalData.supplierId ? props.modalData.supplierId : '');
        setOperatorId(Object.keys(props.modalData).length > 0 ? props.modalData.operatorId : '');
        setNetAmount(Object.keys(props.modalData).length > 0 ? props.modalData.netAmount : (0).toFixed(2));
        setPercentageTypeId(Object.keys(props.modalData).length > 0 ? props.modalData.percentageTypeId : '');
        // setPercentageTypeId(Object.keys(props.modalData).length > 0 ? props.modalData.percentageTypeId : percentageTypeData[0].percentageTypeId);
        setCommissionRate(Object.keys(props.modalData).length > 0 ? props.modalData.commissionRate : '');
        setCommission(Object.keys(props.modalData).length > 0 ? props.modalData.commission : '');
        setAdjustment(!!props.modalData.adjustment ? props.modalData.adjustment: (0).toFixed(2));
        setNetCurrency(Object.keys(props.modalData).length > 0 ? props.modalData.netCurrency : '');
    }

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal').then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    setSupplierId((!!props.modalData.supplierId) && props.modalData.supplierId);
                } else {
                    setSupplierId(result.data.results[0].supplierId);
                }
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorListBySupplier = supplierId => {
        DropdownDataService.getOperatorListBySupplier('modal', supplierId).then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    setOperatorId((!!props.modalData.operatorId) && props.modalData.operatorId);
                }
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getCommissionTypeBySupplierNOperator = (supplierId, operatorId) => {
        DropdownDataService.getCommissionTypeBySupplierNOperator('LapuInput/LapuInput', supplierId, operatorId).then( result => {
            if(result.data) {
                setPercentageTypeId(result.data.percentageTypeId);
                setPercentageTypeName(result.data.percentageTypeName);
                setCommissionRate((result.data.commissionRate).toFixed(3));
                // if(props.modalName === 'Edit') {
                //     setPercentageTypeData(result.data.results);
                // } 
            } else {
                setErrorMessage(result);
            }
        });
    }

    const afterOpenModal = () => {
        // setTransactionDate(Object.keys(props.modalData).length > 0 ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        // setSupplierId(Object.keys(props.modalData).length > 0 ? props.modalData.supplierId : supplierData[0].supplierId);
        // setOperatorId(Object.keys(props.modalData).length > 0 ? props.modalData.operatorId : operatorData[0].operatorId);
        // setNetAmount(Object.keys(props.modalData).length > 0 ? props.modalData.netAmount : '');
        // setPercentageTypeId(Object.keys(props.modalData).length > 0 ? props.modalData.percentageTypeId : percentageTypeData[0].percentageTypeId);
        // setCommissionRate(Object.keys(props.modalData).length > 0 ? props.modalData.commissionRate : '');
        // setCommission(Object.keys(props.modalData).length > 0 ? props.modalData.commission : '');
        // setAdjustment(Object.keys(props.modalData).length > 0 ? props.modalData.adjustment : '');
        // setNetCurrency(Object.keys(props.modalData).length > 0 ? props.modalData.netCurrency : '');
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel');
    }

    const clearMessages = () => {
        setErrorMessage(null);
        setHasError(false);
    }

    const validateInput = value => {
        return value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    }
    
    const transactionDateChangeHandler = event => {
        clearMessages();
        setTransactionDate(event.target.value);
    }

    const supplierChangeHandler = event => {
        clearMessages();
        setSupplierId(event.target.value);
        setOperatorId('-2')
        getOperatorListBySupplier(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        setOperatorId(value);
        if(value === '-2') {
            setPercentageTypeId('');
            setPercentageTypeName('');
            setCommissionRate((0).toFixed(3));
        } else {
            getCommissionTypeBySupplierNOperator(supplierId, event.target.value);
        }
    }

    const netAmountChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value !== '') {
            if(validateInput(value)) {
                setNetAmount(value);
            }
        } else {
            setNetAmount(value);
        }
    }

    const adjustmentChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value !== '' && value !== '-') {
            if(validateNegDecimalInput(value)) {
                setAdjustment(value);
            }
        } else {
            setAdjustment(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits) 

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = 'https://royalinfo.in/api/pavathi/LapuPurchase/' + url;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (supplierId === '-2' || operatorId === '-2' || percentageTypeName === '' || commissionRate === '' )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {
                transactionDate,
                supplierId,
                operatorId,
                netAmount: Number(parseFloat(netAmount).toFixed(2)),
                percentageTypeId,
                commissionRate: parseFloat(commissionRate),
                commission: parseFloat(commission),
                adjustment: parseFloat(adjustment),
                netCurrency: parseFloat(netCurrency)
            };
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                url = 'AddLapuPurchase';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                url = `UpdateLapuPurchase?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                reqBody = {};
                url = `DeleteLapuPurchase?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} Lapu Purchase</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Purchase Date : </label>
                                <div className='col-sm-8'>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={transactionDate} onChange={transactionDateChangeHandler} />
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Supplier.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                <div className='col-sm-8'>  
                                    <select className={`form-select inputField ${hasError && supplierId !== '-2' && operatorId === '-2' ? 'is-invalid': ''}`} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={netAmount} onChange={netAmountChangeHandler} onBlur={ e => setNetAmount(onBlurHandler(e))}/>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' value={percentageTypeName} />
                                </div>
                            </div>
                            {/* <div className={classes.inputContainer}>
                                <label className={classes.inputLabel}>Commission Type : </label>
                                <select className={`form-select form-select-sm ${classes.modalInput}`} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                    { percentageTypeData.map( (value, index) => {
                                        return <option key={`operator-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                    }) }
                                </select>
                            </div> */}
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commissionRate} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commission} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Difference(+/-) : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={adjustment} onChange={adjustmentChangeHandler} onBlur={ e => setAdjustment(onBlurHandler(e))}/>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Net Received : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={netCurrency} />
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete Lapu Purchase?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(SupplierWiseCurrencyPurchaseTransactionModal);