import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerWiseAPISalesDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Customer', 'Sales Date', 'Sales', 'Commission Type', 'Commission %', 'Commission', 'Total Received'];
    const dataFields = ['customerName', 'salesDate', 'sales', 'percentageTypeName', 'commissionRate', 'commission', 'total'];
    const csvHeaders = [
        { label: 'Customer', key: 'customerName' },
        { label: 'Sales Date', key: 'salesDate' },
        { label: 'Sales', key: 'sales' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Total Received', key: 'total' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left', 
            'Sales Date': 'center',
            'Sales': 'center',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Total Received': 'center',
        },
        widthConfig: {
            'Customer': 'auto', 
            'Sales Date': '122px',
            'Sales': '122px',
            'Commission Type': '122px',
            'Commission %': '122px',
            'Commission': '122px',
            'Total Received': '122px',
        },
        alignConfig: {
            'customerName': 'left',
            'salesDate': 'center',
            'sales': 'right',
            'percentageTypeName': 'center',
            'commissionRate': 'right',
            'commission': 'right',
            'total': 'right'
        }
    };

    useEffect(() => {
        getPurchaseDetailedData();
    }, []);
    
    const getPurchaseDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        const startDate = window.startDate;
        const endDate = window.endDate;
        const customerId = window.customerId;
        url = customerId === '-1' ?
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesDetailed?startDate=${startDate}&endDate=${endDate}` :
                `${CONSTANTS.API_URL}Reports/CustomerwiseApiSalesDetailed?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.salesDate = data.salesDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['sales', 'commissionRate', 'commission', 'total'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['salesAmount', 'commission', 'totalAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.salesAmount,
                        '',
                        '',
                        total.commission,
                        total.totalAmount,
                    ]);
                    setExportTotalData({
                        customerName: '',
                        salesDate: '',
                        sales: total.salesAmount,
                        percentageTypeName: '',
                        commissionRate: '',
                        commission: total.commission,
                        total: total.totalAmount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'CustomerWiseAPISalesDetailed';
        exportFilename += '_' + window.startDate;
        exportFilename += '_' + window.endDate;
        exportFilename += window.customerName !== '' ? '_' + window.customerName : '';
        setExportFileName(exportFilename);
    }
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Customer Wise API Sales Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            )}    
        </>
    );
  
}

export default CustomerWiseAPISalesDetailedPage;