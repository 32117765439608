import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaKey, FaEnvelope, FaMobileAlt, FaSignOutAlt, FaUserCircle, FaTag } from 'react-icons/fa';

import { authenticationService } from '../../../_services/authentication.service';
import { ApiService } from '../../../_services/api.service';
import CONSTANTS from '../../../_constants/constants';
import { uiDate } from '../../../_helpers/utils';

import classes from './Header.module.css';

const Header = props => {
    
    const [userFullName, setUserFullName] = useState('');
    const [userType, setUserType] = useState('');
    const [planName, setPlanName] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [expiryDate, setExpiryDate] = useState();
    const [isTestMode, setIsTestMode] = useState(false);
    const [isSuperuser, setIsSuperuser] = useState(false);
    const [showPlanDetails, setShowPlanDetails] = useState(true);
    const [pendingDays, setPendingDays] = useState();

    const { appLogoImage, contactNumber, email } = props;

    useEffect(() => {
        setIsTestMode(localStorage.getItem('testMode'));
        setUserFullName(localStorage.getItem('currentUserFullName'));
        setUserType(localStorage.getItem('currentUserType'));
        setIsSuperuser(authenticationService.isSuperuser());
        getCurrentPlanDetails();
    }, []);

    const getCurrentPlanDetails = () => {
        const url = `${CONSTANTS.API_URL}Company/Company`;
        ApiService.getData(url).then(
            result => {
                if(result.data) {
                    setPlanName(result.data.planName.split(' ')[0]);
                    setCompanyCode(result.data.companyCode);
                    setPendingDays(result.data.pendingDays);
                    setExpiryDate(uiDate(result.data.expiryDate));
                } else {
                    setShowPlanDetails(false);
                }
            },
            () => setShowPlanDetails(false)
        );
    }

    const handleLogout = () => authenticationService.logout()

    return ( 
        <div className={`${classes.navbarCustom} ${classes.topnavNavbar} ${classes.topnavNavbarDark}`}>
            <div className='container-fluid'>
                { isTestMode === 'true' && 
                    <div className={classes.mode}>
                        <div className={classes.modeBadge}>Test Mode</div>
                    </div>
                }
                <a className={classes.topnavLogo}>
                    <img src={appLogoImage} alt='Pavathi' height='60' width='60' />
                </a>

                <div className='float-end'>
                    <span className={classes.contactSection}>
                        {/* <p className='pr-3'><FaUserClock className={classes.contactIcon} /> Mon - Fri 10:00 - 18:00</p> */}
                        {   !isSuperuser && <>
                                <div className={classes.planDetail}>
                                    <p className={`pl-2 pr-2 ${(pendingDays < 4 && pendingDays > 0 ) ? classes.blink : ''}`}>
                                    <FaTag className={classes.contactIcon} /> Current <b>{planName}</b> plan expires on <b>{expiryDate} </b>
                                    </p>
                                </div>
                                <div className={classes.divider}></div>
                            </>
                        }
                        <p className={`${classes.contactDetail}`}><FaMobileAlt className={classes.contactIcon} /> {contactNumber} </p>
                        {/* <p className={`${classes.contactDetail}`}><FaMobileAlt className={classes.contactIcon} /> +91 9845399724 </p> */}
                        <div className={classes.divider}></div>
                        <p className={`${classes.contactDetail} pr-0`}><FaEnvelope className={classes.contactIcon} /> {email}</p>
                        {/* <p className={`${classes.contactDetail} pr-0`}><FaEnvelope className={classes.contactIcon} /> support@paperfreesolutions.com</p> */}
                    </span>
                    <ul className={`${classes.listUnstyled} ${classes.topbarMenu} float-end mb-0`}>
                        <li className={`dropdown ${classes.notificationList}`}>
                            <a className={`mr-0 dropdown-toggle ${classes.dropdownToggle} ${classes.navLink} ${classes.navUser} ${classes.arrowNone}`} data-toggle='dropdown' id='topbar-userdrop' role='button' aria-haspopup='true' aria-expanded='false'>
                                <span className={classes.accountUserAvatar}>
                                    <FaUserCircle className={classes.ronudedCircle} style={{fontSize: '32px'}} />
                                </span>
                                <span>
                                    <div className={classes.username}>
                                        {userFullName}
                                        <span className={classes.companyCode}> ({companyCode})</span>
                                    </div>
                                    <span className={classes.userType}>{userType}</span>
                                </span>
                            </a>
                            <div className={`dropdown-menu ${classes.dropdownMenu}`} aria-labelledby='topbar-userdrop'>
                                {/* <div className={`dropdown-header ${classes.title}`}>
                                    <h6 className='text-overflow m-0' style={{fontSize:'0.75rem'}}>Welcome !</h6>
                                </div> */}
                                <Link className={`dropdown-item ${classes.dropdownItem}`} to='/change-password'>
                                    <FaKey className={classes.userDropdownIcon}/>
                                    Change Password
                                </Link>
                                <Link className={`dropdown-item ${classes.dropdownItem}`} to='/login' onClick={handleLogout}>
                                    <FaSignOutAlt className={classes.userDropdownIcon}/>
                                    Logout
                                </Link>
                            </div>
                            {/* <div className={`dropdown-menu ${classes.dropdownMenu} dropdown-menu-end ${classes.dropdownMenuAnimated} ${classes.topbarDropdownMenu} ${classes.profileDropdown}`} aria-labelledby='topbar-userdrop'>
                                <div className={`dropdown-header ${classes.title}`}>
                                    <h6 className='text-overflow m-0' style={{fontSize:'0.75rem'}}>Welcome !</h6>
                                </div>
                                <Link className={`dropdown-item ${classes.dropdownItem}`} to='/login' onClick={handleLogout}>
                                    <FaSignOutAlt className={classes.signOutIcon}/>
                                    Logout
                                </Link>
                            </div> */}
                        </li>
                    </ul>
                {/* <button type='button' className={`navbar-toggle collapsed ${classes.navbarToggle}`} data-toggle='collapse' data-target='#navbarMenu' aria-expanded='true' aria-controls='navbarMenu' aria-label='Toggle navigation'>
                    <div className={classes.lines}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button> */}
                </div>
                {/* <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#appNavbar'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='collapse navbar-collapse' id='appNavbar'>
                    <ul className='nav navbar-nav ml-auto'>
                        <li className={`nav-item dropdown ${classes.userDropdown}`}>
                            <a className={`nav-link dropdown-toggle ${classes.navLink} ${classes.navbarUser}`} href='#' id='userDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                <span className={classes.userIcon}>
                                    <FaUserCircle className={classes.headerIcon} style={{fontSize: '32px'}} />
                                </span>
                                <span>
                                    <span className={classes.username}>{userFullName}</span>
                                    <span className={classes.userType}>{userType}</span>
                                </span>
                            </a>
                            <div className={`dropdown-menu dropdown-menu-right ${classes.dropdownMenu}`} aria-labelledby='userDropdown'>
                                <div className={`dropdown-header ${classes.title}`}>
                                    <h6 className='text-overflow m-0' style={{fontSize:'0.75rem'}}>Welcome !</h6>
                                </div>
                                <Link className={`dropdown-item ${classes.dropdownItem}`} to='/login' onClick={handleLogout}>
                                    <FaSignOutAlt className={classes.signOutIcon}/>
                                    Logout
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </div>
        {/* </nav> */}
        </div>
    ); 
}

export default Header;
