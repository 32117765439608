import { BehaviorSubject } from 'rxjs';
import CONSTANTS from '../_constants/constants';

import { handleResponse } from '../_helpers/handle-response';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

const routesToAllow = [ 
    'dashboard', 
    'supplier-currency-purchase', 'supplier-currency-pending', 'supplier-currency-sales', 'operator-currency', 'supplier-currency', 'lapu-currency', 'supplier-profit', 'operator-profit',
    'supplier-api-purchase', 'supplier-api-purchase-consumption', 'customer-api-sales', 'customer-api-sales-consumption', 'supplier-api-stock', 'customer-api-stock',
    'daily-profit', 'consolidated-stock',
    'faq', 'refund-policy', 'terms-and-conditions',
    'operator-mapping', 'company', 'plan', 'payment'
];

const login = (userName, password, companyCode) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, password, companyCode, clientId: getClientId() })
    };
    return fetch(`${CONSTANTS.API_URL}Authentication/UserLogin`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(!!result.data) {
                    localStorage.setItem('currentUser', result.data.UserName);
                    localStorage.setItem('currentUserFullName', result.data.FullName);
                    localStorage.setItem('accessToken', result.data.AccessToken);
                    localStorage.setItem('testMode', getIsTestMode());
                    localStorage.setItem('currentUserType', result.data.UserType);
                    currentUserSubject.next(result.data.UserName);
                }
                return result;
            },
            error => error
        )
}

const masterLogin = () => {
    const company = window.location.href.split('/')[3];
    const loginObject = { company: window.location.href.includes('clareinfotech.in') ? 'clareinfotech' : company };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginObject)
    };
    return fetch(`${CONSTANTS.API_URL}Authentication/MasterLogin`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(!!result.data) {
                    localStorage.setItem('clientId', result.data.masterCompanyId);
                    localStorage.setItem('company', result.data.company.toLowerCase());
                    localStorage.setItem('copyRight', result.data.copyRight);
                }
                return result;
            },
            error => error
        )
}

const getIsTestMode = () => window.location.href.indexOf('pavathi.in') === -1

const blockRoute = () => routesToAllow.indexOf(window.location.href.split('/').slice(-1)[0]) === -1

const isLoggedIn = () => !!localStorage.getItem('accessToken')

const getClientId = () => !!localStorage.getItem('clientId') && parseInt(localStorage.getItem('clientId'))

const isSuperuser = () => !!localStorage.getItem('currentUserType') && localStorage.getItem('currentUserType') === 'Superuser'

const logout = () => {
    currentUserSubject.next(null);
    localStorage.clear();
}

export const authenticationService = {
    login,
    masterLogin,
    isLoggedIn,
    logout,
    isSuperuser,
    blockRoute,
    getClientId,
    currentUserSubject,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};