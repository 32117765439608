import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { validateDecimalInput } from '../../../../_helpers/validators';
import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import { formatInputOnBlur } from '../../../../_helpers/utils';

import classes from './SupplierWiseLapuSalesTransactionModal.module.css';

const SupplierWiseLapuSalesTransactionModal= props => {

    const { lapuNumber, lastClosing, closingBalance } = props.modalData;

    const [lastReceived, setLastReceived] = useState();
    const [lastSales, setLastSales] = useState();
    const [lastIncentive, setLastIncentive] = useState();
    const [openingBalance, setOpeningBalance] = useState();
    const [received, setReceived] = useState();
    const [sales, setSales] = useState();
    const [incentive, setIncentive] = useState();
    const [difference, setDifference] = useState(props.modalData.difference);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLastReceived(props.modalData.lastReceived);
        setLastSales(props.modalData.lastSales);
        setLastIncentive(props.modalData.lastIncentive);
        setOpeningBalance(props.modalData.openingBalance);
        setReceived(props.modalData.received);
        setSales(props.modalData.sales);
        setIncentive(props.modalData.incentive);
        setDifference(props.modalData.difference);
    }, []);
    
    useEffect(() => {
        // setOpeningBalance((parseFloat(lastClosing) + parseFloat(lastReceived) - parseFloat(lastSales) + parseFloat(lastIncentive)).toFixed(2));
        let received = lastReceived === '' ? 0 : lastReceived;
        let sales = lastSales === '' ? 0 : lastSales;
        let incentive = lastIncentive === '' ? 0 : lastIncentive;
        props.modalName === 'Previous' && setDifference((parseFloat(lastClosing) + parseFloat(received) - parseFloat(sales) + parseFloat(incentive) - parseFloat(openingBalance)).toFixed(2));
    }, [lastClosing, lastReceived, lastSales, lastIncentive, openingBalance]);
    
    useEffect(() => {
        let currentReceived = received === '' ? 0 : received;
        let currentSales = sales === '' ? 0 : sales;
        let currentIncentive = incentive === '' ? 0 : incentive;
        props.modalName === 'Current' && setDifference((parseFloat(openingBalance) + parseFloat(currentReceived) - parseFloat(currentSales) + parseFloat(currentIncentive) - parseFloat(closingBalance)).toFixed(2));
    }, [openingBalance, received, sales, incentive, closingBalance]);

    const customStyles = {
        content: {
            top: '56%',
            left: '50%',
            right: 'auto',
            width: '50%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const afterOpenModal = () => {}
    
    const onModalClose = event => props.onCloseModal('cancel')

    const prevReceivedChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setLastReceived(value);
        } else {
            if(validateDecimalInput(value)) {
                setLastReceived(value);
            }
        }
    }
    
    const prevSalesChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setLastSales(value);
        } else {
            if(validateDecimalInput(value)) {
                setLastSales(value);
            }
        }
    }
    
    const prevIncentiveChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setLastIncentive(value);
        } else {
            if(validateDecimalInput(value)) {
                setLastIncentive(value);
            }
        }
    }

    const receivedChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setReceived(value);
        } else {
            if(validateDecimalInput(value)) {
                setReceived(value);
            }
        }
    }
    
    const salesChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setSales(value);
        } else {
            if(validateDecimalInput(value)) {
                setSales(value);
            }
        }
    }
    
    const incentiveChangeHandler = event => {
        const value = event.target.value;
        if(value === '') {
            setIncentive(value);
        } else {
            if(validateDecimalInput(value)) {
                setIncentive(value);
            }
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const saveChanges = reqBody => {
        let apiUrl = `${CONSTANTS.API_URL}LapuSales/UpdateLapuSales?transactionId=${props.modalData.transactionId}`;
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(lastReceived === '' || lastSales === '' || lastIncentive === '' || received === '' || sales === '' || incentive === '' || parseFloat(difference) !== 0.00) {
            setHasError(true);
        } else{
            setHasError(false);
            let reqBody = {
                lastReceived: parseFloat(lastReceived),
                lastSales: parseFloat(lastSales),
                lastIncentive: parseFloat(lastIncentive),
                received: parseFloat(received),
                sales: parseFloat(sales),
                incentive: parseFloat(incentive),
                difference: parseFloat(difference)
            };
            saveChanges(reqBody);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>Edit - {props.modalName} Lapu Sales</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <form>
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Lapu Number :</label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled className='form-control inputField' value={lapuNumber} />
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Previous Closing :</label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled className='form-control inputField' style={{textAlign: 'right'}} value={lastClosing} />
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Previous Received :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && lastReceived === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={lastReceived} onChange={prevReceivedChangeHandler} onBlur={e=>setLastReceived(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter previous received.</div>
                                </div>
                            </div>
                            )   
                        }
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Previous Sales :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && lastSales === '' ? 'is-invalid': ''}`} value={lastSales} style={{textAlign: 'right'}} onChange={prevSalesChangeHandler} onBlur={e=>setLastSales(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter previous sales.</div>
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row has-validation ${hasError && lastIncentive === '' ? 'is-invalid': ''} ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Previous Incentive :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && lastSales === '' ? 'is-invalid': ''}`} value={lastIncentive} style={{textAlign: 'right'}} onChange={prevIncentiveChangeHandler} onBlur={e=>setLastIncentive(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter previous incentive.</div>
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Previous' && (
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Difference/Reversal :</label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={difference} />
                                    {/* <div className='invalid-feedback'>Difference must be 0.</div> */}
                                </div>
                            </div>
                            )
                        }
                        <div className={`form-group row ${classes.inputContainer}`}>
                            <label className='col-sm-4 col-form-label inputLabel'>Opening Balance :</label>
                            <div className='col-sm-8'>
                                <input type='text' disabled className='form-control inputField' value={openingBalance} style={{textAlign: 'right'}} />
                            </div>
                        </div>
                        { props.modalName === 'Current' && (
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Received :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && received === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={received} onChange={receivedChangeHandler} onBlur={e=>setReceived(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter received.</div>
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Current' && (
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Sales :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && sales === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={sales} onChange={salesChangeHandler} onBlur={e=>setSales(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter sales.</div>
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Current' && (
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Incentive :</label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && incentive === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={incentive} onChange={incentiveChangeHandler} onBlur={e=>setIncentive(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter incentive.</div>
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Current' && (
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Difference/Reversal :</label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={difference} />
                                    {/* <div className='invalid-feedback'>Difference must be 0.</div> */}
                                </div>
                            </div>
                            )
                        }
                        { props.modalName === 'Current' && (
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Closing Balance :</label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' value={closingBalance} style={{textAlign: 'right'}} />
                                </div>
                            </div>
                            )
                        }
                    </form>
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText='Cancel' />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText='Save' />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(SupplierWiseLapuSalesTransactionModal);