import classes from './FormGroup.module.css';

const FormGroup = props => {
    return (
        <div className={`form-group row has-validation ${classes.inputContainer}`}>
            { props.label && <label className={`col-sm-${props.labelWidth || 4} col-form-label ${classes.inputLabel}`}>{props.label} : </label> }
            <div className={`col-sm-${props.fieldWidth || 8}`}>
                <input 
                    type={props.type} 
                    name={props.name}
                    readOnly={props.readOnly} 
                    // className={`form-control ${classes.inputField} ${props.hasError ? 'is-invalid': ''}`} 
                    className={`form-control ${classes.inputField} ${classes[props.className]} ${props.hasError ? 'is-invalid': ''}`}
                    // className={`form-control ${classes.inputField} ${props.hasError && props.value === '' ? 'is-invalid': ''}`}
                    value={props.value} 
                    onChange={props.changeHandler} 
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    autoFocus={props.autoFocus}
                />
                { props.hasError && <div className={`invalid-feedback ${classes.invalidFeedback}`}>{props.errorMessage}</div> }
            </div>
        </div>
    )
}

export default FormGroup;