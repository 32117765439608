import { useEffect, useState } from 'react';

import { ApiService } from '../../../../_services/api.service';
import CONSTANTS from '../../../../_constants/constants';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import PageTitle from '../../../UI/PageTitle/PageTitle';

const UpdateCompanyPage = () => {

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [gstin, setGstin] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        const url =`${CONSTANTS.API_URL}Company/Company`;
        ApiService.getData(url).then( 
            result => {
                if(result.data) {
                    const data = result.data;
                    setAddress1(data.address1);
                    setAddress2(data.address2);
                    setCity(data.city);
                    setState(data.state);
                    setPincode(data.pincode);
                    setPhoneNumber(data.phoneNumber);
                    setEmail(data.email);
                    setGstin(data.gstin);
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
            );
    }, []);

    const updateCompanyHandler = () => {
        const url =`${CONSTANTS.API_URL}Company/UpdateCompany`;
        const reqBody = {
            address1,
            address2,
            city,
            state,
            pincode,
            phoneNumber,
            email,
            gstin
        }
        ApiService.updateData(url, reqBody).then(handleApiResponse);
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                setSuccessMessage('Company details updated successfully!');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const clearMessages = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    }

    const address1ChangeHandler = e => {
        clearMessages();
        setAddress1(e.target.value);
    }

    const address2ChangeHandler = e => {
        clearMessages();
        setAddress2(e.target.value);
    }

    const cityChangeHandler = e => {
        clearMessages();
        setCity(e.target.value);
    }
    
    const stateChangeHandler = e => {
        clearMessages();
        setState(e.target.value);
    }

    const pincodeChangeHandler = e => {
        clearMessages();
        setPincode(e.target.value);
    }

    const phoneNumberChangeHandler = e => {
        clearMessages();
        setPhoneNumber(e.target.value);
    }

    const emailChangeHandler = e => {
        clearMessages();
        setEmail(e.target.value);
    }

    const gstinChangeHandler = e => {
        clearMessages();
        setGstin(e.target.value);
    }

    return (
        <>
            <PageTitle title='Update Company' />
            <div className='card' style={{width: '50vw', padding: '8px 0px'}}>
                <div className='card-body customCardBody'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { successMessage && <Alert alertClassName='success' message={successMessage} /> }
                            <div className='container'>
                                <form>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>Address Line 1 : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={address1} onChange={address1ChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>Address Line 2 : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={address2} onChange={address2ChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>City : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={city} onChange={cityChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>State : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={state} onChange={stateChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>Pincode : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={pincode} onChange={pincodeChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>Phone Number : </label>
                                        <div className='col-7'>
                                            <input type='text' readOnly className='form-control inputFieldMd' value={phoneNumber} onChange={phoneNumberChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>Email : </label>
                                        <div className='col-7'>
                                            <input type='email' className='form-control inputFieldMd' value={email} onChange={emailChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-5 col-form-label inputLabel'>GSTIN : </label>
                                        <div className='col-7'>
                                            <input type='text' className='form-control inputFieldMd' value={gstin} onChange={gstinChangeHandler} />
                                        </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <Button btnClass='btn btn-primary' onClick={updateCompanyHandler} btnText='Update' />
                                    </div>
                                </form>
                            </div>
                </div>
            </div>
        </>
    );

}

export default UpdateCompanyPage;