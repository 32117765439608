import { Switch }  from 'react-router';
import CacheBuster from 'react-cache-buster';

import { authenticationService } from './_services/authentication.service';
import { ProtectedRoute, PublicRoute } from './_helpers/Routes';
import Loader from './components/UI/Loader/Loader';
import Layout from './components/Layout/Layout';
import { version } from '../package.json';
import './App.css';

import LoginPage from './components/Pages/Auth/Login/LoginPage';
import SignUpPage from './components/Pages/Auth/SignUp/SignUpPage';
import ForgotPasswordPage from './components/Pages/Auth/Login/ForgotPassword/ForgotPasswordPage';

import DashboardPage from './components/Pages/Dashboard/DashboardPage';

// Help Pages
import HelpPage from './components/Pages/Help/HelpPage';
import PlansPage from './components/Pages/Plans/PlansPage';
import FAQPage from './components/Pages/Help/FAQ/FAQPage';
import TermsNConditionsPage from './components/Pages/Help/Terms&Conditions/TermsNConditionsPage'
import RefundPolicyPage from './components/Pages/Help/Refund/RefundPolicyPage';
import ChangePasswordPage from './components/Layout/Header/ChangePassword/ChangePasswordPage';

// Master Pages
import SupplierMasterPage from './components/Pages/Masters/Supplier/SupplierMasterPage';
import SupplierCommMasterPage from './components/Pages/Masters/SupplierCommission/SupplierCommMasterPage';
import LapuSimMasterPage from './components/Pages/Masters/LapuSim/LapuSimMasterPage';
import LocalMarketCommMasterPage from './components/Pages/Masters/LocalMarketCommission/LocalMarketCommMasterPage';
import RegionMasterPage from './components/Pages/Masters/Region/RegionMasterPage';
import SupplierPurchasePendingPage from './components/Pages/Masters/SupplierPurchasePending/SupplierPurchasePendingPage';

// Settings Pages
import SettingsPage from './components/Pages/Settings/SettingsPage';
import UpdateCompanyPage from './components/Pages/Settings/UpdateCompany/UpdateCompanyPage';
import CompareTransactionsPage from './components/Pages/Settings/CompareTransactions/CompareTransactionsPage';
import StaffMasterPage from './components/Pages/Masters/Staff/StaffMasterPage';

// Transaction Pages
import SupplierWiseCurrencyPurchaseTransactionPage from './components/Pages/Transactions/CurrencyPurchase/SupplierWiseCurrencyPurchaseTransactionPage';
import SupplierWiseLapuSalesTransactionPage from './components/Pages/Transactions/SupplierWiseLapuSales/SupplierWiseLapuSalesTransactionPage';
import ImportLapuSalesPage from './components/Pages/Transactions/ImportLapuSales/ImportLapuSalesPage';
import APIPurchaseAdjustmentPage from './components/Pages/Transactions/APIPurchaseAdjustment/APIPurchaseAdjustmentPage';
import APISalesAdjustmentPage from './components/Pages/Transactions/APISalesAdjustment/APISalesAdjustmentPage';

//API Pages
import CustomerPage from './components/Pages/API/Customer/CustomerPage';
import APIPurchaseCommissionPage from './components/Pages/API/APIPurchaseCommission/APIPurchaseCommissionPage';
import APISalesCommissionPage from './components/Pages/API/APISalesCommission/APISalesCommissionPage';
import APIPurchasePage from './components/Pages/API/APIPurchase/APIPurchasePage';
import APISalesPage from './components/Pages/API/APISales/APISalesPage';
import APIPurchaseConsumptionPage from './components/Pages/API/APIPurchaseConsumption/APIPurchaseConsumptionPage';
import APISalesConsumptionPage from './components/Pages/API/APISalesConsumption/APISalesConsumptionPage';
import SupplierWiseAPIPurchasePage from './components/Pages/API/SupplierWiseAPIPurchase/SupplierWiseAPIPurchasePage';
import SupplierWiseAPIPurchaseDetailedPage from './components/Pages/API/SupplierWiseAPIPurchase/SupplierWiseAPIPurchaseDetailedPage';
import CustomerWiseAPISalesPage from './components/Pages/API/CustomerWiseAPISales/CustomerWiseAPISalesPage';
import CustomerWiseAPISalesDetailedPage from './components/Pages/API/CustomerWiseAPISales/CustomerWiseAPISalesDetailedPage';
import SupplierWiseAPIPurchaseConsumptionPage from './components/Pages/API/SupplierWiseAPIPurchaseConsumption/SupplierWiseAPIPurchaseConsumptionPage';
import SupplierWiseAPIPurchaseConsumptionDetailedPage from './components/Pages/API/SupplierWiseAPIPurchaseConsumption/SupplierWiseAPIPurchaseConsumptionDetailedPage';
import CustomerWiseAPISalesConsumptionPage from './components/Pages/API/CustomerWiseAPISalesConsumption/CustomerWiseAPISalesConsumptionPage';
import CustomerWiseAPISalesConsumptionDetailedPage from './components/Pages/API/CustomerWiseAPISalesConsumption/CustomerWiseAPISalesConsumptionDetailedPage';
import SupplierWiseAPIStockPage from './components/Pages/API/SupplierWiseAPIStock/SupplierWiseAPIStockPage';
import SupplierWiseAPIStockDetailedPage from './components/Pages/API/SupplierWiseAPIStock/SupplierWiseAPIStockDetailedPage';
import CustomerWiseAPIStockPage from './components/Pages/API/CustomerWiseAPIStock/CustomerWiseAPIStockPage';
import CustomerWiseAPIStockDetailedPage from './components/Pages/API/CustomerWiseAPIStock/CustomerWiseAPIStockDetailedPage';
import SupplierWiseAPIPurchasePendingPage from './components/Pages/API/SupplierAPIPurchasePending/SupplierWiseAPIPurchasePendingPage';
import CustomerWiseAPISalesPendingPage from './components/Pages/API/CustomerAPISalesPending/CustomerWiseAPISalesPendingPage';

// Report Pages
import SupplierWiseCurrencyPurchasePage from './components/Pages/Reports/SupplierWiseCurrencyPurchase/SupplierWiseCurrencyPurchasePage';
import SupplierWiseCurrencyPurchaseDetailedPage from './components/Pages/Reports/SupplierWiseCurrencyPurchase/SupplierWiseCurrencyPurchaseDetailedPage';
import SupplierWiseCurrencyPendingPage from './components/Pages/Reports/SupplierWiseCurrencyPending/SupplierWiseCurrencyPendingPage';
import SupplierWiseCurrencyPendingDetailedPage from './components/Pages/Reports/SupplierWiseCurrencyPending/SupplierWiseCurrencyPendingDetailedPage';
import SupplierSimWiseCurrencyTransferDetailedPage from './components/Pages/Reports/SupplierWiseCurrencyTransfer/SupplierSimWiseCurrencyTransferDetailedPage';
import SupplierWiseCurrencySalesPage from './components/Pages/Reports/SupplierWiseCurrencySales/SupplierWiseCurrencySalesPage';
import SupplierWiseSimWiseCurrencySalesPage from './components/Pages/Reports/SupplierWiseCurrencySales/SupplierWiseSimWiseCurrencySalesPage';
import SupplierWiseSimWiseDailyCurrencySalesPage from './components/Pages/Reports/SupplierWiseCurrencySales/SupplierWiseSimWiseDailyCurrencySalesPage';
import SupplierWiseCurrencyPage from './components/Pages/Reports/SupplierWiseCurrency/SupplierWiseCurrencyPage';
import SupplierWiseOperatorWiseCurrencyPage from './components/Pages/Reports/SupplierWiseCurrency/SupplierWiseOperatorWiseCurrencyPage';
import OperatorWiseCurrencyPage from './components/Pages/Reports/OperatorWiseCurrency/OperatorWiseCurrencyPage';
import OperatorWiseSupplierWiseCurrencyPage from './components/Pages/Reports/OperatorWiseCurrency/OperatorWiseSupplierWiseCurrencyPage';
import LapuWiseCurrencyPage from './components/Pages/Reports/LapuWiseCurrency/LapuWiseCurrencyPage';
import LapuWiseDailyCurrencyPage from './components/Pages/Reports/LapuWiseCurrency/LapuWiseDailyCurrencyPage';
import SupplierWiseProfitPage from './components/Pages/Reports/SupplierWiseProfit/SupplierWiseProfitPage';
import OperatorWiseProfitPage from './components/Pages/Reports/OperatorWiseProfit/OperatorWiseProfitPage';
import DailyProfitPage from './components/Pages/Reports/DailyProfit/DailyProfitPage';
import ConsolidatedStockPage from './components/Pages/Reports/ConsolidatedStock/ConsolidatedStockPage';

// Admin Panel Pages
import OperatorMappingMasterPage from './components/Pages/AdminPanel/OperatorMappingMaster/OperatorMappingMaster';
import CompanyMasterPage from './components/Pages/AdminPanel/CompanyMaster/CompanyMasterPage';
import PlanMasterPage from './components/Pages/AdminPanel/PlanMaster/PlanMasterPage';
import PaymentLogPage from './components/Pages/AdminPanel/PaymentLog/PaymentLog';

const App = () => {

  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    >
      <Layout auth={{ isLoggedIn: () => authenticationService.isLoggedIn() }}>
        <Switch>
          {/* <PublicRoute
            auth={{ isLoggedIn: () => authenticationService.isLoggedIn() }}
            path='/landing'
            component={LandingPage}
          /> */}
          <PublicRoute
            path='/login'
            component={LoginPage}
          />
          <PublicRoute
            path='/signup'
            component={SignUpPage}
          />
          <ProtectedRoute
            path='/supplier'
            component={SupplierMasterPage}
          />
          <ProtectedRoute
            path='/supplier-commission'
            component={SupplierCommMasterPage}
          />
          <ProtectedRoute
            path='/lapu-sim'
            component={LapuSimMasterPage}
          />
          <ProtectedRoute
            path='/local-market-commission'
            component={LocalMarketCommMasterPage}
          />
          <ProtectedRoute
            path='/region'
            component={RegionMasterPage}
          />
          <ProtectedRoute
            path='/staff'
            component={StaffMasterPage}
          />
          <ProtectedRoute
            path='/supplier-purchase-pending'
            component={SupplierPurchasePendingPage}
          />
          <ProtectedRoute
            path='/lapu-purchase'
            component={SupplierWiseCurrencyPurchaseTransactionPage}
          />
          <ProtectedRoute
            path='/import-lapu-sales'
            component={ImportLapuSalesPage}
          />
          <ProtectedRoute
            path='/customer'
            component={CustomerPage}
          />
          <ProtectedRoute
            path='/api-purchase-commission'
            component={APIPurchaseCommissionPage}
          />
          <ProtectedRoute
            path='/api-sales-commission'
            component={APISalesCommissionPage}
          />
          <ProtectedRoute
            path='/api-purchase'
            component={APIPurchasePage}
          />
          <ProtectedRoute
            path='/api-sales'
            component={APISalesPage}
          />
          <ProtectedRoute
            path='/api-purchase-consumption'
            component={APIPurchaseConsumptionPage}
          />
          <ProtectedRoute
            path='/api-sales-consumption'
            component={APISalesConsumptionPage}
          />
          <ProtectedRoute
            path='/api-purchase-adjustment'
            component={APIPurchaseAdjustmentPage}
          />
          <ProtectedRoute
            path='/api-sales-adjustment'
            component={APISalesAdjustmentPage}
          />
          <ProtectedRoute
            path='/supplier-api-purchase'
            component={SupplierWiseAPIPurchasePage}
          />
          <ProtectedRoute
            path='/supplier-api-purchase-detailed'
            component={SupplierWiseAPIPurchaseDetailedPage}
            />
          <ProtectedRoute
            path='/customer-api-sales'
            component={CustomerWiseAPISalesPage}
          />
          <ProtectedRoute
            path='/customer-api-sales-detailed'
            component={CustomerWiseAPISalesDetailedPage}
          />
          <ProtectedRoute
            path='/supplier-api-purchase-consumption'
            component={SupplierWiseAPIPurchaseConsumptionPage}
          />
          <ProtectedRoute
            path='/supplier-api-purchase-consumption-detailed'
            component={SupplierWiseAPIPurchaseConsumptionDetailedPage}
            />
          <ProtectedRoute
            path='/customer-api-sales-consumption'
            component={CustomerWiseAPISalesConsumptionPage}
          />
          <ProtectedRoute
            path='/customer-api-sales-consumption-detailed'
            component={CustomerWiseAPISalesConsumptionDetailedPage}
            />
          <ProtectedRoute
            path='/supplier-api-stock'
            component={SupplierWiseAPIStockPage}
          />
          <ProtectedRoute
            path='/supplier-api-stock-detailed'
            component={SupplierWiseAPIStockDetailedPage}
          />
          <ProtectedRoute
            path='/customer-api-stock'
            component={CustomerWiseAPIStockPage}
          />
          <ProtectedRoute
            path='/customer-api-stock-detailed'
            component={CustomerWiseAPIStockDetailedPage}
          />
          <ProtectedRoute
            path='/supplier-api-purchase-pending'
            component={SupplierWiseAPIPurchasePendingPage}
          />
          <ProtectedRoute
            path='/customer-api-sales-pending'
            component={CustomerWiseAPISalesPendingPage}
          />
          <ProtectedRoute
            path='/lapu-sales'
            component={SupplierWiseLapuSalesTransactionPage}
          />
          <ProtectedRoute
            path='/supplier-currency-purchase'
            component={SupplierWiseCurrencyPurchasePage}
          />
          <ProtectedRoute
            path='/supplier-currency-purchase-detailed'
            component={SupplierWiseCurrencyPurchaseDetailedPage}
          />
          <ProtectedRoute
            path='/supplier-currency-pending'
            component={SupplierWiseCurrencyPendingPage}
          />
          <ProtectedRoute
            path='/supplier-currency-pending-detailed'
            component={SupplierWiseCurrencyPendingDetailedPage}
          />
          <ProtectedRoute
            path='/supplier-sim-currency-transfer-detailed'
            component={SupplierSimWiseCurrencyTransferDetailedPage}
          />
          <ProtectedRoute
            path='/supplier-currency-sales'
            component={SupplierWiseCurrencySalesPage}
          />
          <ProtectedRoute
            path='/supplier-sim-currency-sales'
            component={SupplierWiseSimWiseCurrencySalesPage}
          />
          <ProtectedRoute
            path='/supplier-sim-daily-currency-sales'
            component={SupplierWiseSimWiseDailyCurrencySalesPage}
          />
          <ProtectedRoute
            path='/supplier-currency'
            component={SupplierWiseCurrencyPage}
          />
          <ProtectedRoute
            path='/supplier-operator-currency'
            component={SupplierWiseOperatorWiseCurrencyPage}
          />
          <ProtectedRoute
            path='/operator-currency'
            component={OperatorWiseCurrencyPage}
          />
          <ProtectedRoute
            path='/operator-supplier-currency'
            component={OperatorWiseSupplierWiseCurrencyPage}
          />
          <ProtectedRoute
            path='/lapu-currency'
            component={LapuWiseCurrencyPage}
          />
          <ProtectedRoute
            path='/lapu-daily-currency'
            component={LapuWiseDailyCurrencyPage}
          />
          <ProtectedRoute
            path='/supplier-profit'
            component={SupplierWiseProfitPage}
            />
          <ProtectedRoute
            path='/operator-profit'
            component={OperatorWiseProfitPage}
            />
          <ProtectedRoute
            path='/daily-profit'
            component={DailyProfitPage}
          />
          <ProtectedRoute
            path='/consolidated-stock'
            component={ConsolidatedStockPage}
          />
          <ProtectedRoute
            path='/settings'
            component={SettingsPage}
          />
          <ProtectedRoute
            path='/plans-list'
            component={PlansPage}
          />
          <ProtectedRoute
            path='/help'
            component={HelpPage}
          />
          <ProtectedRoute
            path='/update-company'
            component={UpdateCompanyPage}
          />
          <ProtectedRoute
            path='/compare-transactions'
            component={CompareTransactionsPage}
          />
          <ProtectedRoute
            path='/change-password'
            component={ChangePasswordPage}
          />
          <PublicRoute
            path='/forgot-password'
            component={ForgotPasswordPage}
          />
          <ProtectedRoute
            path='/faq'
            component={FAQPage}
          />
          <ProtectedRoute
            path='/terms-and-conditions'
            component={TermsNConditionsPage}
          />
          <ProtectedRoute
            path='/refund-policy'
            component={RefundPolicyPage}
          />
          <ProtectedRoute
            path='/operator-mapping'
            component={OperatorMappingMasterPage}
          />
          <ProtectedRoute
            path='/company'
            component={CompanyMasterPage}
          />
          <ProtectedRoute
            path='/plan'
            component={PlanMasterPage}
          />
          <ProtectedRoute
            path='/payment'
            component={PaymentLogPage}
          />
          <ProtectedRoute
            path='/dashboard'
            component={DashboardPage}
          />
          <ProtectedRoute
            path='*'
            component={DashboardPage}
          />
          {/* <Route exact path='/'
            render={(props) =>
              isAuthenticated ? <Redirect to='/dashboard' {...props}/> : <Redirect to='/login' />
            }
          /> */}
          {/* <Route path='/dashboard' component={DashboardPage} /> */}
          {/* <Route path='/supplier-master' component={SupplierMasterPage} /> */}
          {/* <Route path='/supplier-commission-master' component={SupplierCommMasterPage} /> */}
          {/* <Route path='/lapu-sim-master' component={LapuSimMasterPage} /> */}
          {/* <Route path='/local-market-commission-master' component={LocalMarketCommMasterPage} /> */}
          {/* <Route path='/staff-master' component={StaffMasterPage} /> */}
          {/* <Route path='/currency-purchase' component={SupplierWiseCurrencyPurchaseTransactionPage} /> */}
          {/* <Route path='/currency-opening' component={SupplierWiseCurrencyOpeningTransactionPage} /> */}
          {/* <Route path='/lapu-sales' component={SupplierWiseLapuSalesTransactionPage} /> */}
          {/* <Route path='/import-lapu-sales' component={ImportLapuSalesPage} /> */}
          {/* <Route path='/supplier-currency-purchase' component={SupplierWiseCurrencyPurchasePage} /> */}
          {/* <Route path='/supplier-currency-purchase-detailed' component={SupplierWiseCurrencyPurchaseDetailedPage} /> */}
          {/* <Route path='/supplier-currency-transfer' component={SupplierWiseCurrencyTransferPage} /> */}
          {/* <Route path='/supplier-currency-transfer-detailed' component={SupplierWiseCurrencyTransferDetailedPage} /> */}
          {/* <Route path='/supplier-sim-currency-transfer-detailed' component={SupplierSimWiseCurrencyTransferDetailedPage} /> */}
          {/* <Route path='/supplier-currency-sales' component={SupplierWiseCurrencySalesPage} /> */}
          {/* <Route path='/supplier-sim-currency-sales' component={SupplierWiseSimWiseCurrencySalesPage} /> */}
          {/* <Route path='/supplier-sim-daily-currency-sales' component={SupplierWiseSimWiseDailyCurrencySalesPage} /> */}
          {/* <Route path='/supplier-currency' component={SupplierWiseCurrencyPage} /> */}
          {/* <Route path='/supplier-operator-currency' component={SupplierWiseOperatorWiseCurrencyPage} /> */}
          {/* <Route path='/operator-currency' component={OperatorWiseCurrencyPage} /> */}
          {/* <Route path='/operator-supplier-currency' component={OperatorWiseSupplierWiseCurrencyPage} /> */}
          {/* <Route path='/lapu-currency' component={LapuWiseCurrencyPage} /> */}
          {/* <Route path='/lapu-daily-currency' component={LapuWiseDailyCurrencyPage} /> */}
          {/* <Route path='/supplier-profit' component={SupplierWiseProfitPage} /> */}
          {/* <Route path='/operator-profit' component={OperatorWiseProfitPage} /> */}
          {/* <Route path='/daily-profit' component={DailyProfitPage} /> */}
          {/* <Route path='/settings' component={SettingsPage} /> */}
          {/* <ProtectedRoute path='/' component={DashboardPage} /> */}
          {/* <Redirect from='*' to='/' /> */}
        </Switch>
      </Layout>
    </CacheBuster>
  );
}
 
export default App;
