import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AccessDenied from '../components/Wrapper/AccessDenied/AccessDenied';
import { authenticationService } from '../_services/authentication.service';

// const ProtectedRoute = ({ component: Component, ...restOfProps }) => {

//   const isAuthenticated = localStorage.getItem('accessToken');

//   return (
//     <Route
//       {...restOfProps}
//       render={(props) =>
//         isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
//       }
//     />
//   );
// }

const superuserPaths = ['/operator-mapping'];

const isLoggedIn = () => authenticationService.isLoggedIn()

export const ProtectedRoute =  ({ auth, ...props }) => {
  const isSuperuser = authenticationService.isSuperuser();
  if(isLoggedIn()) {
    return !isSuperuser && superuserPaths.includes(props.path)
      ? <AccessDenied />
      : (<Route {...props} />);
  } else {
    authenticationService.logout();
    return <Redirect to='/login' />
  }
};

export const PublicRoute = ({ auth, ...props }) => {
  return isLoggedIn()
      ? (<Redirect to='/dashboard' />)
      : (<Route {...props} />);
};
