import React, { useState, useEffect } from 'react'; 
import { FaEdit, FaPencilAlt } from 'react-icons/fa';

import { handleResponse } from '../../../../_helpers/handle-response';
import SupplierWiseLapuSalesTransactionModal from './SupplierWiseLapuSalesTransactionModal';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import CONSTANTS from '../../../../_constants/constants';
import classes from './SupplierWiseLapuSalesTransactionPage.module.css';

import Loader from '../../../UI/Loader/Loader';
import Alert from '../../../UI/Alert/Alert';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import Button from '../../../UI/Button/Button';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseLapuSalesTransactionPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [tableConfig, setTableConfig] = useState({});
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [providerId, setProviderId] = useState('-1');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalName, setModalName] = useState('');
    const [filterKeyword, setFilterKeyword] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Previous Closing', 'Previous Received', 'Previous Sales', 'Previous Incentive', 'Opening Balance', 'Received', 'Sales', 'Incentive', 'Difference(+/-)', 'Failed', 'Pending', 'Closing Balance', 'Success Count', 'Failed Count', 'Pending Count'];
    const dataFields = ['lastClosing', 'lastReceived', 'lastSales', 'lastIncentive', 'openingBalance', 'received', 'sales', 'incentive', 'difference', 'failed', 'pending', 'closingBalance', 'successCount', 'failedCount', 'pendingCount'];
    // const stickyColumnHeaders = ['Date', 'Supplier', 'Operator', 'Provider', 'Lapu Number'];
    const stickyColumnData = ['transactionDate', 'supplierName', 'operatorName', 'providerName', 'lapuNumber'];
    // const csvHeaders = ['Date', 'Transaction ID', 'Supplier', 'Operator', 'Provider', 'Lapu Number', 'Previous Closing', 'Previous Received', 'Previous Sales', 'Previous Incentive', 'Opening Balance', 'Received', 'Sales', 'Incentive', 'Difference(+/-)', 'Failed', 'Pending', 'Closing Balance', 'Success Count', 'Failed Count', 'Pending Count'];
    const csvHeaders = [
        { label: 'Date', key: 'transactionDate' },
        { label: 'Transaction ID', key: 'transactionId' },
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Provider', key: 'providerName' },
        { label: 'Lapu Number', key: 'lapuNumber' },
        { label: 'Previous Closing', key: 'lastClosing' },
        { label: 'Previous Received', key: 'lastReceived' },
        { label: 'Previous Sales', key: 'lastSales' },
        { label: 'Previous Incentive', key: 'lastIncentive' },
        { label: 'Opening Balance', key: 'openingBalance' },
        { label: 'Received', key: 'received' },
        { label: 'Sales', key: 'sales' },
        { label: 'Incentive', key: 'incentive' },
        { label: 'Difference(+/-)', key: 'difference' },
        { label: 'Failed', key: 'failed' },
        { label: 'Pending', key: 'pending' },
        { label: 'Closing Balance', key: 'closingBalance' },
        { label: 'Success Count', key: 'successCount' },
        { label: 'Failed Count', key: 'failedCount' },
        { label: 'Pending Count', key: 'pendingCount' },
    ];
    const config = {
        headerAlignConfig: {
            'Date': 'left',
            'Supplier': 'left',
            'Operator': 'center',
            'Provider': 'center',
            'Lapu Number': 'center',
            'Previous Closing': 'center',
            'Previous Received': 'center',
            'Previous Sales': 'center',
            'Previous Incentive': 'center',
            'Opening Balance': 'center',
            'Received': 'center',
            'Sales': 'center',
            'Incentive': 'center',
            'Difference(+/-)': 'center',
            'Failed': 'center',
            'Pending': 'center',
            'Closing Balance': 'center',
            'Success Count': 'center',
            'Failed Count': 'center',
            'Pending Count': 'center'
        },
        widthConfig: {
            'Date': '96px',
            'Supplier': '200px',
            'Operator': '130px',
            'Provider': '130px',
            'Lapu Number': '130px',
            'Previous Closing': '130px',
            'Previous Received': '130px',
            'Previous Sales': '130px',
            'Previous Incentive': '130px',
            'Opening Balance': '130px',
            'Received': '130px',
            'Sales': '130px',
            'Incentive': '130px',
            'Difference(+/-)': '130px',
            'Failed': '130px',
            'Pending': '130px',
            'Closing Balance': '130px',
            'Success Count': '80px',
            'Failed Count': '80px',
            'Pending Count': '80px'
        },
        alignConfig: {
            'transactionDate': 'left',
            'supplierName': 'left',
            'operatorName': 'center',
            'providerName': 'center',
            'lapuNumber': 'left', 
            'lastClosing': 'right', 
            'lastReceived': 'right',
            'lastSales': 'right',
            'lastIncentive': 'right',
            'openingBalance': 'right',
            'received': 'right',
            'sales': 'right',
            'incentive': 'right',
            'difference': 'right',
            'failed': 'right',
            'pending': 'right',
            'closingBalance': 'right',
            'successCount': 'right',
            'failedCount': 'right',
            'pendingCount': 'right'
        }
    }

    useEffect(() => { 
        setTableConfig(config);
        getSupplierList();
        getOperatorList();
        getProviderList();
        getLapuSalesData();
    }, []);

    useEffect(() => {
        setEnableExport(false);
    }, [date, supplierId, operatorId, providerId]);
    
    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (!!data.supplierName && data.supplierName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (!!data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (!!data.providerName && data.providerName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (!!data.lapuNumber && data.lapuNumber.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.lastClosing && data.lastClosing.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.lastReceived && data.lastReceived.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.lastSales && data.lastSales.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.lastIncentive && data.lastIncentive.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.openingBalance && data.openingBalance.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.received && data.received.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.sales && data.sales.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.incentive && data.incentive.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.difference && data.difference.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.failed && data.failed.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.pending && data.pending.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.closingBalance && data.closingBalance.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.successCount && data.successCount.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.failedCount && data.failedCount.toString().includes(filterKeyword.toLowerCase())) ||
            (!!data.pendingCount && data.pendingCount.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    useEffect(() => {
        setGridData(filterDataHandler(filteredData));
    }, [filteredData, filterKeyword]);

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setIsLoading(false);
                setSupplierData(result.data.results);
            } else {
                setIsLoading(false);
                setErrorMessage(result.error.message);
            }
        });
    }
    
    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setIsLoading(false);
                setOperatorData(result.data.results);
            } else {
                setIsLoading(false);
                setErrorMessage(result.error.message);
            }
        });
    }

    const getProviderList = () => {
        DropdownDataService.getProviderList('filter').then( result => {
            if(result.data) {
                setIsLoading(false);
                setProviderData(result.data.results);
            } else {
                setIsLoading(false);
                setErrorMessage(result.error.message);
            }
        });
    }

    const modalCloseHandler = (status, message) => {
        setModalIsOpen(false);
        if(status === 'save') {
            getLapuSalesData();
        } 
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value)

    const getLapuSalesData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = `${CONSTANTS.API_URL}LapuSales/AllLapuSales?transactionDate=${date}`;
        if(supplierId !== '-1') { url = url + `&supplierId=${supplierId}` }
        if(operatorId !== '-1') { url = url + `&operatorId=${operatorId}` }
        if(providerId !== '-1') { url = url + `&providerId=${providerId}` }
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.transactionDate = data.transactionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    let keysToMap = ['lastClosing', 'lastReceived', 'lastSales', 'lastIncentive', 'openingBalance', 'received', 'sales', 'incentive', 'difference', 'failed', 'pending', 'closingBalance'];
                    result.data.results.map( data => {
                        return Object.entries(data).forEach( ([key, value]) => {
                            data[key] = keysToMap.includes(key) ? value.toFixed(2) : value
                        });
                    });
                    setGridData(result.data.results);
                    setFilteredData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    const totals = result.data.total[0];
                    setTotalData([
                        '',
                        '',
                        '',
                        '',
                        (totals.lastClosing).toFixed(2),
                        (totals.lastReceived).toFixed(2),
                        (totals.lastSales).toFixed(2),
                        (totals.lastIncentive).toFixed(2),
                        (totals.openingBalance).toFixed(2),
                        (totals.received).toFixed(2),
                        (totals.sales).toFixed(2),
                        (totals.incentive).toFixed(2),
                        (totals.difference).toFixed(2),
                        (totals.failed).toFixed(2),
                        (totals.pending).toFixed(2),
                        (totals.closingBalance).toFixed(2),
                        totals.successCount,
                        totals.failedCount,
                        totals.pendingCount,
                        ''
                    ]);
                    setExportTotalData({
                        transactionDate: 'Total',
                        transactionId: '',
                        supplierName: '',
                        operatorName: '',
                        providerName: '',
                        lapuNumber: '',
                        lastClosing: (totals.lastClosing).toFixed(2),
                        lastReceived: (totals.lastReceived).toFixed(2),
                        lastSales: (totals.lastSales).toFixed(2),
                        lastIncentive: (totals.lastIncentive).toFixed(2),
                        openingBalance: (totals.openingBalance).toFixed(2),
                        received: (totals.received).toFixed(2),
                        sales: (totals.sales).toFixed(2),
                        incentive: (totals.incentive).toFixed(2),
                        difference: (totals.difference).toFixed(2),
                        failed: (totals.failed).toFixed(2),
                        pending: (totals.pending).toFixed(2),
                        closingBalance: (totals.closingBalance).toFixed(2),
                        successCount: totals.successCount,
                        failedCount: totals.failedCount,
                        pendingCount: totals.pendingCount
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const dateChangeHandler = event => setDate(event.target.value)

    const supplierChangeHandler = event => setSupplierId(event.target.value)

    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const providerChangeHandler = event => setProviderId(event.target.value)

    const modalOpenHandler = (modalName, index) => {
        setModalName(modalName);
        setModalIsOpen(true);
        setModalData(gridData[index]);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const showClickHandler = () => {
        setIsLoading(true);
        getLapuSalesData();
    }

    const getExportFileName = () => {
        let exportFilename = 'LapuSales';
        exportFilename += supplierId !== '-1' ? '_' + supplierData.find(data => data.supplierId == supplierId)['supplierName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        exportFilename += providerId !== '-1' ? '_' + providerData.find(data => data.providerId == providerId)['providerName'] : '';
        exportFilename += '_' + date;
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Lapu Sales'>
                    <div className='row mb-2'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={date} onChange={dateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Supplier : </label>
                                    <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={supplierChangeHandler} value={supplierId}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} className='dropdownItem' value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={operatorChangeHandler} value={operatorId}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} className='dropdownItem' value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Provider : </label>
                                    <select className='form-select customSelect' aria-label=".form-select-sm example" onChange={providerChangeHandler} value={providerId}>
                                        { providerData.map( (value, index) => {
                                            return <option key={`provider-${index}`} className='dropdownItem' value={value.providerId}>{value.providerName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' btnTitle='Show' />
                                { modalIsOpen && <SupplierWiseLapuSalesTransactionModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                /> }
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <div className={classes.tableContainer} style={{height: gridData.length > 0 ? '60vh': 'auto'}}>
                        <table className={`table table-bordered ${classes.lapuTable}`}>
                            <thead className={classes.header}>
                                <tr>
                                    <th scope='col' className={`${classes.thead} ${classes.stickyCol} ${classes.stickyIndexCol}`} style={{textAlign: 'center'}}>#</th>
                                    <th 
                                        className={`${classes.stickyCol} ${classes.firstStickyCol} ${classes.thead}`}
                                        style={{ 
                                            width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Date'],
                                            minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Date'],
                                            textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig']['Date'],
                                            overflowWrap: 'break-word'
                                        }}
                                    >
                                        Date
                                    </th>
                                    <th 
                                        className={`${classes.stickyCol} ${classes.secondStickyCol} ${classes.thead}`}
                                        style={{ 
                                            width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Supplier'],
                                            minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Supplier'],
                                            textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig']['Supplier']
                                        }}
                                    >
                                        Supplier
                                    </th>
                                    <th 
                                        className={`${classes.stickyCol} ${classes.thirdStickyCol} ${classes.thead}`}
                                        style={{ 
                                            width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Operator'],
                                            minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Operator'],
                                            textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig']['Operator']
                                        }}
                                    >
                                        Operator
                                    </th>
                                    <th 
                                        className={`${classes.stickyCol} ${classes.fourthStickyCol} ${classes.thead}`}
                                        style={{ 
                                            width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Provider'],
                                            minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Provider'],
                                            textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig']['Provider']
                                        }}
                                    >
                                        Provider
                                    </th>
                                    <th 
                                        className={`${classes.stickyCol} ${classes.fifthStickyCol} ${classes.thead}`}
                                        style={{ 
                                            width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Lapu Number'],
                                            minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig']['Lapu Number'],
                                            textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig']['Lapu Number']
                                        }}
                                    >
                                        Lapu Number
                                    </th>
                                    { tableHeaders.map( (header, i) => {
                                        return (
                                            <th 
                                                key={`header-${i}`} 
                                                className={classes.thead}
                                                style={{ 
                                                    width: (!!tableConfig['widthConfig']) && tableConfig['widthConfig'][header],
                                                    minWidth: (!!tableConfig['widthConfig']) && tableConfig['widthConfig'][header],
                                                    textAlign: (!!tableConfig['headerAlignConfig']) && tableConfig['headerAlignConfig'][header]
                                                }}
                                            >
                                                {header}
                                            </th>
                                        );
                                    })}
                                    <th scope='col' className={classes.thead} style={{textAlign: 'center'}}>Actions</th>
                                </tr>
                                { gridData.length > 0 && totalData &&
                                    (<tr key='totals' className={classes.totalRow}>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.stickyIndexCol}`}></td>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.firstStickyCol}`} style={{textAlign: 'left'}}>Total</td>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.secondStickyCol}`}>{totalData[0]}</td>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.thirdStickyCol}`}>{totalData[1]}</td>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.fourthStickyCol}`}>{totalData[2]}</td>
                                        <td className={`${classes.totalRowData} ${classes.stickyCol} ${classes.fifthStickyCol}`}>{totalData[3]}</td>
                                        { [4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map( index =>
                                            <td className={classes.totalRowData}>{totalData[index]}</td>
                                            )
                                        }
                                    </tr>)
                                }
                            </thead>
                            <tbody>
                                { gridData.length === 0 && (
                                    <tr className={classes.noDataRow}>
                                        <td colSpan={dataFields.length + stickyColumnData + 2} className={classes.noData}>
                                            Data not found.
                                        </td>
                                    </tr>
                                )}
                                {/* { gridData.length > 0 && totalData &&
                                    (<tr key='totals' className={classes.totalRow} style={{position: 'sticky', top: '38px'}}>
                                        <th scope='row' key='total' style={{textAlign: 'right', position: 'sticky', top: '38px'}}>Total</th>
                                        { totalData.map((total, index) => {
                                            return (
                                                <td key={`${'total-' + index}`} style={{textAlign: 'right', position: 'sticky', top: '38px'}}>{total}</td>
                                            )
                                        })}
                                    </tr>)
                                } */}
                                { gridData.map((tableData, index) => {
                                    return (
                                        <tr key={index} className={classes.tableRow}>
                                            <td key={index} style={{textAlign: 'right'}} className={`${classes.stickyCol} ${classes.stickyIndexCol}`}>{index+1}</td>
                                            <td
                                                className={`${classes.stickyCol} ${classes.firstStickyCol}`}
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig['transactionDate']) && tableConfig.alignConfig['transactionDate']
                                                }}
                                            >
                                                {tableData['transactionDate']}
                                            </td>
                                            <td
                                                className={`${classes.stickyCol} ${classes.secondStickyCol}`}
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig['supplierName']) && tableConfig.alignConfig['supplierName']
                                                }}
                                            >
                                                {tableData['supplierName']}
                                            </td>
                                            <td 
                                                className={`${classes.stickyCol} ${classes.thirdStickyCol}`}
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig['operatorName']) && tableConfig.alignConfig['operatorName']
                                                }}
                                            >
                                                {tableData['operatorName']}
                                            </td>
                                            <td 
                                                className={`${classes.stickyCol} ${classes.fourthStickyCol}`}
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig['providerName']) && tableConfig.alignConfig['providerName']
                                                }}
                                            >
                                                {tableData['providerName']}
                                            </td>
                                            <td 
                                                className={`${classes.stickyCol} ${classes.fifthStickyCol}`}
                                                style={{
                                                    textAlign: (!!tableConfig.alignConfig['lapuNumber']) && tableConfig.alignConfig['lapuNumber']
                                                }}
                                            >
                                                {tableData['lapuNumber']}
                                            </td>
                                            { dataFields.map( (field, index) => 
                                                <td 
                                                    key={`${'field-' + index}`} 
                                                    // className={stickyColumnData.includes(field) ? `${classes.stickyCol}` : ''}
                                                    style={{
                                                        textAlign: (!!tableConfig.alignConfig[field]) && tableConfig.alignConfig[field]
                                                    }}
                                                >
                                                    {tableData[field]}
                                                </td> )
                                            }
                                            <td>
                                                <span className={classes.actionBtn}>
                                                    <FaEdit 
                                                        title='Previous Edit' 
                                                        style={{
                                                            color: '#66CD00',
                                                            // opacity: tableData.openingBalance === tableData.closingBalance ? '0.5' : '1',
                                                            // cursor: tableData.openingBalance === tableData.closingBalance ? 'auto' : 'pointer',
                                                        }} 
                                                        onClick={e => modalOpenHandler('Previous', index)}
                                                    />
                                                </span>
                                                <span className={classes.actionBtn}>
                                                    <FaPencilAlt title='Current Edit' style={{color: '#ffc107'}} onClick={e => modalOpenHandler('Current', index)} />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </PageLayout>
            )}
        </>
    );
  
}

export default SupplierWiseLapuSalesTransactionPage;