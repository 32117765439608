import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import classes from './CustomerModal.module.css';
import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const CustomerModal = props => {

    const [customerName, setCustomerName] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    useEffect(() => {
        setErrorMessage(null);
        return () => {
            setCustomerName('');
            setHasError(false);
        }
    }, []);

    const customStyles = {
        content: {
            top: '35%',
            left: '50%',
            right: 'auto',
            width: '30%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const afterOpenModal = event => {
        setHasError(false);
        setCustomerName(Object.keys(props.modalData).length > 0 ? props.modalData.customerName : '');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setHasError(false);
        props.onCloseModal('cancel');
    }

    const customerChangeHandler = event => {
        if(event.target.value !== '') {
            setHasError(false);
        }
        setCustomerName(event.target.value);
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const addData = (url, reqBody) => {
        ApiService.postData(url, reqBody).then(handleApiResponse);
    }

    const editData = (url, reqBody) => {
        ApiService.updateData(url, reqBody).then(handleApiResponse);
    }

    const deleteData = (url, reqBody) => {
        ApiService.deleteData(url, reqBody).then(handleApiResponse);
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Customer/`;
        if(customerName === '' ) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            if(props.modalName === 'Add') {
                reqBody = {customerName};
                url = 'AddCustomer';
                addData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Edit') {
                reqBody = {customerName};
                url = `UpdateCustomer?customerId=${props.modalData.customerId}`;
                editData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Delete') {
                reqBody = props.modalData;
                url = `DeleteCustomer?customerId=${props.modalData.customerId}`;
                deleteData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Activate') {
                url = `ActivateCustomer?customerId=${props.modalData.customerId}`;
                editData(apiUrl+url);
            } else if(props.modalName === 'Deactivate') {
                url = `DeActivateCustomer?customerId=${props.modalData.customerId}`;
                editData(apiUrl+url);
            }
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
                <div className='modal-header'>
                    <h4>{props.modalName} Customer</h4>
                    <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
                </div>
                <div className='modal-body'>
                    <div className='container'>
                        { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                        { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                            <form>
                                <div className={`input-group has-validation ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Customer : </label>
                                    <input type='text' className={`form-control ${hasError && customerName === '' ? 'is-invalid': ''}`} value={customerName} onChange={customerChangeHandler} autoFocus/>
                                    <div className={`invalid-feedback ${classes.feedback}`}>Please enter Customer name.</div>
                                </div>
                            </form>
                        }
                        { (props.modalName === 'Delete' || props.modalName === 'Activate' || props.modalName === 'Deactivate') && 
                            <div className={classes.text}>Do you want to {props.modalName} Customer <b>({props.modalData.customerName})</b>?</div>
                        }
                    </div>
                </div>
                <div className='modal-footer' style={{textAlign: 'right'}}>
                    <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Add' || props.modalName === 'Edit' ? 'Cancel' : 'No' } btnTitle={props.modalName === 'Add' || props.modalName === 'Edit' ? 'Cancel' : 'No' } />
                    <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Add' || props.modalName === 'Edit' ? 'Save' : 'Yes' } btnTitle={props.modalName === 'Add' || props.modalName === 'Edit' ? 'Save' : 'Yes' } />
                </div>
            </ReactModal>
        </div>
    );

}

export default React.memo(CustomerModal);