import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierSimWiseCurrencyTransferDetailedPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    const tableHeaders = ['Supplier', 'Operator', 'Lapu Number', 'Transfer Date', 'Transferred'];
    const dataFields = ['supplierName', 'operatorName', 'lapuNumber', 'transferDate', 'totalTransfer'];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left', 
            'Operator': 'center', 
            'Lapu Number': 'center', 
            'Transfer Date': 'center', 
            'Transferred': 'center'
        },
        widthConfig: {
            'Supplier': 'auto', 
            'Operator': '154px', 
            'Lapu Number': '154px', 
            'Transfer Date': '154px', 
            'Transferred': '154px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'lapuNumber': 'left',
            'transferDate': 'center',
            'totalTransfer': 'right'
        }
    };

    useEffect(() => { 
        getTransferDetailedData();
    }, []);

    const getTransferDetailedData = () => {
        const requestOptions = {
            headers: {
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        let supplierId = window.supplierId;
        let operatorId = window.operatorId;
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseCurrencyTransferDetailed?transDate=${window.transDate}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseCurrencyTransferDetailed?transDate=${window.transDate}&operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseCurrencyTransferDetailed?transDate=${window.transDate}&supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseCurrencyTransferDetailed?transDate=${window.transDate}&supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.transferDate = data.transferDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['totalTransfer'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['transferAmount'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        '',
                        total.transferAmount
                    ]);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }
    
    return (
        <>
            { isLoading && <Loader /> }
            { !isLoading && (
                <PageLayout pageTitle='Supplier Wise Sim Wise Currency Transfer Detailed'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            ) }    
        </>
    );
  
}

export default SupplierSimWiseCurrencyTransferDetailedPage;