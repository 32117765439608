import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import classes from './SupplierWiseAPIPurchasePendingModal.module.css';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecimalInput, validateDecInputTo3Digits } from '../../../../_helpers/validators';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const SupplierWiseAPIPurchasePendingModal = props => {

    const [supplierId, setSupplierId] = useState('-2');
    const [percentageTypeId, setPercentageTypeId] = useState('-2');
    const [percentageTypeName, setPercentageTypeName] = useState('');
    const [commissionRate, setCommissionRate] = useState();
    const [netCurrency, setNetCurrency] = useState();
    const [supplierData, setSupplierData] = useState([]);
    const [percentageTypeData, setPercentageTypeData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        getSupplierList();
        getCommissionType();
        return () => {
            setSupplierId('');
            setPercentageTypeId();
            setCommissionRate();
            setNetCurrency('');
        }
    }, []);

    useEffect(() => {
        setCommissionRate(percentageTypeName === 'ADD ON' ? (0).toFixed(3) : commissionRate);
    }, [percentageTypeName]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          width: '35%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal', 'ApiPurchase').then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    setSupplierId((!!props.modalData.supplierId) && props.modalData.supplierId);
                }
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getCommissionType = () => {
        DropdownDataService.getCommissionType('modal').then( result => {
            if(result.data) {
                setPercentageTypeData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = () => {
        setSupplierId(!!props.modalData.supplierId ? props.modalData.supplierId : '-2');
        setCommissionRate(!!props.modalData.commissionRate ? props.modalData.commissionRate : (0).toFixed(3));
        setPercentageTypeId(!!props.modalData.percentageTypeId ? props.modalData.percentageTypeId : '-2');
        setPercentageTypeName(!!props.modalData.percentageTypeName ? props.modalData.percentageTypeName : '');
        setNetCurrency(!!props.modalData.netCurrency ? props.modalData.netCurrency : (0).toFixed(2));
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel');
    }

    const clearMessages = () => {
        setErrorMessage(null);
        // setHasError(false);
    }
    
    const supplierChangeHandler = event => {
        clearMessages();
        setSupplierId(event.target.value);
    }
    
    const commTypeChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        setPercentageTypeId(value);
        const commTypeName = percentageTypeData.find(data => data.percentageTypeId == value)['percentageTypeName'];
        setPercentageTypeName(commTypeName);
    }
    
    const commissionRateChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value === '') {
            setCommissionRate(value);
        } else {
            if(validateDecInputTo3Digits(value)) {
                setCommissionRate(value);
            }
        }
    }

    const netCurrencyChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value === '') {
            setNetCurrency(value);
        } else {
            if(validateDecimalInput(value)) {
                setNetCurrency(value);
            }
        }
    }
    
    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = 'https://royalinfo.in/api/pavathi/ApiPurchaseOpening/' + url;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (supplierId === '-2' || percentageTypeId === '-2' || commissionRate === '' || netCurrency <= 0 )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                reqBody = {
                    supplierId,
                    percentageTypeId,
                    commissionRate: parseFloat(commissionRate),
                    netCurrency: Number(parseFloat(netCurrency).toFixed(2))
                };
                url = 'AddApiPurchaseOpening';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                reqBody = {
                    supplierId,
                    percentageTypeId,
                    commissionRate: parseFloat(commissionRate),
                    netCurrency: Number(parseFloat(netCurrency).toFixed(2))
                };
                url = `UpdateApiPurchaseOpening?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteApiPurchaseOpening?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} API Purchase Pending</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Supplier.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && percentageTypeId === '-2' ? 'is-invalid': ''}`} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                        { percentageTypeData.map( (value, index) => {
                                            return <option key={`commType-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Commission Type.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled={percentageTypeName === 'ADD ON'} className={`form-control inputField ${hasError && commissionRate === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={commissionRate} onChange={commissionRateChangeHandler} onBlur={e=>setCommissionRate(onBlurHandler(e, 3))} />
                                    <div className='invalid-feedback'>Please enter Commission %.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Net Pending : </label>
                                <div className='col-sm-8'>
                                    <input type='text' style={{textAlign:'right'}} className={`form-control inputField ${hasError && netCurrency <= 0 ? 'is-invalid': ''}`} value={netCurrency} onChange={netCurrencyChangeHandler} onBlur={ e => setNetCurrency(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Please enter Net Pending greater than 0.</div>
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete API Purchase Pending?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } btnTitle={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } btnTitle={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default SupplierWiseAPIPurchasePendingModal;