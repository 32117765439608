import Alert from '../Alert/Alert';

const ModalBody = props => {
    return (
        <div className='modal-body'>
            <div className='container'>
                {props.errorMessage && <Alert alertClassName='danger' message={props.errorMessage} />}
                {props.children}
            </div>
        </div>
    )
}

export default ModalBody;