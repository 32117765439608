import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';

import CONSTANTS from '../../../../_constants/constants';
import { handleResponse } from '../../../../_helpers/handle-response';
import { validateOTPInput, validateMobileNoInput } from '../../../../_helpers/validators';
import AuthImage from '../AuthImage/AuthImage';
import { authenticationService } from '../../../../_services/authentication.service';
import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

import classes from './SignUpPage.module.css';

const SignUpPage = () => {

    const history = useHistory();

    const [companyName, setCompanyName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [OTP, setOTP] = useState('');
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [error, setError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [appLogoProps, setAppLogoProps] = useState({});
    const [authImageProps, setAuthImageProps] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => getCompanyDetails(), []);

    const getCompanyDetails = () => {
        authenticationService.masterLogin()
        .then( result => {
            if(result.data) {
                const { appLogoImage, desktopImage, appName } = result.data;
                setAppLogoProps({ appLogoImage, appName });
                setAuthImageProps({ desktopImage, appName });
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        },
        error => setErrorMessage('Something went wrong!'))
    }

    const submitClickHandler = e => {
        e.preventDefault();
        setErrorMessage(null);
        if(newPassword !== confirmPassword) {
            setErrorMessage('New and Confirm Passwords must be same');
        } else {
            let apiUrl = `${CONSTANTS.API_URL}Authentication/CreateCompany`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    companyCode: mobileNumber,
                    companyName, 
                    password: newPassword,
                    OTP,
                    clientId: authenticationService.getClientId()
                })
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        setSuccessMessage('Account created successfully! Click Return to Login button below to login to your account.');
                    } else if(result.error) {
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        }
    }

    const validateMobileNumber = e => {
        e.preventDefault();
        setErrorMessage(null);
        let apiUrl = `${CONSTANTS.API_URL}Authentication/ValidateRegisterMobileNo`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ companyName, mobileNumber, clientId: authenticationService.getClientId() })
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setShowAdditionalFields(true);
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const resendOTPClickHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Authentication/ReSendOTP`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({  mobileNumber, clientId: authenticationService.getClientId() })
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const loginClickHandler = () => {
        history.push('/login');
    }

    const mobileNumberChangeHandler = e => {
        const value = e.target.value;
        if(validateMobileNoInput(value) || value === '') {
            setMobileNumber(value);
            setShowAdditionalFields(false);
        }
    }

    const otpChangeHandler = e => {
        const value = e.target.value;
        if(validateOTPInput(value) || value === '') {
            setOTP(value);
        }
    }

    const newPasswordChangeHandler = e => {
        setErrorMessage(null);
        setNewPassword(e.target.value);
    }
    
    const confirmPasswordChangeHandler = e => {
        setErrorMessage(null);
        setConfirmPassword(e.target.value);
    }

    return (
        <div className={`${classes.authBg} m-0 p-0`} data-layout-config='{"darkMode":false}'>

        <div className={classes.authFluid}>

            <AuthImage {...authImageProps} />
            
            <div className={classes.authFluidFormBox}>
                <div className={`${classes.alignItemsCenter} d-flex`}>
                    <div className="card-body">
                        <div className={`${classes.authBrand} mb-2`}>
                            <a href="#" className={classes.logoLight}>
                                <span><img src={appLogoProps.appLogoImage} alt={appLogoProps.appName} height="56" /></span>
                            </a>
                        </div>

                        { !successMessage &&
                            <>
                                <h4 className="mt-0">Sign Up</h4>
                                <p className="text-muted mb-3">Please fill in below details to create your account.</p>

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="company">Company Name</label>
                                        <input className="form-control" type="text" id="company" required placeholder="Enter Company Name" onChange={e => setCompanyName(e.target.value)} autoFocus />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                        <input className="form-control" disabled={showAdditionalFields} type="text" required id="mobileNumber" placeholder="Enter Mobile Number" onChange={mobileNumberChangeHandler} value={mobileNumber} />
                                        { showAdditionalFields && <p className="text-muted float-right" onClick={e=>setShowAdditionalFields(false)} style={{textDecoration: 'underline', display:'inline', cursor: 'pointer'}}><small>Edit Mobile Number</small></p> }
                                    </div>
                                    { !showAdditionalFields && 
                                        <div className="form-group mb-0 text-center mt-3">
                                            <Button btnClass='btn btn-primary btn-block' onClick={validateMobileNumber} btnText='Next' />
                                        </div> 
                                    }
                                    { showAdditionalFields &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input className="form-control" type="text" id="username" readOnly value='Admin' />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='new-password'>New Password : </label>
                                                <div className='input-group'>
                                                    <input type={showNewPassword ? 'text' : 'password'} id='new-password' className='form-control' value={newPassword} onChange={newPasswordChangeHandler} />
                                                    <div className='input-group-addon'>
                                                        <div className='input-group-text'>
                                                            <span>
                                                                { showNewPassword && <FaEyeSlash title='Hide Password' onClick={e=>setShowNewPassword(false)}/> }
                                                                { !showNewPassword && <FaRegEye title='Show Password' onClick={e=>setShowNewPassword(true)} /> }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='confirm-password'>Confirm Password : </label>
                                                <div className='input-group'>
                                                    <input type='password'  type={showConfirmPassword ? 'text' : 'password'} id='confirm-password' className='form-control' value={confirmPassword} onChange={confirmPasswordChangeHandler} />
                                                    <div className='input-group-addon'>
                                                        <div className='input-group-text'>
                                                            <span>
                                                                { showConfirmPassword && <FaEyeSlash title='Hide Password' onClick={e=>setShowConfirmPassword(false)}/> }
                                                                { !showConfirmPassword && <FaRegEye title='Show Password' onClick={e=>setShowConfirmPassword(true)} /> }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="otp">OTP</label>
                                                <input className="form-control" type="text" maxLength='6' onChange={otpChangeHandler} value={OTP} required id="otp" placeholder="Enter OTP"/>
                                                <p className="text-muted float-right" onClick={resendOTPClickHandler} style={{textDecoration: 'underline', display:'inline', cursor: 'pointer'}}><small>Resend OTP</small></p>
                                            </div>
                                            
                                            <div className="form-group mb-0 text-center">
                                                <Button btnClass='btn btn-primary btn-block' onClick={submitClickHandler} btnText='Submit' />
                                            </div>
                                        </>
                                    }
                                </form>
                                { 
                                    errorMessage && <div className={`mt-3 ${classes.errorContainer}`}>
                                       <div className={`${'alert alert-danger'} ${classes.errorMessage}`} role="alert">{errorMessage}</div>
                                    </div>
                                }
                                {/* { 
                                    successMessage && <div className={`mt-3 ${classes.errorContainer}`}>
                                       <div className={`${'alert alert-success'} ${classes.errorMessage}`} role="alert">{successMessage}</div>
                                    </div>
                                } */}
                                {/* { 
                                    errorMessage && <div className={`mt-3 ${classes.errorContainer}`}>
                                        { error && <div className={`${'alert alert-danger'} ${classes.errorMessage}`} role="alert">{errorMessage}</div> }
                                    </div>
                                } */}
                                {/* { errorMessage && <Alert className='mt-3' alertClassName='danger' message={errorMessage} /> } */}
                            </>
                        }
                        { successMessage && 
                            <div className='mt-4'>
                                <Alert alertClassName='success' message={successMessage} />
                                <div className="form-group mb-0 text-center">
                                    <Button btnClass='btn btn-primary btn-block' onClick={loginClickHandler} btnText='Return to Login' />
                                </div>
                            </div>
                        }
                        { !successMessage && 
                            <footer className={`${classes.footer} ${classes.footerAlt}`}>
                                <span className="text-muted">Already have an account?
                                    <p onClick={loginClickHandler} className="authPageLink">Login</p>
                                </span>
                            </footer>
                        }

                    </div>
                </div>
            </div>
            
        </div>

        <script src="assets/js/vendor.min.js"></script>
        <script src="assets/js/app.min.js"></script>

    </div>
    );
}

export default SignUpPage;