import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import APISalesModal from './APISalesModal';
import { parseFloat2Decimal, uiDateToday, uiMonthStartDate } from '../../../../_helpers/utils';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import classes from './APISalesPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const APISalesPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [fromDate, setFromDate] = useState(uiMonthStartDate());
    const [toDate, setToDate] = useState(uiDateToday());
    const [customerId, setCustomerId] = useState('-1');
    const [customerData, setCustomerData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Date', 'Customer', 'Purchase Amount', 'Commission Type', 'Commission %', 'Commission', 'Net Received'];
    const dataFields = ['transactionDate', 'customerName', 'netAmount', 'percentageTypeName', 'commissionRate', 'commission', 'netCurrency'];
    const csvHeaders = [
        { label: 'Date', key: 'transactionDate' },
        { label: 'Customer', key: 'customerName' },
        { label: 'Purchase Amount', key: 'netAmount' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Net Received', key: 'netCurrency' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'left',
            'Customer': 'left',
            'Purchase Amount': 'center',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Net Received': 'center'
        },
        widthConfig: {
            'Date': '96px',
            'Customer': '300px',
            'Purchase Amount': '154px',
            'Commission Type': '154px',
            'Commission %': '154px',
            'Commission': '154px',
            'Net Received': '154px'
        },
        alignConfig: {
            'transactionDate': 'left',
            'customerName': 'left',
            'netAmount': 'right', 
            'percentageTypeName': 'center', 
            'commissionRate': 'right',
            'commission': 'right',
            'netCurrency': 'right'
        }
    }

    useEffect(() => {
        getCustomerList();
        getCurrencyPurchaseData();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate, customerId]);

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('filter').then( result => {
            if(result.data) {
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.transactionDate && data.transactionDate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.customerName && data.customerName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.netAmount && data.netAmount.toString().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.commission && data.commission.toString().includes(filterKeyword.toLowerCase())) ||
            (data.netCurrency && data.netCurrency.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    const modalCloseHandler = (status, message) => {
        setModalIsOpen(false);
        if(status === 'save') {
            getCurrencyPurchaseData();
        }
    }

    const getCurrencyPurchaseData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        url = customerId === '-1' ?
                `${CONSTANTS.API_URL}ApiSales/AllApiSales?startDate=${fromDate}&endDate=${toDate}` :
                `${CONSTANTS.API_URL}ApiSales/AllApiSales?startDate=${fromDate}&endDate=${toDate}&customerId=${customerId}`;
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.transactionDate = data.transactionDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['netAmount', 'commission', 'netCurrency'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    result.data.results.map( d => d.commissionRate = d.commissionRate.toFixed(3));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    const totals = result.data.total[0];
                    setTotalData([
                        '',
                        (totals.netAmount).toFixed(2),
                        '',
                        '',
                        (totals.commission).toFixed(2),
                        (totals.netCurrency).toFixed(2),
                        ''
                    ]);
                    setExportTotalData({
                        transactionDate: 'Total',
                        customerName: '',
                        netAmount: (totals.netAmount).toFixed(2),
                        percentageTypeName: '',
                        commissionRate: '',
                        commission: (totals.commission).toFixed(2),
                        netCurrency: (totals.netCurrency).toFixed(2)
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        setModalData(modalData === '' ? {}: modalData);
    }
    
    const handleAfterOpen = (event, data) => console.log(event, data)

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const showClickHandler = () => {
        setIsLoading(true);
        getCurrencyPurchaseData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)
    
    const customerChangeHandler = event => setCustomerId(event.target.value)
    
    const getExportFileName = () => {
        let exportFilename = 'APISales';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += customerId !== '-1' ? '_' + customerData.find(data => data.customerId == customerId)['customerName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='API Sales'>
                    <div className='row mb-1'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add API Sales' btnTitle='Add API Sales' btnType='Add' />
                                { modalIsOpen && <APISalesModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                /> }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Customer : </label>
                                    <select className='form-select customSelect' aria-label='.form-select-sm example' onChange={customerChangeHandler} value={customerId}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} className='dropdownItem' value={value.customerId}>{value.customerName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' />
                                <ExportToCSV
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        paginate={true}
                        filter={true}
                        rowClick={false}
                        gridData={gridData}
                        totalData={totalData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default APISalesPage;