import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecInputTo3Digits } from '../../../../_helpers/validators';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import Modal from '../../../UI/Modal/Modal';
import { ApiService } from '../../../../_services/api.service';
import classes from './SupplierCommissionModal.module.css';

const SupplierCommissionModal = props => {

    const [supplierId, setSupplierId] = useState('-2');
    const [supplierName, setSupplierName] = useState('');
    const [operatorId, setOperatorId] = useState('-2');
    const [operatorName, setOperatorName] = useState('');
    const [percentageTypeName, setPercentageTypeName] = useState('');
    const [percentageTypeId, setPercentageTypeId] = useState('');
    const [commissionRate, setCommissionRate] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [percentageTypeData, setPercentageTypeData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Supplier Commission`;

    useEffect(() => {
        getSupplierList();
        getOperatorList();
        getCommissionType();
        return () => setCommissionRate('')
    }, [props.modalName]);

    useEffect(() => {
        setErrorMessage(null);
    }, [supplierId, operatorId, commissionRate])

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('modal').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const getCommissionType = () => {
        DropdownDataService.getCommissionType('modal').then( result => {
            if(result.data) {
                setPercentageTypeData(result.data.results);
                const percTypeId = result.data.results.find(data => data.percentageTypeName == 'DIRECT')['percentageTypeId'];
                setPercentageTypeId(percTypeId);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = event => {
        setSupplierId(props.modalData.supplierId !== '-1' ? props.modalData.supplierId : '-2');
        setSupplierName(!!props.modalData.supplierName ? props.modalData.supplierName : '');
        setOperatorId(!!props.modalData.operatorId && props.modalData.operatorId !== '-1' ? props.modalData.operatorId : '-2');
        setOperatorName(!!props.modalData.operatorName ? props.modalData.operatorName : '');
        setCommissionRate(props.modalData.commissionRate ? props.modalData.commissionRate : '');
        setPercentageTypeName(props.modalData.percentageTypeName && (props.modalData.percentageTypeName !== '') ? props.modalData.percentageTypeName : '');
        setPercentageTypeId(props.modalData.percentageTypeId && (props.modalData.percentageTypeId !== '-1') ? props.modalData.percentageTypeId : '-2');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = evt => props.onCloseModal('cancel');
    
    // Change Handlers
    const supplierChangeHandler = event => setSupplierId(event.target.value)
    const operatorChangeHandler = event => setOperatorId(event.target.value)
    const commRateChangeHandler = event => {
        const value = event.target.value;
        if(validateDecInputTo3Digits(value)) {
            setCommissionRate(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    // API functions
    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}LapuInput/`;
        if(props.modalName !== 'Delete' && (supplierId === '-2' || operatorId === '-2'|| percentageTypeId === '' || commissionRate === '' )) {
            setHasError(true);
        } else {
            setHasError(false);
            if(props.modalName === 'Add') {
                ApiService.postData(`${apiUrl}AddLapuInput`, { supplierId, operatorId, percentageTypeId, commissionRate: parseFloat(commissionRate) })
                    .then(handleApiResponse);
            } else if(props.modalName === 'Edit') {
                ApiService.updateData(`${apiUrl}UpdateLapuInput?lapuInputId=${props.modalData.lapuInputId}`, { supplierId, operatorId, percentageTypeId, commissionRate: parseFloat(commissionRate) })
                    .then(handleApiResponse);
            } else if(props.modalName === 'Delete') {
                ApiService.deleteData(`${apiUrl}DeleteLapuInput?lapuInputId=${props.modalData.lapuInputId}`, props.modalData)
                    .then(handleApiResponse);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            style={{top: '47%', width: '38%'}}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            labelWidth={4}
            fieldWidth={8}
        >
            {props.modalName !== 'Delete' &&
                <form>
                    <div className={`form-group row has-validation ${classes.inputContainer}`}>
                        <label className='col-sm-4 col-form-label inputLabel'>Supplier :</label>
                        <div className='col-sm-8'>
                            <select className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                { supplierData.map( (value, index) => {
                                    return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                }) }
                            </select>
                            <div className='invalid-feedback'>Please select a Supplier.</div>
                        </div>
                    </div>
                    <div className={`form-group row has-validation ${classes.inputContainer}`}>
                        <label className='col-sm-4 col-form-label inputLabel'>Operator :</label>
                        <div className='col-sm-8'>
                            <select className={`form-select inputField ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} disabled={props.modalName ==='Edit'} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                { operatorData.map( (value, index) => {
                                    return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                }) }
                            </select>
                            <div className='invalid-feedback'>Please select an Operator.</div>
                        </div>
                    </div>
                    <div className={`form-group row has-validation ${classes.inputContainer}`}>
                        <label className='col-sm-4 col-form-label inputLabel'>Commission Type :</label>
                        <div className='col-sm-8'>
                            <input type='text' readOnly className='form-control inputField' value='DIRECT' />
                        </div>
                    </div>
                    <div className={`form-group row has-validation ${classes.inputContainer}`}>
                        <label className='col-sm-4 col-form-label inputLabel'>Commission % :</label>
                        <div className='col-sm-8'>
                            <input type='text' className={`form-control inputField ${hasError && commissionRate === '' ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={commissionRate} onChange={commRateChangeHandler} onBlur={ e => setCommissionRate(onBlurHandler(e, 3))} />
                            <div className='invalid-feedback'>Please enter Commission Rate.</div>
                        </div>
                    </div>
                </form>
            }
            { props.modalName === 'Delete' && 
                <div className={classes.text}>Do you want to Delete Supplier Commission?</div>
            }
        </Modal>
    );

}

export default React.memo(SupplierCommissionModal);