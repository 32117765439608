import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import LapuSimModal from './LapuSimModal';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';
import CONSTANTS from '../../../../_constants/constants';
import classes from './LapuSimMasterPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import PageTitle from '../../../UI/PageTitle/PageTitle';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const LapuSimMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [supplierId, setSupplierId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [filterKeyword, setFilterKeyword] = useState('');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = [ 'Supplier', 'Operator', 'Lapu Number', 'Lapu Opening'];
    const dataFields = ['supplierName', 'operatorName', 'lapuNumber', 'lapuOpening'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Lapu Number', key: 'lapuNumber' },
        { label: 'Lapu Opening', key: 'lapuOpening' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left',
            'Operator': 'center',
            'Lapu Number': 'center',
            'Lapu Opening': 'center'
        },
        widthConfig: {
            'Supplier': 'auto',
            'Operator': '154px',
            'Lapu Number': '154px',
            'Lapu Opening': '154px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'lapuNumber': 'left',
            'lapuOpening': 'right'
        }
    }

    useEffect(() => {
        getSupplierList();
        getOperatorList();
    }, []);
    
    useEffect(() => {
        setIsLoading(true);
        getCommissionData();
    }, [supplierId, operatorId]);
    
    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            setIsLoading(true);
            getCommissionData();
        }
    }

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }
    
    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getCommissionData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            }
        };
        let url = '';
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Lapu/AllLapu` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Lapu/AllLapu?operatorId=${operatorId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Lapu/AllLapu?supplierId=${supplierId}` :
                        `${CONSTANTS.API_URL}Lapu/AllLapu?supplierId=${supplierId}&operatorId=${operatorId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    const keysToParse = ['lapuOpening'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data._meta;
                    const headerKeysToParse = ['totalOpening'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        total.totalOpening
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        lapuNumber: '',
                        lapuOpening: total.totalOpening
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({supplierId, operatorId}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => {
        setSuccessMessage(null);
        setFilterKeyword(event.target.value);
    }

    const supplierChangeHandler = event => {
        setSuccessMessage(null);
        setErrorMessage(null);
        setSupplierId(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        setSuccessMessage(null);
        setErrorMessage(null);
        setOperatorId(event.target.value);
    }

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.lapuNumber && data.lapuNumber.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.supplierName && data.supplierName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.lapuOpening && data.lapuOpening.toString().includes(filterKeyword.toLowerCase()))
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'Lapu_Sim';
        exportFilename += supplierId !== '-1' ? '_' + supplierData.find(data => data.supplierId == supplierId)['supplierName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        setExportFileName(exportFilename);
    }

    // const importFromLapuSalesHandler = () => {
    //     const requestOptions = {
    //         method: 'PUT',
    //         headers: { 
    //             'Token': localStorage.getItem('accessToken'),
    //             'Content-Type': 'application/json'
    //         }
    //     };
    //     let url = '';
    //     url = (supplierId === '-1' && operatorId === '-1') ?
    //             `${CONSTANTS.API_URL}LapuSales/UpdateLapuOpening` :
    //             supplierId === '-1' ?
    //                 `${CONSTANTS.API_URL}LapuSales/UpdateLapuOpening?operatorId=${operatorId}` :
    //                 operatorId === '-1' ?
    //                     `${CONSTANTS.API_URL}LapuSales/UpdateLapuOpening?supplierId=${supplierId}` :
    //                     `${CONSTANTS.API_URL}LapuSales/UpdateLapuOpening?supplierId=${supplierId}&operatorId=${operatorId}`
    //     fetch(url, requestOptions)
    //     .then(handleResponse)
    //     .then(
    //         (result) => {
    //             if(result.data) {
    //                 setSuccessMessage(result.data.message);
                    
    //             } else {
    //                 setErrorMessage(result.error.message);
    //             }
    //         },
    //         error => setErrorMessage(error)
    //     );
    // }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Lapu/Demo Sim Master'>
                    <div className='row mb-3'>
                        <div className='col-sm-6 col-md-6'>
                        <form className='form-inline'>
                            <div className={`form-group ${classes.inputContainer}`}>
                                <label className='col-form-label inputLabel'>Supplier : </label>
                                <select value={supplierId} className='form-select customSelect' onChange={supplierChangeHandler} >
                                    { supplierData.map( (value, index) => {
                                        return <option key={`supplier-${index}`} value={value.supplierId} className='dropdownItem'>{value.supplierName}</option>
                                    }) }
                                </select>
                            </div>
                            <div className={`form-group ${classes.inputContainer}`}>
                                <label className='col-form-label inputLabel'>Operator : </label>
                                <select  value={operatorId} className='form-select customSelect' onChange={operatorChangeHandler} >
                                    { operatorData.map( (value, index) => {
                                        return <option key={`operator-${index}`} value={value.operatorId} className='dropdownItem'>{value.operatorName}</option>
                                    }) }
                                </select>
                            </div>
                        </form>
                        </div>
                        <div className={`col-sm-6 col-md-6 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={e => modalOpenHandler('Add', '')} btnText='Add Lapu Sim' btnTitle='Add Lapu Sim' btnType='Add' />
                                { modalIsOpen && <LapuSimModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { successMessage && <Alert alertClassName='success' message={successMessage} /> }
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true} 
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={true}
                        rowClick={false}
                        gridData={gridData}
                        totalData={totalData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default LapuSimMasterPage;