const ROLES = {
    'SUPER_USER': 'Superuser',
    'ADMIN': 'Admin',
    'STAFF': 'Staff'
}

const PLAN_STATUS = {
    'ACTIVE': 'Active',
    'EXPIRED': 'Expired'
}

const CONSTANTS = {
    API_URL: 'https://royalinfo.in/api/pavathi/',
    FILE_EXT: '.csv',
    RECORDS_PER_PAGE: 50,
    ENTRIES: [10, 20, 50, 100],
    ROLES: ROLES,
    PLAN_STATUS: PLAN_STATUS
}

export default CONSTANTS;