import { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import classes from './ChangePasswordPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import PageTitle from '../../../UI/PageTitle/PageTitle';

const ChangePasswordPage = props => {

    const username = localStorage.getItem('currentUser');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // username = localStorage.getItem('currentUser');
        // console.log(username)
    }, []);

    const changePasswordHandler = () => {
        clearMessages();
        if(newPassword !== confirmPassword) {
            setErrorMessage('New and Confirm Passwords must be same');
        } else {
            const url = `${CONSTANTS.API_URL}user/changepassword`;
            const reqBody = { oldPassword, newPassword };
            ApiService.postData(url, reqBody).then(handleApiResponse);
        }
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                setSuccessMessage('Password changed successfully!');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const clearMessages = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    }

    const oldPasswordChangeHandler = e => {
        clearMessages();
        setOldPassword(e.target.value);
    }

    const newPasswordChangeHandler = e => {
        clearMessages();
        setNewPassword(e.target.value);
    }

    const confirmPasswordChangeHandler = e => {
        clearMessages();
        setConfirmPassword(e.target.value);
    }

    return (
        <>
            <PageTitle title='Change Password' />
            <div className='card' style={{width: '50vw', padding: '8px 0px'}}>
                <div className='card-body customCardBody'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { successMessage && <Alert alertClassName='success' message={successMessage} /> }
                    <div className='container'>
                        <form>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-5 col-form-label inputLabel'>Username : </label>
                                <div className='col-7'>
                                    <input type='text' readOnly className='form-control inputFieldMd' value={username} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-5 col-form-label inputLabel'>Old Password : </label>
                                <div className='col-7'>
                                    <input type='password' className='form-control inputFieldMd' value={oldPassword} onChange={oldPasswordChangeHandler} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-5 col-form-label inputLabel'>New Password : </label>
                                <div className='col-7'>
                                    <input type='password' className='form-control inputFieldMd' value={newPassword} onChange={newPasswordChangeHandler} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-5 col-form-label inputLabel'>Confirm Password : </label>
                                <div className='col-7'>
                                    <input type='password' className='form-control inputFieldMd' value={confirmPassword} onChange={confirmPasswordChangeHandler} />
                                </div>
                            </div>
                            <div style={{textAlign: 'center', marginTop: '24px'}}>
                                <Button btnClass='btn btn-primary mr-8' onClick={changePasswordHandler} btnText='Submit' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePasswordPage;