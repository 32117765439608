import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import Modal from '../../../UI/Modal/Modal';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import FormSelect from '../../../UI/Form/FormSelect/FormSelect';
import classes from './OperatorMappingModal.module.css';

const OperatorMappingModal = props => {

    const [operatorMapName, setOperatorMapName] = useState('');
    const [operatorId, setOperatorId] = useState('-2');
    const [operatorData, setOperatorData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Operator Mapping`;
    
    useEffect(() => {
        getOperatorList();
        return () => {
            setOperatorMapName('');
            setOperatorId('-2');
            setErrorMessage(null);
        }
    }, []);

    useEffect(() => {
        setErrorMessage(null);
    });

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('modal').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = event => {
        setOperatorMapName(props.modalData.operatorMapName ? props.modalData.operatorMapName : '');
        setOperatorId(props.modalData.operatorId ? props.modalData.operatorId : '-2');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setErrorMessage(null);
        setHasError(false);
        props.onCloseModal('cancel');
    }

    const operatorMapChangeHandler = event => setOperatorMapName(event.target.value)

    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const addData = (url, reqBody) => ApiService.postData(url, reqBody).then(handleApiResponse)

    const editData = (url, reqBody) => ApiService.updateData(url, reqBody).then(handleApiResponse)

    const deleteData = (url, reqBody) => ApiService.deleteData(url, reqBody).then(handleApiResponse)

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}OperatorMap/`;
        if(props.modalName !== 'Delete' && (operatorMapName === '' || operatorId === '-2' )) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            if(props.modalName === 'Add') {
                reqBody = {operatorMapName, operatorId};
                url = 'AddOperatorMap';
                addData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Edit') {
                reqBody = {operatorMapName, operatorId};
                url = `UpdateOperatorMap?operatorMapId=${props.modalData.operatorMapId}`;
                editData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Delete') {
                url = `DeleteOperatorMap?operatorMapId=${props.modalData.operatorMapId}`;
                deleteData(apiUrl+url, reqBody);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit') &&
            <form>
                <FormGroup 
                    label='Operator Mapping Name'
                    value={operatorMapName}
                    // readOnly={props.modalName === 'Edit'}
                    changeHandler={operatorMapChangeHandler} 
                    hasError={hasError}
                    errorMessage='Please enter operator mapping name.'
                    labelWidth={6}
                    fieldWidth={6}
                    autoFocus
                />
                <FormSelect 
                    label='Operator'
                    value={operatorId}
                    defaultValue='-2'
                    onChange={operatorChangeHandler} 
                    selectData={operatorData}
                    dataKeyPrefix='operator'
                    selectDataKey='operatorId'
                    selectDataValue='operatorName'
                    hasError={hasError}
                    errorMessage='Please select Operator.'
                    labelWidth={6}
                    fieldWidth={6}
                />
            </form>
            }
            { props.modalName === 'Delete' && 
                <div className={classes.text}>Do you want to Delete Operator Mapping <b>({props.modalData.operatorMapName})</b>?</div>
            }
        </Modal>
    );

}

export default React.memo(OperatorMappingModal);