import CONSTANTS from '../_constants/constants';
import { handleResponse } from '../_helpers/handle-response';

const getReqOptions = () => {
    const reqOptions = {
        headers: { 
            'Token': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        }
    };
    return reqOptions;
}

const getCustomerList = requestor => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Customer/CustomerList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                if(requestor === 'filter') {
                    result.data.results.unshift({customerId: '-1', customerName: 'All'});
                } else if(requestor === 'modal') {
                    result.data.results.unshift({customerId: '-2', customerName: ''});
                }
            }
            return result;
        },
        error => error
    );
}

const getOperatorList = requestor => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Operator/OperatorList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({operatorId: '-1', operatorName: 'All'}) :
                    result.data.results.unshift({operatorId: '-2', operatorName: ''});
            }
            return result;
        },
        error => error
    );
}

const getStatusList = () => {
    return [
        { statusId: '0', statusName: 'No'},
        { statusId: '1', statusName: 'Yes'}
    ];
}

const getProviderList = requestor => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Provider/ProviderList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                if(requestor === 'filter') {
                    result.data.results.unshift({providerId: '-1', providerName: 'All'});
                } else if(requestor === 'modal') {
                    result.data.results.unshift({providerId: '-2', providerName: ''});
                }
            }
            return result;
        },
        error => error
    );
}

const getRegionList = (requestor, apiPrefix = '') => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Region/${apiPrefix}RegionList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                if(requestor === 'filter') { 
                    result.data.results.unshift({regionId: '-1', regionName: 'All'});
                } else if(requestor === 'modal') {
                    result.data.results.unshift({regionId: '-2', regionName: ''});
                }
            }
            return result;
        },
        error => error
    );
}

const getSupplierList = (requestor, apiPrefix = '') => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Supplier/${apiPrefix}SupplierList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({supplierId: '-1', supplierName: 'All'}) :
                    result.data.results.unshift({supplierId: '-2', supplierName: ''});
            }
            return result;
        },
        error => error
    );
}

const getAPIOperatorListBySupplier = (requestor, supplierId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Operator/SupplierwiseAPIOperatorList?supplierId=${supplierId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({operatorId: '-1', operatorName: 'All'}) :
                    result.data.results.unshift({operatorId: '-2', operatorName: ''});
            }
            return result;
        },
        error => error
    );
}

const getAPIOperatorListByCustomer = (requestor, customerId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Operator/CustomerwiseAPIOperatorList?customerId=${customerId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                if(requestor === 'filter') {
                    result.data.results.unshift({operatorId: '-1', operatorName: 'All'});
                } else if(requestor === 'modal') {
                    result.data.results.unshift({operatorId: '-2', operatorName: ''});
                }
            }
            return result;
        },
        error => error
    );
}

const getOperatorListBySupplier = (requestor, supplierId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Operator/SupplierwiseOperatorList?supplierId=${supplierId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({operatorId: '-1', operatorName: 'All'}) :
                    result.data.results.unshift({operatorId: '-2', operatorName: ''});
            }
            return result;
        },
        error => error
    );
}

const getOperatorWiseSupplierList = (requestor, operatorId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Supplier/OperatorwiseSupplierList?operatorId=${operatorId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({supplierId: '-1', supplierName: 'All'}) :
                    result.data.results.unshift({supplierId: '-2', supplierName: ''});
            }
            return result;
        },
        error => error
    );
}

const getCommissionType = requestor => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}PercentageType/PercentageTypeList`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({percentageTypeId: '-1', percentageTypeName: 'All'}) :
                    result.data.results.unshift({percentageTypeId: '-2', percentageTypeName: ''});
            }
            return result;
        },
        error => error
    );
}

const getRegionListBySupplierNOperator = (requestor, supplierId, operatorId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Region/ApiPurchaseRegionList?operatorId=${operatorId}&supplierId=${supplierId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({regionId: '-1', regionName: 'All'}) :
                    result.data.results.unshift({regionId: '-2', regionName: ''});
            }
            return result;
        },
        error => error
    );
}

const getRegionListByCustomerNOperator = (requestor, customerId, operatorId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}Region/ApiSalesRegionList?operatorId=${operatorId}&customerId=${customerId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => {
            if(result.data) {
                requestor === 'filter' ? 
                    result.data.results.unshift({regionId: '-1', regionName: 'All'}) :
                    result.data.results.unshift({regionId: '-2', regionName: ''});
            }
            return result;
        },
        error => error
    );
}

const getCommissionTypeBySupplierNOperator = (api, supplierId, operatorId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}${api}?operatorId=${operatorId}&supplierId=${supplierId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => result,
        error => error
    );
}

const getCommissionTypeBySupplierNOperatorNRegion = (api, supplierId, operatorId, regionId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}${api}?operatorId=${operatorId}&supplierId=${supplierId}&regionId=${regionId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => result,
        error => error
    );
}

const getCommissionTypeByCustomerNOperatorNRegion = (api, customerId, operatorId, regionId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}${api}?operatorId=${operatorId}&customerId=${customerId}&regionId=${regionId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => result,
        error => error
    );
}

const getCommissionTypeByCustomerNOperator = (api, customerId, operatorId) => {
    const requestOptions = getReqOptions();
    return fetch(`${CONSTANTS.API_URL}${api}?operatorId=${operatorId}&customerId=${customerId}`, requestOptions)
    .then(handleResponse)
    .then(
        result => result,
        error => error
    );
}

export const DropdownDataService = {
    getCustomerList,
    getOperatorList,
    getProviderList,
    getRegionList,
    getSupplierList,
    getStatusList,
    getAPIOperatorListBySupplier,
    getAPIOperatorListByCustomer,
    getOperatorListBySupplier,
    getOperatorWiseSupplierList,
    getCommissionType,
    getCommissionTypeBySupplierNOperator,
    getCommissionTypeByCustomerNOperator,
    getRegionListBySupplierNOperator,
    getRegionListByCustomerNOperator,
    getCommissionTypeBySupplierNOperatorNRegion,
    getCommissionTypeByCustomerNOperatorNRegion
};