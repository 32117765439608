import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import Modal from '../../../UI/Modal/Modal';
import classes from './StaffModal.module.css';

const StaffModal = props => {

    const [staffName, setStaffName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Staff`;

    useEffect(() => {
        return () => clearFields()
    }, []);

    useEffect(() => {
        setErrorMessage(null);
    }, [staffName, userName, password])

    const clearFields = () => {
        setStaffName('');
        setUserName('');
        setPassword('');
    }

    // Modal Handlers
    const afterOpenModal = event => {
        setStaffName( props.modalData.staffName ? props.modalData.staffName : '');
        setUserName( props.modalData.userName ? props.modalData.userName : '');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        clearFields();
        props.onCloseModal('cancel');
    }

    // Change Handlers
    const staffNameChangeHandler = event => setStaffName(event.target.value)
    const userNameChangeHandler = event => setUserName(event.target.value)
    const passwordChangeHandler = event => setPassword(event.target.value)

    // API functions
    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Staff/`;
        if(props.modalName !== 'Delete' && (staffName === '' || userName === '' || password === '' )) {
            setHasError(true);
        } else {
            setHasError(false);
            if(props.modalName === 'Add') {
                ApiService.postData(`${apiUrl}AddStaff`, {staffName, userName, password})
                    .then(handleApiResponse);
            } else if(props.modalName === 'Edit') {
                ApiService.updateData(`${apiUrl}UpdateStaff?staffId=${props.modalData.staffId}`, {staffName, userName, password})
                    .then(handleApiResponse);
            } else if(props.modalName === 'Delete') {
                ApiService.deleteData(`${apiUrl}DeleteStaff?staffId=${props.modalData.staffId}`, props.modalData)
                    .then(handleApiResponse);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            labelWidth={4}
            fieldWidth={8}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit')  &&
                <form>
                    <FormGroup 
                        label='Staff Name'
                        value={staffName}
                        readOnly={props.modalName === 'Edit'}
                        changeHandler={staffNameChangeHandler} 
                        hasError={hasError}
                        errorMessage='Please enter staff name.'
                        autoFocus
                    />
                    <FormGroup 
                        label='User Name'
                        value={userName}
                        readOnly={props.modalName === 'Edit'}
                        changeHandler={userNameChangeHandler} 
                        hasError={hasError}
                        errorMessage='Please enter user name.'
                    />
                    <FormGroup 
                        label='Password'
                        value={password}
                        changeHandler={passwordChangeHandler} 
                        hasError={hasError}
                        errorMessage='Please enter password.'
                    />
                    {/* <div className={`form-group row has-validation ${classes.inputContainer}`}>
                        <label className='col-sm-4 col-form-label inputLabel'>Staff Name : </label>
                        <div className='col-sm-8'>
                            <input 
                                type='text' 
                                readOnly={props.modalName === 'Edit'} 
                                className={`form-control inputField ${classes.modalInput} ${hasError && staffName === '' ? 'is-invalid': ''}`} 
                                value={staffName} 
                                onChange={staffNameChangeHandler} 
                                autoFocus
                            />
                            <div className='invalid-feedback'>Please enter staff name.</div>
                        </div>
                    </div> */}
                </form>
            } 
            { props.modalName === 'Delete' &&
                <div className={classes.text}>Do you want to Delete Staff Name?</div>
            }
        </Modal>
        
    );

}

export default React.memo(StaffModal);