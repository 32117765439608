import { useEffect, useState } from 'react';

import TERMS_N_CONDITIONS from '../../../../_data/termsNConditionsData';
import PageLayout from '../../../Layout/PageLayout/PageLayout';
import classes from './RefundPolicyPage.module.css';

const RefundPolicyPage = props => {

    const [refundPolicy, setRefundPolicy] = useState([]);

    useEffect(() => {
        setRefundPolicy(TERMS_N_CONDITIONS.filter(data => data.title === 'Refund')[0]);
    }, []);

    return (
        <PageLayout pageTitle='Refund Policy'>
            <p className={classes.refundText}>{refundPolicy.text}</p>
        </PageLayout>
    );
}

export default RefundPolicyPage;