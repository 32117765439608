import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import CONSTANTS from '../../../../_constants/constants';
import classes from './DeleteLapuSalesModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const DeleteLapuSalesModal = props => {

    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [providerId, setProviderId] = useState('');
    const [providerData, setProviderData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        getProviderList();
        return () => {
            setConfirmDelete(false);
            setProviderId('');
            setErrorMessage(null);
        }
    }, []);

    useEffect(() => {
        if(confirmDelete == false) {
            setErrorMessage(null);
        }
    }, [confirmDelete]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          width: '35%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getProviderList = () => {
        DropdownDataService.getProviderList('filter').then( result => {
            if(result.data) {
                setProviderId(result.data.results[0].providerId);
                setProviderData(result.data.results);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const dateChangeHandler = event => {
        setErrorMessage(null);
        setDate(event.target.value)
    };

    const providerChangeHandler = e => {
        setErrorMessage(null);
        setProviderId(e.target.value);
    }

    const afterOpenModal = () => {
        setDate(new Date().toISOString().slice(0,10));
        setProviderId('-1');
        setErrorMessage(null);
    }
    
    const saveChangesHandler = () => {
        if(date === '') {
            setHasError(true);
        } else {
            setHasError(false);
            let apiUrl = `${CONSTANTS.API_URL}LapuSales/DeleteLapuSales?transactionDate=${date}`;
            if(providerId !== '-1') { apiUrl = apiUrl + `&providerId=${providerId}` }
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('accessToken')
                }
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        setConfirmDelete(false);
                        props.onCloseModal();
                    } else if(result.error) {
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        }
    }

    const onModalClose = event => {
        props.onCloseModal();
    }

    
    const deleteLapuSalesContent = ( <form>
        <div className={`form-group row ${classes.inputContainer}`}>
            <label className='col-sm-4 col-form-label inputLabel'>Date : </label>
            <div className='col-sm-8'>
                <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={date} onChange={dateChangeHandler} />
            </div>
        </div>
        <div className={`form-group row has-validation ${classes.inputContainer}`}>
            <label className='col-sm-4 col-form-label inputLabel'>Provider : </label>
            <div className='col-sm-8'>
                <select className={`form-select inputField ${hasError && providerId === '-2' ? 'is-invalid': ''}`} value={providerId} aria-label=".form-select-sm example" onChange={providerChangeHandler}>
                    { providerData.map( (value, index) => {
                        return <option key={`provider-${index}`} value={value.providerId}>{value.providerName}</option>
                    }) }
                </select>
                <div className='invalid-feedback'>Please select a Provider.</div>
            </div>
        </div>
    </form>
    );

    const confirmDeleteContent = (
        <div className={classes.text}>Do you want to Delete Lapu Sales?</div>
    );

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{confirmDelete ? 'Confirm Delete' : props.modalName}</h4>
            </div>
            <div className='modal-body'>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { !confirmDelete && deleteLapuSalesContent }
                    { confirmDelete && confirmDeleteContent }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                { !confirmDelete && 
                    <>
                        <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText='Cancel' />
                        <Button btnClass='btn btn-primary' onClick={e => setConfirmDelete(true)} btnText='Delete' />
                    </>
                }
                { confirmDelete && 
                    <>
                        <Button btnClass='btn btn-secondary' onClick={e => setConfirmDelete(false)} btnText='No' />
                        <Button btnClass='btn btn-primary' onClick={saveChangesHandler} btnText='Yes' />
                    </>
                }
            </div>
          </ReactModal>
        </div>
    );

}

export default DeleteLapuSalesModal;