import classes from './FormSelect.module.css';

const FormSelect = props => {
    return (
        <div className={`form-group row has-validation ${classes.inputContainer}`}>
            <label className={`col-sm-${props.labelWidth || 4} col-form-label ${classes.inputLabel}`}>{props.label} : </label>
            <div className={`col-sm-${props.fieldWidth || 8}`}>
                <select value={props.value} className={`form-select inputField ${props.hasError && props.value === props.defaultValue ? 'is-invalid': ''}`} onChange={props.onChange}>
                    { props.selectData.map( (value, index) => {
                        return <option key={`${props.dataKeyPrefix}-${index}`} value={value[props.selectDataKey]} className='dropdownItem'>{value[props.selectDataValue]}</option>
                    }) }
                </select>
                <div className='invalid-feedback'>{props.errorMessage}</div>
            </div>
        </div>
    )
}

export default FormSelect;