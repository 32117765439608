import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import APISalesConsumptionModal from './APISalesConsumptionModal';
import CONSTANTS from '../../../../_constants/constants';
import { getAPIUrl, parseFloat2Decimal, parseFloat2DecimalHeader, uiDate, uiDateToday, uiMonthStartDate } from '../../../../_helpers/utils';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import classes from './APISalesConsumptionPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const APISalesConsumptionPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [fromDate, setFromDate] = useState(uiMonthStartDate());
    const [toDate, setToDate] = useState(uiDateToday());
    const [customerId, setCustomerId] = useState('-1');
    const [operatorId, setOperatorId] = useState('-1');
    const [regionId, setRegionId] = useState('-1');
    const [customerData, setCustomerData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Date', 'Customer', 'Operator', 'Region', 'Consumed Amount', 'Commission Type', 'Commission %', 'Commission', 'Difference(+/-)', 'Net Consumed'];
    const dataFields = ['transactionDate', 'customerName', 'operatorName', 'regionName', 'netAmount', 'percentageTypeName', 'commissionRate', 'commission', 'adjustment', 'netCurrency'];
    const csvHeaders = [
        { label: 'Date', key: 'transactionDate' },
        { label: 'Customer', key: 'customerName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Region', key: 'regionName' },
        { label: 'Consumed Amount', key: 'netAmount' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Commission', key: 'commission' },
        { label: 'Difference(+/-)', key: 'adjustment' },
        { label: 'Net Consumed', key: 'netCurrency' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'left',
            'Customer': 'left',
            'Operator': 'center',
            'Region': 'center',
            'Consumed Amount': 'center',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Commission': 'center',
            'Difference(+/-)': 'center',
            'Net Consumed': 'center'
        },
        widthConfig: {
            'Date': '96px',
            'Customer': '300px',
            'Operator': '154px',
            'Region': '154px',
            'Consumed Amount': '162px',
            'Commission Type': '154px',
            'Commission %': '154px',
            'Commission': '154px',
            'Difference(+/-)': '154px',
            'Net Consumed': '154px'
        },
        alignConfig: {
            'transactionDate': 'left',
            'customerName': 'left',
            'operatorName': 'center',
            'regionName': 'center',
            'netAmount': 'right', 
            'percentageTypeName': 'center', 
            'commissionRate': 'right',
            'commission': 'right',
            'adjustment': 'right',
            'netCurrency': 'right'
        }
    }

    useEffect(() => {
        getCustomerList();
        getOperatorList();
        getRegionList();
        getAPIPurchaseData();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate, customerId, operatorId, regionId]);

    const getCustomerList = () => {
        DropdownDataService.getCustomerList('filter').then( result => {
            if(result.data) {
                setCustomerData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getRegionList = () => {
        DropdownDataService.getRegionList('filter').then( result => {
            if(result.data) {
                setRegionData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.transactionDate && data.transactionDate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.customerName && data.customerName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.operatorName && data.operatorName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.regionName && data.regionName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.netAmount && data.netAmount.toString().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.commission && data.commission.toString().includes(filterKeyword.toLowerCase())) ||
            (data.adjustment && data.adjustment.toString().includes(filterKeyword.toLowerCase())) ||
            (data.netCurrency && data.netCurrency.toString().includes(filterKeyword.toLowerCase()))
        )
    }

    const modalCloseHandler = (status, message) => {
        setModalIsOpen(false);
        if(status === 'save') {
            getAPIPurchaseData();
        }
    }

    const getUrl = () => {
        const state = { startDate: fromDate, endDate: toDate, customerId, operatorId, regionId };
        const urlPrefix = `${CONSTANTS.API_URL}ApiSalesConsumption/AllApiSalesConsumption`;
        return getAPIUrl(state, urlPrefix);
    }

    const getAPIPurchaseData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = getUrl();
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => data.transactionDate = uiDate(data.transactionDate));
                    const keysToParse = ['netAmount', 'commission', 'adjustment', 'netCurrency'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    result.data.results.map( d => d.commissionRate = d.commissionRate.toFixed(3));
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    const total = result.data.total[0];
                    const headerKeysToParse = ['netAmount', 'commission', 'adjustment', 'netCurrency'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        '',
                        total.netAmount,
                        '',
                        '',
                        total.commission,
                        total.adjustment,
                        total.netCurrency,
                        ''
                    ]);
                    setExportTotalData({
                        transactionDate: 'Total',
                        customerName: '',
                        operatorName: '',
                        regionName: '',
                        netAmount: total.netAmount,
                        percentageTypeName: '',
                        commissionRate: '',
                        commission: total.commission,
                        adjustment: total.adjustment,
                        netCurrency: total.netCurrency
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        setModalData(modalData === '' ? {}: modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const showClickHandler = () => {
        setIsLoading(true);
        getAPIPurchaseData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)
    
    const customerChangeHandler = event => setCustomerId(event.target.value)

    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const regionChangeHandler = event => setRegionId(event.target.value)
    
    const getExportFileName = () => {
        let exportFilename = 'APISalesConsumption';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += customerId !== '-1' ? '_' + customerData.find(data => data.customerId == customerId)['customerName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        exportFilename += regionId !== '-1' ? '_' + regionData.find(data => data.regionId == regionId)['regionName'] : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='API Sales Consumption'>
                    <div className='row mb-1'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary' onClick={e => modalOpenHandler('Add', '')} btnText='Add API Consumption' btnTitle='Add API Consumption' btnType='Add' />
                                { modalIsOpen && <APISalesConsumptionModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                /> }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline' style={{display: 'flex', justifyContent:'flex-start'}}>
                                {/* <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date: </label>
                                    <input type='date' required className='form-control form-control-sm inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div> */}
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To Date : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Customer : </label>
                                    <select className='form-select customSelect' aria-label='.form-select-sm example' onChange={customerChangeHandler} value={customerId}>
                                        { customerData.map( (value, index) => {
                                            return <option key={`customer-${index}`} className='dropdownItem' value={value.customerId}>{value.customerName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select  value={operatorId} className='form-select customSelect' onChange={operatorChangeHandler} >
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId} className='dropdownItem'>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Region : </label>
                                    <select value={regionId} className='form-select customSelect' onChange={regionChangeHandler}>
                                        { regionData.map( (value, index) => {
                                            return <option key={`region-${index}`} value={value.regionId} className='dropdownItem'>{value.regionName}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className={classes.headerButtons}>
                            <Button btnClass='btn btn-primary mr-8' onClick={showClickHandler} btnText='Show' />
                            <ExportToCSV 
                                headers={csvHeaders}
                                exportTotalData={exportTotalData}
                                gridData={gridData}
                                exportFileName={exportFileName} 
                                exportClickHandler={getExportFileName} 
                                enableExport={enableExport}
                            />
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        paginate={true}
                        filter={true}
                        rowClick={false}
                        gridData={gridData}
                        totalData={totalData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default APISalesConsumptionPage;