import { handleResponse } from '../_helpers/handle-response';

const getData = url => {
    const requestOptions = {
        headers: getHeaders()
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            result => result,
            error => error
        );
}

const postData = (url, reqBody) => {
    const body = JSON.stringify(reqBody);
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: body
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            result => result,
            error => error
        );
}

const updateData = (url, reqBody) => {
    const body = !!reqBody && JSON.stringify(reqBody);
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        body: !!body && body
    };
    return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
        result => result,
        error => error
        );
    }
    
const deleteData = (url, reqBody) => {
    const body = !!reqBody && JSON.stringify(reqBody);
    const requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: !!body && body
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            result => result,
            error => error
        );
}

const getHeaders = () => {
    return { 
        'Token': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
    };
}

export const ApiService = {
    getData,
    postData,
    updateData,
    deleteData
};