import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import CustomerWiseAPISalesPendingModal from './CustomerWiseAPISalesPendingModal';
import { parseFloat2Decimal } from '../../../../_helpers/utils';
import classes from './CustomerWiseAPISalesPendingPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Table from '../../../UI/Table/Table';
import Loader from '../../../UI/Loader/Loader';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const CustomerWiseAPISalesPendingPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Customer', 'Commission Type', 'Commission %', 'Net Pending'];
    const dataFields = ['customerName', 'percentageTypeName', 'commissionRate', 'netCurrency'];
    const csvHeaders = [
        { label: 'Customer', key: 'customerName' },
        { label: 'Commission Type', key: 'percentageTypeName' },
        { label: 'Commission %', key: 'commissionRate' },
        { label: 'Net Pending', key: 'netCurrency' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Customer': 'left',
            'Commission Type': 'center',
            'Commission %': 'center',
            'Net Pending': 'center'
        },
        widthConfig: {
            'Customer': 'auto',
            'Commission Type': '154px',
            'Commission %': '154px',
            'Net Pending': '154px'
        },
        alignConfig: {
            'customerName': 'left',
            'percentageTypeName': 'center',
            'commissionRate': 'right',
            'netCurrency': 'right'
        }
    }

    useEffect(() => { 
        getPurchaseOpeningData();
    }, []);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.customerName && data.customerName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.percentageTypeName && data.percentageTypeName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.commissionRate && data.commissionRate.toString().includes(filterKeyword.toLowerCase())) ||
            (data.netCurrency && data.netCurrency.toString().includes(filterKeyword.toLowerCase()))
        );
    }

    const modalCloseHandler = (status, message) => {
        setModalIsOpen(false);
        if(status === 'save') {
            getPurchaseOpeningData();
        }
    }

    const getPurchaseOpeningData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        fetch(`${CONSTANTS.API_URL}ApiSalesOpening/AllApiSalesOpening`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    if(result.data.results.length > 0) {
                        const keysToParse = ['commissionRate', 'netCurrency'];
                        parseFloat2Decimal(result.data.results, keysToParse);
                        setGridData(result.data.results);
                        setPageCount(Math.ceil(result.data.results.length/10));
                        setTotalData([
                            '',
                            '',
                            (result.data.total[0].netCurrency).toFixed(2),
                            ''
                        ]);
                        setExportTotalData({
                            customerName: 'Total',
                            percentageTypeName: '',
                            commissionRate: '',
                            netCurrency: (result.data.total[0].netCurrency).toFixed(2)
                        });
                        setEnableExport(result.data.results.length > 0 ? true : false);
                    }
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        setModalData(modalData === '' ? {}: modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const getExportFileName = () => {
        setExportFileName('CustomerWise_API_Sales_Pending');
    }
    
    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='API Sales Pending'>
                    {/* mb-3 */}
                    <div className='row'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary mr-8' onClick={e => modalOpenHandler('Add', '')} btnText='Add API Sales Pending' btnTitle='Add API Sales Pending' btnType='Add' />
                                { modalIsOpen && <CustomerWiseAPISalesPendingModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                /> }
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={true}
                        rowClick={false}
                        gridData={gridData}
                        totalData={totalData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
                </PageLayout>
            )}
        </>
    );
  
}

export default CustomerWiseAPISalesPendingPage;