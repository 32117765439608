import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { validateUTRInput } from '../../../../_helpers/validators';
import { ApiService } from '../../../../_services/api.service';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import Modal from '../../../UI/Modal/Modal';
import qrCode from '../../../../assets/images/QRCode.png'
import classes from './PaymentModal.module.css';
import { FaInfoCircle } from 'react-icons/fa';

const PaymentModal = props => {

    const [utrNo, setUtrNo] = useState();
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const upiID = 'paperfreesolutions@sbi';

    useEffect(() => {
        return () => clearFields()
    }, []);

    const clearFields = () => {
    }
    
    // Modal Handlers
    const afterOpenModal = event => {
        setHasError(false);
        setErrorMessage(null);
        props.onAfterOpen(event, 'After Modal Opened');
    }

    const onModalClose = event => {
        clearFields();
        props.onCloseModal('cancel');
    }
    
    // Change Handler
    const utrChangeHandler = evt => setUtrNo(evt.target.value)

    // API functions
    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}PaymentLog/AddPaymentLog`;
        if( validateUTRInput(utrNo) ) {
            setHasError(false);
            const apiBody = {
                planId: props.modalData.planId,
                validity: props.modalData.days,
                amount: props.modalData.amount,
                utrNo
            };
            ApiService.postData(apiUrl, {...apiBody}).then(handleApiResponse);
        } else {
            setHasError(true);
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName='Payment'
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            style={{top: '55%', width: '40%'}}
            labelWidth={4}
            fieldWidth={8}
        >
            <form style={{ height: 'calc(82vh - 180px)', width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                <div style={{width: '220px',  height: '220px', margin: 'auto', textAlign: 'center'}}>
                    <img src={qrCode} alt='QR Code' height={215} width={215} style={{margin: 'auto'}} />
                </div>
                <div className='mb-4' style={{textAlign: 'center'}}>UPI ID: {upiID}</div>
                <FormGroup 
                    label='Plan Name'
                    value={props.modalData.planName}
                    disabled
                />
                <FormGroup 
                    label='Plan Validity (days)'
                    value={props.modalData.days}
                    disabled
                />
                <div className='form-group row'>
                    <label className='col-sm-4 col-form-label text-right' style={{paddingRight: '20px'}}>Benefits : </label>
                    <div className='col-sm-8 col-form-label pl-0 pr-0'>
                        <FaInfoCircle className={`text-info ${classes.benefitsIcon}`}/> {props.modalData.lapu} Lapu, Unlimited Providers, Operators and Users 
                    </div>
                </div>
                <FormGroup 
                    label='Amount'
                    value={props.modalData.amount}
                    disabled
                />
                <FormGroup 
                    label='UTR No.'
                    name='utr'
                    value={utrNo}
                    changeHandler={utrChangeHandler} 
                    hasError={hasError}
                    errorMessage='Please enter valid 12 digit UTR obtained after payment.'
                />
            </form>
        </Modal>
        
    );

}

export default React.memo(PaymentModal);