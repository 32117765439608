import React, { useEffect, useState } from 'react';

import classes from './RegionModal.module.css';
import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';

import Modal from '../../../UI/Modal/Modal';
import InputGroup from '../../../UI/Form/InputGroup/InputGroup';

const RegionModal = props => {

    const [regionName, setRegionName] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Region`;
    
    useEffect(() => {
        return () => {
            setRegionName('');
            setErrorMessage(null);
        }
    }, []);

    const afterOpenModal = event => {
        setHasError(false);
        setRegionName(Object.keys(props.modalData).length > 0 ? props.modalData.regionName : '');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setHasError(false);
        props.onCloseModal('cancel');
    }

    const regionChangeHandler = event => {
        if(event.target.value !== '') {
            setHasError(false);
        }
        setRegionName(event.target.value);
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const addData = (url, reqBody) => ApiService.postData(url, reqBody).then(handleApiResponse)

    const editData = (url, reqBody) => ApiService.updateData(url, reqBody).then(handleApiResponse)

    const deleteData = (url, reqBody) => ApiService.deleteData(url, reqBody).then(handleApiResponse)

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Region/`;
        if(props.modalName !== 'Delete' && regionName === '' ) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            if(props.modalName === 'Add') {
                reqBody = {regionName};
                url = 'AddRegion';
                addData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Edit') {
                reqBody = {regionName};
                url = `UpdateRegion?regionId=${props.modalData.regionId}`;
                editData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Delete') {
                reqBody = props.modalData;
                url = `DeleteRegion?regionId=${props.modalData.regionId}`;
                deleteData(apiUrl+url, reqBody);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                <InputGroup 
                    label='Region'
                    value={regionName}
                    changeHandler={regionChangeHandler} 
                    hasError={hasError}
                    errorMessage='Please enter region name.'
                    autoFocus
                />
            }
            { props.modalName === 'Delete' && 
                <div className={classes.text}>Do you want to Delete Region <b>({props.modalData.regionName})</b>?</div>
            }
        </Modal>
    );

}

export default React.memo(RegionModal);