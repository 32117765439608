import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { validateDecimalInput, validateNegDecimalInput } from '../../../../_helpers/validators';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import classes from './APIPurchaseConsumptionModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import CONSTANTS from '../../../../_constants/constants';

const APIPurchaseConsumptionModal = props => {

    const [transactionDate, setTransactionDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('-2');
    const [operatorId, setOperatorId] = useState('-2');
    const [regionId, setRegionId] = useState('-2');
    const [balanceAmount, setBalanceAmount] = useState();
    const [netAmount, setNetAmount] = useState();
    const [percentageTypeId, setPercentageTypeId] = useState();
    const [percentageTypeName, setPercentageTypeName] = useState();
    const [commissionRate, setCommissionRate] = useState();
    const [opCommissionRate, setOpCommissionRate] = useState();
    const [purCommissionRate, setPurCommissionRate] = useState();
    const [commission, setCommission] = useState();
    const [adjustment, setAdjustment] = useState();
    const [netCurrency, setNetCurrency] = useState();
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [percentageTypeData, setPercentageTypeData] = useState([]);

    useEffect(() => {
        if(props.modalName !== 'Delete') {
            updateModal();
            getSupplierList();
            if(props.modalName === 'Edit') {
                !!props.modalData.supplierId && getAPIOperatorListBySupplier(props.modalData.supplierId);
                !!props.modalData.supplierId && !!props.modalData.operatorId && getRegionListBySupplierNOperator(props.modalData.supplierId, props.modalData.operatorId);
                !!props.modalData.supplierId && !!props.modalData.operatorId && getCommissionTypeBySupplierNOperatorNRegion(props.modalData.supplierId, props.modalData.operatorId, props.modalData.regionId);
            }
        }
        return () => {
            setTransactionDate('');
            setSupplierId('');
            setOperatorId('');
            setRegionId('');
            setBalanceAmount();
            setNetAmount();
            setPercentageTypeId('');
            setPercentageTypeName('');
            setCommissionRate();
            setOpCommissionRate();
            setPurCommissionRate();
            setCommission();
            setNetCurrency();
        }
    }, []);

    const customStyles = {
        content: {
            top: '56%',
            left: '50%',
            right: 'auto',
            width: '38%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    useEffect(() => {
        if(!!percentageTypeName) {
            var commission = 0;
            let amount = netAmount === '' ? 0 : parseFloat(netAmount);
            let commRate = percentageTypeName === 'ADD ON' ? parseFloat(opCommissionRate) : (parseFloat(opCommissionRate) - parseFloat(purCommissionRate));
            setCommissionRate(commRate.toFixed(3));
            commission = (amount * commRate) / 100;
            setCommission(commission.toFixed(2));
        }
    }, [netAmount, percentageTypeName, opCommissionRate, purCommissionRate]);

    useEffect(() => {
        let amount = netAmount === '' ? 0 : parseFloat(netAmount);
        let difference = adjustment === '' || adjustment === '-' ? 0 : parseFloat(adjustment);
        const currency = (amount - parseFloat(commission) + difference).toFixed(2);
        setNetCurrency(currency); // less than or equal to balanceAmount
    }, [netAmount, commission, adjustment]);

    useEffect(() => {
        clearFieldsRelatedToSupplier();
        if(supplierId !== '-2') {
            getAPIOperatorListBySupplier(supplierId);
        }
    }, [supplierId]);

    useEffect(() => {
        if(operatorId !== '-2') {
            getRegionListBySupplierNOperator(supplierId, operatorId);
        }
    }, [operatorId]);

    useEffect(() => {
        if(supplierId !== '-2' && operatorId !== '-2' && regionId !== '-2') {
            getCommissionTypeBySupplierNOperatorNRegion(supplierId, operatorId, regionId);
        }
    }, [supplierId,operatorId, regionId]);

    useEffect(() => {
        getBalanceAmount();
    }, [supplierId, transactionDate]);

    const updateModal = () => {
        setTransactionDate(Object.keys(props.modalData).length > 0 ? props.modalData.transactionDate.split('/').reverse().join('-') : new Date().toISOString().slice(0,10));
        setSupplierId(!!props.modalData.supplierId ? props.modalData.supplierId : '-2');
        props.modalName === 'Edit' && setOperatorId(!!props.modalData.operatorId ? props.modalData.operatorId : '-2');
        props.modalName === 'Edit' && setRegionId(!!props.modalData.regionId ? props.modalData.regionId : '-2');
        setNetAmount(!!props.modalData.netAmount ? props.modalData.netAmount : (0).toFixed(2));
        setPercentageTypeId(Object.keys(props.modalData).length > 0 ? props.modalData.percentageTypeId : '-2');
        setPercentageTypeName(!!props.modalData.percentageTypeName ? props.modalData.percentageTypeName : '');
        setCommissionRate(Object.keys(props.modalData).length > 0 ? props.modalData.commissionRate : (0).toFixed(3));
        setCommission(!!props.modalData.commission ? props.modalData.commission : (0).toFixed(2));
        setAdjustment(!!props.modalData.adjustment ? props.modalData.adjustment : (0).toFixed(2));
        setNetCurrency(Object.keys(props.modalData).length > 0 ? props.modalData.netCurrency : (0).toFixed(2));
    }

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('modal', 'ApiPurchase').then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getAPIOperatorListBySupplier = supplierId => {
        DropdownDataService.getAPIOperatorListBySupplier('modal', supplierId).then( result => {
            if(result.data) {
                if(props.modalName === 'Edit') {
                    !!props.modalData.operatorId && setOperatorId(props.modalData.operatorId);
                }
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getCommissionTypeBySupplierNOperatorNRegion = (supplierId, operatorId, regionId) => {
        DropdownDataService.getCommissionTypeBySupplierNOperatorNRegion('ApiInput/ApiInput', supplierId, operatorId, regionId).then( result => {
            if(Object.keys(result.data).length > 0) {
                setPercentageTypeId(result.data.percentageTypeId);
                setPercentageTypeName(result.data.percentageTypeName);
                setOpCommissionRate((result.data.commissionRate).toFixed(3));
                setPercentageTypeData(result.data.results);
            } else {
                setErrorMessage(result.statusDec);
            }
        });
    }

    const getRegionListBySupplierNOperator = (supplierId, operatorId) => {
        DropdownDataService.getRegionListBySupplierNOperator('modal', supplierId, operatorId).then( result => {
            if(result.data) {
                setRegionData(result.data.results);
                (props.modalName === 'Edit' && props.modalData.regionId) && setRegionId(props.modalData.regionId);
            } else {
                setErrorMessage(result.error.message);
            }
        });
    }

    const afterOpenModal = () => { }
    
    const onModalClose = event => props.onCloseModal('cancel')

    const clearMessages = () => setErrorMessage(null)

    const clearFieldsRelatedToSupplier = () => {
        setOperatorId('-2');
        setRegionId('-2');
        setOperatorData([]);
        setRegionData([]);
        setBalanceAmount((0).toFixed(2));
        setPercentageTypeId('');
        setPercentageTypeName('');
        setCommissionRate((0).toFixed(3));
        setCommission((0).toFixed(2));
    }

    const clearFieldsRelatedToOperatorNRegion = () => {
        setPercentageTypeId('');
        setPercentageTypeName('');
        setCommissionRate((0).toFixed(3));
        setCommission((0).toFixed(2));
    }
    
    const transactionDateChangeHandler = event => {
        clearMessages();
        setTransactionDate(event.target.value);
    }

    const supplierChangeHandler = event => {
        clearMessages();
        setSupplierId(event.target.value);
    }
    
    const operatorChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        setOperatorId(event.target.value);
        if(value === '-2') {
            clearFieldsRelatedToOperatorNRegion();
        }
    }

    const regionChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        setRegionId(event.target.value);
        if(value === '-2') {
            clearFieldsRelatedToOperatorNRegion();
        }
    }
    
    const netAmountChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setNetAmount(value);
            }
        } else {
            setNetAmount(value);
        }
    }

    const adjustmentChangeHandler = event => {
        clearMessages();
        const value = event.target.value;
        if(value !== '' && value !== '-') {
            if(validateNegDecimalInput(value)) {
                setAdjustment(value);
            }
        } else {
            setAdjustment(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const getBalanceAmount = () => {
        if(supplierId !== '-2') {
            let apiUrl = `${CONSTANTS.API_URL}ApiPurchaseConsumption/ApiPurchaseConsumptionBalance?purchaseDate=${transactionDate}&supplierId=${supplierId}`;
            const requestOptions = {
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('accessToken')
                }
            };
            fetch(apiUrl, requestOptions)
            .then(handleResponse)
            .then(
                (result) => {
                    if(result.data) {
                        setBalanceAmount(result.data.results.balanceAmount.toFixed(2));
                        setPurCommissionRate(result.data.results.commissionRate.toFixed(3));
                    } else if(result.error) {
                        setErrorMessage(result.error.message);
                    }
                },
                error => setErrorMessage(error)
            );
        }
    }

    const saveChanges = (reqBody, url, reqMethod) => {
        let apiUrl = `${CONSTANTS.API_URL}ApiPurchaseConsumption/${url}`;
        const requestOptions = {
            method: reqMethod,
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    props.onCloseModal('save');
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    const saveChangesHandler = () => {
        if(props.modalName !== 'Delete' && (supplierId === '-2' || regionId === '-2' || netAmount === '0.00' || balanceAmount - netCurrency <= 0 || percentageTypeId === '-2' || opCommissionRate === '' || adjustment === '')) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {
                transactionDate,
                supplierId,
                operatorId,
                regionId,
                netAmount,
                percentageTypeId,
                commissionRate: parseFloat(commissionRate), 
                commission,
                adjustment,
                netCurrency
            };
            let url = '';
            let reqMethod = '';
            if(props.modalName === 'Add') {
                url = 'AddApiPurchaseConsumption';
                reqMethod = 'POST';
            } else if(props.modalName === 'Edit') {
                url = `UpdateApiPurchaseConsumption?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'PUT';
            } else if(props.modalName === 'Delete') {
                url = `DeleteApiPurchaseConsumption?transactionId=${props.modalData.transactionId}`;
                reqMethod = 'DELETE';
                reqBody = {};
            }
            saveChanges(reqBody, url, reqMethod);
        }
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4>{props.modalName} API Purchase Consumption</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                        <form>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Consumption Date : </label>
                                <div className='col-sm-8'>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={transactionDate} onChange={transactionDateChangeHandler} />
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select ${hasError && supplierId === '-2' ? 'is-invalid': ''}`} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Supplier.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && operatorId === '-2' ? 'is-invalid': ''}`} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Operator.</div>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Region :</label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && regionId === '-2' ? 'is-invalid': ''}`} value={regionId} aria-label=".form-select-sm example" onChange={regionChangeHandler}>
                                        { regionData.map( (value, index) => {
                                            return <option key={`region-${index}`} value={value.regionId}>{value.regionName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select a Region.</div>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Balance Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' disabled className='form-control inputField' style={{textAlign: 'right'}} value={balanceAmount}/>
                                </div>
                            </div>
                            <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Amount : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className={`form-control inputField ${hasError && (netAmount === '0.00' || balanceAmount - netCurrency <= 0) ? 'is-invalid': ''}`} style={{textAlign: 'right'}} value={netAmount} onChange={netAmountChangeHandler} onBlur={e=>setNetAmount(onBlurHandler(e))} />
                                    <div className='invalid-feedback'>Net Consumed should be greater than 0 and lesser than or equal to Balance Amount.</div>
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                <input type='text' readOnly className='form-control inputField' value={percentageTypeName} />
                                </div>
                            </div>
                            {/* <div className={`form-group row has-validation ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission Type : </label>
                                <div className='col-sm-8'>
                                    <select className={`form-select inputField ${hasError && percentageTypeId === '-2' ? 'is-invalid': ''}`} value={percentageTypeId} aria-label=".form-select-sm example" onChange={commTypeChangeHandler}>
                                        { percentageTypeData.map( (value, index) => {
                                            return <option key={`commType-${index}`} value={value.percentageTypeId}>{value.percentageTypeName}</option>
                                        }) }
                                    </select>
                                    <div className='invalid-feedback'>Please select Commission Type.</div>
                                </div>
                            </div> */}
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission % : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commissionRate} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Commission : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={commission} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Difference(+/-) : </label>
                                <div className='col-sm-8'>
                                    <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={adjustment} onChange={adjustmentChangeHandler} onBlur={e=>setAdjustment(onBlurHandler(e))} />
                                </div>
                            </div>
                            <div className={`form-group row ${classes.inputContainer}`}>
                                <label className='col-sm-4 col-form-label inputLabel'>Net Consumed : </label>
                                <div className='col-sm-8'>
                                    <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={netCurrency} />
                                </div>
                            </div>
                        </form>
                    }
                    { props.modalName === 'Delete' && 
                        <div className={classes.text}>Do you want to Delete API Purchase Consumption?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText={props.modalName === 'Delete' ? 'No' : 'Cancel' } />
                <Button btnClass='btn btn-primary' disabled={errorMessage} onClick={saveChangesHandler} btnText={props.modalName === 'Delete' ? 'Yes' : 'Save' } />
            </div>
          </ReactModal>
        </div>
    );

}

export default React.memo(APIPurchaseConsumptionModal);