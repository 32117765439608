import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import CONSTANTS from '../../../../_constants/constants';
import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import { formatInputOnBlur } from '../../../../_helpers/utils';
import { validateDecimalInput } from '../../../../_helpers/validators';
import classes from './ImportLapuSalesModal.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';

const ImportLapuSalesModal = props => {

    const { operatorId, operatorName, closingBalance, openingBalance, lapuNumber } = props.modalData;
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [changedData, setChangedData] = useState({});
    const [supplierName, setSupplierName] = useState(props.modalData.supplierName);
    const [supplierData, setSupplierData] = useState([]);
    const [received, setReceived] = useState();
    const [sales, setSales] = useState();
    const [incentive, setIncentive] = useState();
    const [difference, setDifference] = useState();
    const [supplierAdded, setSupplierAdded] = useState(!!props.modalData.supplierName);
    
    let modalTitle = '';
    if(props.modalName === 'Upload') {
        modalTitle = 'Upload';
    } else {
        modalTitle = supplierAdded ? 'Edit' : 'Add';
    }
    
    useEffect(() => {
        getSupplierList();
        return () => setSupplierName('')
    }, []);

    useEffect(() => {
        // setDifference(Math.round(closingBalance - (openingBalance + parseInt(incentive) + parseInt(received) - parseInt(sales)),2));
        if(!!received && !!sales && !!incentive) {
            let calcDifference = (parseFloat(closingBalance) -( parseFloat(received) - parseFloat(sales) + parseFloat(incentive) + parseFloat(openingBalance))).toFixed(2);
            if(calcDifference === '-0.00') {
                calcDifference = (0).toFixed(2);
            }
            setDifference(calcDifference);
        }
    }, [received, sales, incentive]);

    const customStyles = {
        content: {
          top: '56%',
          left: '50%',
          right: 'auto',
          width: '40%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
    };

    const getSupplierList = () => {
        DropdownDataService.getSupplierList().then( result => {
            if(result.data) {
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }
    
    const afterOpenModal = event => {
        setChangedData(props.modalData);
        setSupplierName(props.modalData.supplierName === '' ? supplierName : props.modalData.supplierName);
        setReceived(props.modalData.received);
        setSales(props.modalData.sales);
        setIncentive(props.modalData.incentive);
        setDifference(parseFloat(props.modalData.difference));
        setSupplierAdded(props.modalData.supplierName === '' ? false : true);
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel', changedData);
    }
    
    const supplierChangeHandler = event => setSupplierName(event.target.value)
    
    const receivedChangeHandler = event => {
        setErrorMessage(null);
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setReceived(value);
            }
        } else {
            setReceived(value);
        }
    }
    
    const salesChangeHandler = event => {
        setErrorMessage(null);
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setSales(value);
            }
        } else {
            setSales(value);
        }
    }

    const incentiveChangeHandler = event => {
        setErrorMessage(null);
        const value = event.target.value;
        if(value !== '') {
            if(validateDecimalInput(value)) {
                setIncentive(value);
            }
        } else {
            setIncentive(value);
        }
    }

    const onBlurHandler = (event, digits = 2) => formatInputOnBlur(event.target.value, digits)

    const saveClickHandler = () => {
        if( props.modalName === 'Upload' ) {
            props.uploadHandler();
        } else if(supplierAdded) {
            saveChangesHandler();
        } else {
            addToLapuHandler();
        }
    }

    const saveChangesHandler = () => {
        const reqBody = {
            received,
            sales,
            incentive,
            difference
        };
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('accessToken')
            },
            body: JSON.stringify(reqBody)
        };
        fetch(`${CONSTANTS.API_URL}DraftSales/UpdateDraftSales?transactionId=${props.modalData.transactionId}`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    changedData.received = received;
                    changedData.sales = sales;
                    changedData.incentive = incentive;
                    changedData.difference = difference;
                    props.onCloseModal('save', changedData);
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
            );
        }
        
        const addToLapuHandler = () => {
            const reqBody = {
                supplierId: supplierData.find(data => data.supplierName === supplierName).supplierId,
                operatorId: operatorId,
                lapuNumber: lapuNumber,
                openingBalance: openingBalance 
            };
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('accessToken')
                },
                body: JSON.stringify(reqBody)
            };
            fetch(`${CONSTANTS.API_URL}Lapu/AddLapu`, requestOptions)
            .then(handleResponse)
            .then(
            (result) => {
                if(result.data) {
                    changedData.supplierId = supplierData.find(data => data.supplierName === supplierName).supplierId;
                    changedData.supplierName = supplierName;
                    setSupplierAdded(true);
                    props.onCloseModal('save', changedData);
                } else if(result.error) {
                    setErrorMessage(result.error.message);
                }
            },
            error => setErrorMessage(error)
        );
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
            <div className='modal-header'>
                <h4> {modalTitle} Lapu Sales</h4>
                <Button btnClass='close' onClick={e => onModalClose(e)} btnType='Close' />
            </div>
            <div className='modal-body' style={{maxHeight: 'calc(82vh - 113px)', overflowY: 'auto'}}>
                <div className='container'>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    { props.modalName !== 'Upload' && <form>
                        <div className={`form-group row ${classes.inputContainer}`}>
                            <label className='col-sm-4 col-form-label inputLabel'>Lapu Number : </label>
                            <div className='col-sm-8'>
                                <input type='text' readOnly className='form-control inputField' value={lapuNumber} />
                            </div>
                        </div>
                        <div className={`form-group row ${classes.inputContainer}`}>
                            <label className='col-sm-4 col-form-label inputLabel'>Supplier : </label>
                            <div className='col-sm-8'>
                                <select value={supplierName} className='form-select' disabled={ supplierAdded ? true : false } onChange={supplierChangeHandler}>
                                    { supplierData.map( (value, index) => {
                                        return <option key={`supplier-${index}`} value={value.supplierName}>{value.supplierName}</option>
                                    }) }
                                </select>
                            </div>
                        </div>
                        {/* { !supplierAdded && 
                            (<div style={{textAlign:'center', marginBottom: '22px', height: '28px'}}>
                                <button className='btn btn-primary' onClick={addToLapuHandler}>Add to Lapu</button>
                            </div>)
                        } */}
                        { supplierAdded && 
                            <>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Operator : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' readOnly className='form-control inputField' value={operatorName} />
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Opening Balance : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={openingBalance} />
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Received : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={received} onChange={receivedChangeHandler} onBlur={ e => setReceived(onBlurHandler(e))}/>
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Sales : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={sales} onChange={salesChangeHandler} onBlur={ e => setSales(onBlurHandler(e))}/>
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Incentive : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' className='form-control inputField' style={{textAlign: 'right'}} value={incentive} onChange={incentiveChangeHandler} onBlur={ e => setIncentive(onBlurHandler(e))}/>
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Difference : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={difference} />
                                    </div>
                                </div>
                                <div className={`form-group row ${classes.inputContainer}`}>
                                    <label className='col-sm-4 col-form-label inputLabel'>Closing Balance : </label>
                                    <div className='col-sm-8'>
                                        <input type='text' readOnly className='form-control inputField' style={{textAlign: 'right'}} value={closingBalance} />
                                    </div>
                                </div>
                            </>
                        }
                    </form> }
                    { props.modalName === 'Upload' && 
                        <div className={classes.text}><b>Difference</b> in all rows is <b>not 0.</b> Do you wish to continue?</div>
                    }
                </div>
            </div>
            <div className='modal-footer' style={{textAlign: 'right'}}>
                <Button btnClass='btn btn-secondary' onClick={e => onModalClose(e)} btnText='Cancel' btnTitle='Cancel' />
                <Button btnClass='btn btn-primary' onClick={saveClickHandler} btnText={`${props.modalName === 'Upload' ? 'Continue' : 'Save'}`} btnTitle={`${props.modalName === 'Upload' ? 'Continue' : 'Save'}`} />
            </div>
          </ReactModal>
        </div>
    );

}

export default ImportLapuSalesModal;