import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';

import { authenticationService } from '../../../../_services/authentication.service';
import AuthImage from '../AuthImage/AuthImage';

import classes from './LoginPage.module.css';

const LoginPage = () => {

    const history = useHistory();

    const [appLogoProps, setAppLogoProps] = useState({});
    const [authImageProps, setAuthImageProps] = useState({});
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => getCompanyDetails(), []);

    const getCompanyDetails = () => {
        authenticationService.masterLogin()
        .then( result => {
            if(result.data) {
                const { appLogoImage, desktopImage, appName } = result.data;
                setAppLogoProps({ appLogoImage, appName });
                setAuthImageProps({ desktopImage, appName });
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        },
        error => setErrorMessage('Something went wrong!'))
    }

    const handleLogin = e => {
        e.preventDefault();
        authenticationService.login(username, password, companyCode)
        .then( result => {
            if(result.data) {
                setErrorMessage('');
                localStorage.getItem('currentUserType') === 'Superuser' ? history.push('operator-mapping') : history.push('/dashboard');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        },
        error => {
            setErrorMessage('Something went wrong!');
        }
        );
    }
    
    const signUpClickHandler = () => {
        history.push('/signup');
    }

    const forgotPasswordClickHandler = () => {
        history.push('/forgot-password');
    }

    useMemo(() => {
        errorMessage === '' ? setError(false): setError(true);
    }, [errorMessage]);

    return (
        <div className={`${classes.authBg} m-0 p-0`} data-layout-config='{"darkMode":false}'>

        <div className={classes.authFluid}>

            <AuthImage {...authImageProps} />
            
            <div className={classes.authFluidFormBox}>
                <div className={`${classes.alignItemsCenter} d-flex`}>
                    <div className="card-body">
                        <div className={`${classes.authBrand} mb-2`}>
                            <a href="#" className={classes.logoLight}>
                            <span><img src={appLogoProps.appLogoImage} alt={appLogoProps.appName} height="56" /></span>
                            </a>
                        </div>
                        <h4 className="mt-0">Login</h4>
                        <p className="text-muted mb-3">Enter Username, Password and Company code to access your account.</p>
                        <form onSubmit={handleLogin}>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input className="form-control" type="text" id="username" required placeholder="Enter your username" onChange={e => setUsername(e.target.value)} autoFocus />
                                <p className="text-muted"><small>Enter Username as <b>Admin</b> for Company login.</small></p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <div className='input-group'>
                                    <input className="form-control" type={showPassword ? 'text' : 'password'} required id="password" placeholder="Enter your password" onChange={e => setPassword(e.target.value)} />
                                    <div className='input-group-addon'>
                                        <div className='input-group-text'>
                                            <span>
                                                { showPassword && <FaEyeSlash title='Hide Password' onClick={e=>setShowPassword(false)}/> }
                                                { !showPassword && <FaRegEye title='Show Password' onClick={e=>setShowPassword(true)} /> }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-muted float-right" onClick={forgotPasswordClickHandler} style={{textDecoration: 'underline', display:'inline', cursor: 'pointer'}}><small>Forgot your password?</small></p>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="companyCode">Company Code</label>
                                <input className="form-control" type="text" required id="companyCode" placeholder="Enter your company code" onChange={e => setCompanyCode(e.target.value)} />
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="btn btn-primary btn-block" type="submit"><i className="mdi mdi-login"></i> Log In </button>
                            </div>
                        </form>
                        { errorMessage && 
                            <div className={`mt-3 ${classes.errorContainer}`}>
                                { error && <div className={`${'alert alert-danger'} ${classes.errorMessage}`} role="alert">{errorMessage}</div> }
                            </div>
                        }
                        <footer className={`${classes.footer} ${classes.footerAlt}`}>
                            <span className="text-muted">Don't have an account? 
                                <p onClick={signUpClickHandler} className="authPageLink">Sign Up</p>
                            </span>
                        </footer>

                    </div>
                </div>
            </div>
        </div>

        <script src="assets/js/vendor.min.js"></script>
        <script src="assets/js/app.min.js"></script>

    </div>
    );
}

export default LoginPage;