import React, { useEffect, useState } from 'react';

import CONSTANTS from '../../../../_constants/constants';
import { apiDate } from '../../../../_helpers/utils';
import { validateEmailInput, validatePincodeInput } from '../../../../_helpers/validators';
import { ApiService } from '../../../../_services/api.service';
import FormGroup from '../../../UI/Form/FormGroup/FormGroup';
import Modal from '../../../UI/Modal/Modal';
import classes from './CompanyModal.module.css';

const INITIAL_STATE = {
    companyName: '',
    companyCode: '',
    address1: '',
    address2: '',
    city: '',
    pincode: '',
    state: '',
    gstin: '',
    phoneNumber: '',
    email: '',
    // planName: '',
    // expiryDate: uiDateToday(),
    // dayCost: 0
};

const INITIAL_STATUS = {
    email: false,
    pincode: false
};

const CompanyModal = props => {

    // const [companyName, setCompanyName] = useState('');
    // const [companyCode, setCompanyCode] = useState('');
    // const [address1, setAddress1] = useState('');
    // const [address2, setAddress2] = useState('');
    // const [city, setCity] = useState('');
    // const [pincode, setPincode] = useState('');
    // const [state, setState] = useState('');
    // const [gstin, setGstin] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('');
    // const [email, setEmail] = useState('');
    // const [planName, setPlanName] = useState('');
    // const [expiryDate, setExpiryDate] = useState('');
    // const [dayCost, setDayCost] = useState('');
    const [state, setState] = useState({...INITIAL_STATE});
    const [formStatus, setFormStatus] = useState({...INITIAL_STATUS});
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Company`;

    useEffect(() => {
        return () => clearFields()
    }, []);

    const clearFields = () => {
        setState({...INITIAL_STATE});
    }
    
    // Modal Handlers
    const afterOpenModal = event => {
        setHasError(false);
        setErrorMessage(null);
        if( props.modalName === 'Edit' ) {
            props.modalData.expiryDate = apiDate(props.modalData.expiryDate);
            setState({...props.modalData});
        }
        props.onAfterOpen(event, 'After Modal Opened');
    }

    const onModalClose = event => {
        clearFields();
        props.onCloseModal('cancel');
    }
    
    // Change Handler
    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.type == 'checkbox' ? evt.target.checked : evt.target.value;
        setState({
          ...state,
          [name]: value
        });
    }

    const validateFields = () => {
        const formValidStatus = {
            email: !!validateEmailInput(state.email),
            pincode: !!validatePincodeInput(state.pincode),
            companyName: state.companyName !== '',
            companyCode: state.companyCode !== '',
            address01: state.address01 !== '',
            gstin: state.gstin !== '',
            // planName: state.planName !== '',
            // expiryDate: state.expiryDate !== '',
            // dayCost: state.dayCost > 0,
        };
        setFormStatus(formValidStatus);
        const formValid = Object.values(formValidStatus).every((v) => v === true);
        // setFormValid(formValid);
        return formValid;
    }

    // API functions
    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Company/`;
        if(props.modalName !== 'Delete' && !validateFields() ) {
            setHasError(true);
        } else {
            setHasError(false);
            const apiBody = {
                companyName: state.companyName,
                companyCode: state.companyCode,
                address1: state.address1,
                address2: state.address2,
                city: state.city,
                pincode: state.pincode,
                state: state.state,
                gstin: state.gstin,
                phoneNumber: state.phoneNumber,
                email: state.email,
                // planName: state.planName,
                // expiryDate: state.expiryDate,
                // dayCost: state.dayCost
            };
            if(props.modalName === 'Edit') {
                ApiService.updateData(`${apiUrl}UpdateCompany?companyId=${props.modalData.companyId}`, {...apiBody}).then(handleApiResponse);
            } else if(props.modalName === 'Delete') {
                ApiService.deleteData(`${apiUrl}DeleteCompany?companyId=${props.modalData.companyId}`, props.modalData).then(handleApiResponse);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
            style={{top: '54%', width: '32%'}}
            labelWidth={4}
            fieldWidth={8}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit')  &&
                <form style={{ height: 'calc(82vh - 185px)', overflowY: 'auto' }}>
                    <FormGroup 
                        label='Company Name'
                        name='companyName'
                        value={state.companyName}
                        changeHandler={handleChange} 
                        hasError={hasError && state.companyName === ''}
                        errorMessage='Please enter Company Name.'
                        autoFocus
                    />
                    <FormGroup 
                        label='Company Code'
                        name='companyCode'
                        value={state.companyCode}
                        changeHandler={handleChange} 
                        hasError={hasError && state.companyCode === ''}
                        errorMessage='Please enter Company Code.'
                    />
                    <FormGroup 
                        label='Address Line 1'
                        name='address1'
                        value={state.address1}
                        changeHandler={handleChange} 
                        hasError={hasError && state.address1 === ''}
                        errorMessage='Please enter Address.'
                    />
                    <FormGroup 
                        label='Address Line 2'
                        name='address2'
                        value={state.address2}
                        changeHandler={handleChange} 
                    />
                    <FormGroup 
                        label='City'
                        name='city'
                        value={state.city}
                        changeHandler={handleChange} 
                        hasError={hasError && state.city === ''}
                        errorMessage='Please enter City.'
                    />
                    <FormGroup 
                        label='Pincode'
                        name='pincode'
                        value={state.pincode}
                        changeHandler={handleChange} 
                        hasError={hasError && !formStatus.pincode}
                        errorMessage='Please enter valid Pincode.'
                    />
                    <FormGroup 
                        label='State'
                        name='state'
                        value={state.state}
                        changeHandler={handleChange} 
                        hasError={hasError && state.state === ''}
                        errorMessage='Please enter State.'
                    />
                    <FormGroup 
                        label='GSTIN'
                        name='gstin'
                        value={state.gstin}
                        changeHandler={handleChange} 
                        hasError={hasError && state.gstin === ''}
                        errorMessage='Please enter GSTIN.'
                    />
                    <FormGroup 
                        label='Phone Number'
                        name='phoneNumber'
                        value={state.phoneNumber}
                        changeHandler={handleChange} 
                        hasError={hasError && state.phoneNumber === ''}
                        errorMessage='Please enter Phone Number.'
                    />
                    <FormGroup 
                        label='Email'
                        name='email'
                        value={state.email}
                        changeHandler={handleChange} 
                        hasError={hasError && !formStatus.email}
                        errorMessage='Please enter valid Email.'
                    />
                    {/* <FormGroup 
                        label='Plan Name'
                        name='planName'
                        value={state.planName}
                        changeHandler={handleChange} 
                        hasError={hasError && state.planName === ''}
                        errorMessage='Please enter Plan Name.'
                    />
                    <FormGroup 
                        type='date'
                        label='Expiry Date'
                        name='expiryDate'
                        value={state.expiryDate}
                        changeHandler={handleChange} 
                        hasError={hasError && state.expiryDate === ''}
                        errorMessage='Please enter Expiry Date.'
                    />
                    <FormGroup 
                        label='Day Cost'
                        name='dayCost'
                        value={state.dayCost}
                        changeHandler={handleChange} 
                        hasError={hasError && state.dayCost <= 0}
                        errorMessage='Please enter valid Cost.'
                    /> */}
                </form>
            } 
            { props.modalName === 'Delete' &&
                <div className={classes.text}>Do you want to Delete Company <b>({props.modalData.companyName})</b>?</div>
            }
        </Modal>
        
    );

}

export default React.memo(CompanyModal);