import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import StaffModal from './StaffModal';
import CONSTANTS from '../../../../_constants/constants';
import classes from './StaffMasterPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import SearchBar from '../../../UI/SearchBar/SearchBar';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const StaffMasterPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalData, setModalData] = useState({});
    const [filterKeyword, setFilterKeyword] = useState('');
    
    const tableHeaders = ['Staff Name', 'User Name'];
    const dataFields = ['staffName', 'userName'];
    const tableConfig = {
        headerAlignConfig: {
            'Staff Name': 'left',
            'User Name': 'left'
        },
        widthConfig: {
            'Staff Name': 'auto',
            'User Name': 'auto'
        },
        alignConfig: {
            'staffName': 'left',
            'userName': 'left'
        }
    }


    useEffect(() => {
        getStaffData();
    }, []);

    const modalCloseHandler = status => {
        setModalIsOpen(false);
        if(status === 'save') {
            getStaffData();
        }
    }

    const getStaffData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        fetch(`${CONSTANTS.API_URL}Staff/AllStaff`, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const modalOpenHandler = (modalName, modalData) => {
        setModalIsOpen(true);
        setModalName(modalName);
        modalData === '' ? setModalData({}) : setModalData(modalData);
    }
    
    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const filterKeywordChangeHandler = event => setFilterKeyword(event.target.value);

    const filterDataHandler = filteredData => {
        return filteredData.filter( data =>
            (data.staffName.toLowerCase().includes(filterKeyword.toLowerCase())) ||
            (data.userName.toLowerCase().includes(filterKeyword.toLowerCase()))
        )
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Staff Master'>
                    <div className='row mb-3'>
                        <div className={`col-12 ${classes.headerActions}`}>
                            <SearchBar onFilterKeywordChange={filterKeywordChangeHandler} />
                            <div>
                                <Button btnClass='btn btn-primary btn-block' onClick={e => modalOpenHandler('Add', '')} btnText='Add Staff' btnType='Add' />
                                { modalIsOpen && <StaffModal
                                    modalData={modalData}
                                    modalName={modalName}
                                    isModalOpened={modalIsOpen}
                                    onCloseModal={modalCloseHandler}
                                    onAfterOpen={handleAfterOpen}
                                />}
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        onModalOpen={modalOpenHandler} 
                        sort={true}
                        actions={true}
                        edit={true} 
                        delete={true} 
                        filter={true}
                        paginate={false}
                        rowClick={false}
                        gridData={gridData}
                        filterKeyword={filterKeyword}
                        onFilterData={filterDataHandler}
                    />
            </PageLayout>
            )}    
        </>
    );
  
}

export default StaffMasterPage;