import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';

import Alert from '../../../UI/Alert/Alert';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const SupplierWiseSimWiseDailyCurrencySalesPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});

    const tableHeaders = ['Supplier', 'Operator', 'Lapu Number', 'Sales Date', 'Sales', 'Incentive', 'Difference(+/-)'];
    const dataFields = ['supplierName', 'operatorName', 'lapuNumber', 'salesDate', 'totalSales', 'totalIncentive', 'totalAdjustment'];
    const csvHeaders = [
        { label: 'Supplier', key: 'supplierName' },
        { label: 'Operator', key: 'operatorName' },
        { label: 'Lapu Number', key: 'lapuNumber' },
        { label: 'Sales Date', key: 'salesDate' },
        { label: 'Sales', key: 'totalSales' },
        { label: 'Incentive', key: 'totalIncentive' },
        { label: 'Difference(+/-)', key: 'totalAdjustment' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Supplier': 'left', 
            'Operator': 'center', 
            'Lapu Number': 'center', 
            'Sales Date': 'center', 
            'Sales': 'center',
            'Incentive': 'center',
            'Difference(+/-)': 'center'
        },
        widthConfig: {
            'Supplier': 'auto', 
            'Operator': '122px', 
            'Lapu Number': '122px', 
            'Sales Date': '122px', 
            'Sales': '122px',
            'Incentive': '122px',
            'Difference(+/-)': '122px'
        },
        alignConfig: {
            'supplierName': 'left',
            'operatorName': 'center',
            'lapuNumber': 'left',
            'salesDate': 'center',
            'totalSales': 'right',
            'totalIncentive': 'right',
            'totalAdjustment': 'right'
        }
    };

    useEffect(() => { getTransferDetailedData() }, []);

    const getTransferDetailedData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = '';
        let supplierId = window.supplierId;
        let operatorId = window.operatorId;
        url = (supplierId === '-1' && operatorId === '-1') ?
                `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseDailyCurrencySales?startDate=${window.fromDate}&endDate=${window.toDate}&lapuId=${window.lapuId}` :
                supplierId === '-1' ?
                    `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseDailyCurrencySales?startDate=${window.fromDate}&endDate=${window.toDate}&operatorId=${operatorId}&lapuId=${window.lapuId}` :
                    operatorId === '-1' ?
                        `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseDailyCurrencySales?startDate=${window.fromDate}&endDate=${window.toDate}&supplierId=${supplierId}&lapuId=${window.lapuId}` :
                        `${CONSTANTS.API_URL}Reports/SupplierwiseSimwiseDailyCurrencySales?startDate=${window.fromDate}&endDate=${window.toDate}&supplierId=${supplierId}&operatorId=${operatorId}&lapuId=${window.lapuId}`
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.salesDate = data.salesDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['totalSales', 'totalIncentive', 'totalAdjustment'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    setPageCount(Math.ceil(result.data.results.length/10));
                    let total = result.data.header;
                    const headerKeysToParse = ['salesAmount', 'incentive', 'adjustment'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        '',
                        '',
                        total.salesAmount,
                        total.incentive,
                        total.adjustment
                    ]);
                    setExportTotalData({
                        supplierName: 'Total',
                        operatorName: '',
                        lapuNumber: '',
                        salesDate: '',
                        totalSales: total.salesAmount,
                        totalIncentive: total.incentive,
                        totalAdjustment: total.adjustment
                    });
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            (error) => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const getExportFileName = () => {
        let exportFilename = 'SupplierSimWiseDailyCurrencySales';
        exportFilename += '_' + window.fromDate;
        exportFilename += '_' + window.toDate;
        exportFilename += window.supplierName !== '' ? '_' + window.supplierName : '';
        exportFilename += window.operatorName !== '' ? '_' + window.operatorName : '';
        exportFilename += window.lapuNumber !== '' ? '_' + window.lapuNumber : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader /> }
            { !isLoading && (
                <PageLayout pageTitle='Supplier Wise Sim Wise Daily Currency Sales'>
                    <div className='row'>
                        <div className='col-12'>
                            { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                            { gridData.length > 0 && 
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ExportToCSV 
                                        headers={csvHeaders}
                                        exportTotalData={exportTotalData}
                                        gridData={gridData}
                                        exportFileName={exportFileName} 
                                        exportClickHandler={getExportFileName} 
                                        enableExport={enableExport}
                                    />
                                </div>
                            }
                            <Table 
                                tableHeaders={tableHeaders} 
                                dataFields={dataFields} 
                                tableConfig={tableConfig}
                                pageCount={pageCount} 
                                sort={false}
                                actions={false} 
                                view={false}
                                paginate={true}
                                filter={false}
                                gridData={gridData}
                                totalData={totalData}
                            />
                        </div>
                    </div>
                </PageLayout>
            ) }    
        </>
    );
  
}

export default SupplierWiseSimWiseDailyCurrencySalesPage;