import classes from './Loader.module.css';

const Loader = () => {
    return (
        <div className={classes.loader}>
            Loading...
        </div>
    );
}

export default Loader;