import React, { useState, useEffect } from 'react'; 

import { handleResponse } from '../../../../_helpers/handle-response';
import { DropdownDataService } from '../../../../_services/dropdownData.service';
import CONSTANTS from '../../../../_constants/constants';
import { parseFloat2Decimal, parseFloat2DecimalHeader } from '../../../../_helpers/utils';
import classes from './DailyProfitPage.module.css';

import Alert from '../../../UI/Alert/Alert';
import Button from '../../../UI/Button/Button';
import ExportToCSV from '../../../UI/ExportToCSV/ExportToCSV';
import Loader from '../../../UI/Loader/Loader';
import Table from '../../../UI/Table/Table';
import PageLayout from '../../../Layout/PageLayout/PageLayout';

const DailyProfitPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fromDate, setFromDate] = useState(new Date().toISOString().slice(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0,10));
    const [supplierId, setSupplierId] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [day, setDay] = useState('All');
    const [supplierData, setSupplierData] = useState([]);
    const [operatorData, setOperatorData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [exportFileName, setExportFileName] = useState('');
    const [enableExport, setEnableExport] = useState(false);
    const [exportTotalData, setExportTotalData] = useState({});
    
    const tableHeaders = ['Date', 'Day', 'Total Sales', 'API Sales', 'Local Market Sales', 'Commission Received', 'Commission Given (Local)', 'Commission Given (API)', 'Incentive', 'Profit', 'Difference'];
    const dataFields = ['salesDate', 'weekDay', 'totalSales', 'apiSales', 'localMarketSales', 'commissionReceived', 'commissionGivenLocal', 'commissionGivenApi', 'incentive', 'netProfit', 'difference'];
    // const csvHeaders = ['Date', 'Day', 'Sales', 'Commission Received', 'Commission Paid', 'Commission Margin', 'Incentive', 'Net Profit'];
    const csvHeaders = [
        { label: 'Date', key: 'salesDate' },
        { label: 'Day', key: 'weekDay' },
        { label: 'Total Sales', key: 'totalSales' },
        { label: 'API Sales', key: 'apiSales' },
        { label: 'Local Market Sales', key: 'localMarketSales' },
        { label: 'Commission Received', key: 'commissionReceived' },
        { label: 'Commission Given (Local)', key: 'commissionGivenLocal' },
        { label: 'Commission Given (API)', key: 'commissionGivenApi' },
        { label: 'Incentive', key: 'incentive' },
        { label: 'Profit', key: 'netProfit' },
        { label: 'Difference', key: 'difference' }
    ];
    const tableConfig = {
        headerAlignConfig: {
            'Date': 'left', 
            'Day': 'center',
            'Total Sales': 'center', 
            'API Sales': 'center', 
            'Local Market Sales': 'center',
            'Commission Received': 'center',
            'Commission Given (Local)': 'center',
            'Commission Given (API)': 'center',
            'Incentive': 'center',
            'Profit': 'center',
            'Difference': 'center'
        },
        widthConfig: {
            'Date': 'auto', 
            'Day': '124px',
            'API Sales': '162px', 
            'Total Sales': '162px',
            'Local Market Sales': '162px',
            'Commission Received': '162px', 
            'Commission Given (Local)': '162px',
            'Commission Given (API)': '162px',
            'Incentive': '162px',
            'Profit': '162px',
            'Difference': '162px'
        },
        alignConfig: {
            'salesDate': 'left',
            'weekDay': 'center',
            'apiSales': 'right',
            'totalSales': 'right',
            'localMarketSales': 'right',
            'commissionReceived': 'right',
            'commissionGivenLocal': 'right',
            'commissionGivenApi': 'right',
            'incentive' : 'right',
            'netProfit': 'right',
            'difference': 'right'
        }
    };
    const dayData = ['All', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    useEffect(() => {
        getSupplierList();
        getOperatorList();
    }, []);

    useEffect(() => {
        setErrorMessage(null);
        setEnableExport(false);
    }, [fromDate, toDate, supplierId, operatorId, day]);

    const getSupplierList = () => {
        DropdownDataService.getSupplierList('filter').then( result => {
            if(result.data) {
                setSupplierId(result.data.results[0].supplierId);
                setSupplierData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getOperatorList = () => {
        DropdownDataService.getOperatorList('filter').then( result => {
            if(result.data) {
                setOperatorId(result.data.results[0].operatorId);
                setOperatorData(result.data.results);
            } else {
                setErrorMessage(result);
            }
        });
    }

    const getReportData = () => {
        const requestOptions = {
            headers: { 
                'Token': localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
        };
        let url = `${CONSTANTS.API_URL}Reports/DailyProfitReport?startDate=${fromDate}&endDate=${toDate}`;
        if(supplierId !== '-1') { url = url + `&supplierId=${supplierId}` }
        if(operatorId !== '-1') { url = url + `&operatorId=${operatorId}` }
        if(day !== 'All') { url = url + `&weekDay=${day}` }
        fetch(url, requestOptions)
        .then(handleResponse)
        .then(
            (result) => {
                if(result.data) {
                    setIsLoading(false);
                    result.data.results.map( data => 
                        data.salesDate = data.salesDate.split(' ')[0].split('-').reverse().join('/')
                    );
                    const keysToParse = ['totalSales', 'apiSales', 'localMarketSales', 'commissionReceived', 'commissionGivenLocal', 'commissionGivenApi', 'incentive', 'netProfit', 'difference'];
                    parseFloat2Decimal(result.data.results, keysToParse);
                    setGridData(result.data.results);
                    let total = result.data.header;
                    const headerKeysToParse = ['totalSales', 'apiSales', 'localMarketSales', 'commissionReceived', 'commissionGivenLocal', 'commissionGivenApi', 'incentive', 'netProfit', 'difference'];
                    parseFloat2DecimalHeader(total, headerKeysToParse);
                    setTotalData([
                        '',
                        total.totalSales,
                        total.apiSales,
                        total.localMarketSales,
                        total.commissionReceived,
                        total.commissionGivenLocal,
                        total.commissionGivenApi,
                        total.incentive,
                        total.netProfit,
                        total.difference
                    ]);
                    setExportTotalData({
                        salesDate: 'Total',
                        weekDay: '',
                        totalSales: total.totalSales,
                        apiSales: total.apiSales,
                        localMarketSales: total.localMarketSales,
                        commissionReceived: total.commissionReceived,
                        commissionGivenLocal: total.commissionGivenLocal,
                        commissionGivenApi: total.commissionGivenApi,
                        incentive: total.incentive,
                        netProfit: total.netProfit,
                        difference: total.difference
                    });
                    setPageCount(Math.ceil(result.data.results.length/10));
                    setEnableExport(result.data.results.length > 0 ? true : false);
                } else {
                    setIsLoading(false);
                    setErrorMessage(result.error.message);
                }
            },
            error => {
                setIsLoading(false);
                setErrorMessage(error);
            }
        );
    }

    const showClickHandler = () => {
        setIsLoading(true);
        getReportData();
    }

    const fromDateChangeHandler = event => setFromDate(event.target.value)
    
    const toDateChangeHandler = event => setToDate(event.target.value)

    const supplierChangeHandler = event => setSupplierId(event.target.value)
    
    const operatorChangeHandler = event => setOperatorId(event.target.value)

    const dayChangeHandler = event => setDay(event.target.value)

    const getExportFileName = () => {
        let exportFilename = 'DailyProfit';
        exportFilename += '_' + fromDate;
        exportFilename += '_' + toDate;
        exportFilename += supplierId !== '-1' ? '_' + supplierData.find(data => data.supplierId == supplierId)['supplierName'] : '';
        exportFilename += operatorId !== '-1' ? '_' + operatorData.find(data => data.operatorId == operatorId)['operatorName'] : '';
        exportFilename += day !== 'All' ? '_' + day : '';
        setExportFileName(exportFilename);
    }

    return (
        <>
            { isLoading && <Loader />}
            { !isLoading && (
                <PageLayout pageTitle='Daily Profit'>
                    <div className='row mb-1'>
                        <div className='col-12' style={{display: 'flex', justifyContent:'space-between', marginTop: '6px', marginBottom: '6px'}}>
                            <form className='form-inline'>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>From : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField dateField' value={fromDate} onChange={fromDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>To : </label>
                                    <input type='date' onKeyDown={(e) => e.preventDefault()} required className='form-control inputField' value={toDate} onChange={toDateChangeHandler} />
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Supplier : </label>
                                    <select className='form-select' style={{width: '190px'}} value={supplierId} aria-label=".form-select-sm example" onChange={supplierChangeHandler}>
                                        { supplierData.map( (value, index) => {
                                            return <option key={`supplier-${index}`} className='dropdownItem' value={value.supplierId}>{value.supplierName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Operator : </label>
                                    <select className='form-select' style={{width: '150px'}} value={operatorId} aria-label=".form-select-sm example" onChange={operatorChangeHandler}>
                                        { operatorData.map( (value, index) => {
                                            return <option key={`operator-${index}`} className='dropdownItem' value={value.operatorId}>{value.operatorName}</option>
                                        }) }
                                    </select>
                                </div>
                                <div className={`form-group ${classes.inputContainer}`}>
                                    <label className='col-form-label inputLabel'>Week Day : </label>
                                    <select className='form-select' value={day} style={{width: '140px'}} aria-label=".form-select-sm example" onChange={dayChangeHandler}>
                                        { dayData.map( (day, index) => {
                                            return <option key={`day-${index}`} className='dropdownItem' value={day}>{day}</option>
                                        }) }
                                    </select>
                                </div>
                            </form>
                            <div>
                                <Button btnClass='btn btn-primary mr-3' onClick={showClickHandler} btnText='Show' />
                                <ExportToCSV 
                                    headers={csvHeaders}
                                    exportTotalData={exportTotalData}
                                    gridData={gridData}
                                    exportFileName={exportFileName} 
                                    exportClickHandler={getExportFileName} 
                                    enableExport={enableExport}
                                />
                            </div>
                        </div>
                    </div>
                    { errorMessage && <Alert alertClassName='danger' message={errorMessage} /> }
                    <Table 
                        tableHeaders={tableHeaders} 
                        dataFields={dataFields} 
                        tableConfig={tableConfig}
                        pageCount={pageCount} 
                        sort={false}
                        actions={false}
                        view={false} 
                        paginate={true}
                        filter={false}
                        gridData={gridData}
                        totalData={totalData}
                    />
                </PageLayout>
            )}    
        </>
    );
  
}

export default DailyProfitPage;