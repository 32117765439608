import React, { useEffect, useState } from 'react';

import classes from './SupplierModal.module.css';
import CONSTANTS from '../../../../_constants/constants';
import { ApiService } from '../../../../_services/api.service';

import Modal from '../../../UI/Modal/Modal';
import InputGroup from '../../../UI/Form/InputGroup/InputGroup';

const SupplierModal = props => {

    const [supplierName, setSupplierName] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const modalName = `${props.modalName} Supplier`;
    
    useEffect(() => {
        return () => {
            setSupplierName('');
            setErrorMessage(null);
        }
    }, []);

    const afterOpenModal = event => {
        setHasError(false);
        setSupplierName(Object.keys(props.modalData).length > 0 ? props.modalData.supplierName : '');
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        setHasError(false);
        props.onCloseModal('cancel');
    }

    const supplierChangeHandler = event => {
        if(event.target.value !== '') {
            setHasError(false);
        }
        setSupplierName(event.target.value);
    }

    const handleApiResponse = (result, error) => {
        if(!!result) {
            if(result.data) {
                props.onCloseModal('save');
            } else if(result.error) {
                setErrorMessage(result.error.message);
            }
        } else if (error) {
            setErrorMessage(error);
        }
    }

    const addData = (url, reqBody) => ApiService.postData(url, reqBody).then(handleApiResponse)

    const editData = (url, reqBody) => ApiService.updateData(url, reqBody).then(handleApiResponse)

    const deleteData = (url, reqBody) => ApiService.deleteData(url, reqBody).then(handleApiResponse)

    const saveChangesHandler = () => {
        let apiUrl = `${CONSTANTS.API_URL}Supplier/`;
        if(props.modalName !== 'Delete' && supplierName === '' ) {
            setHasError(true);
        } else {
            setHasError(false);
            let reqBody = {};
            let url = '';
            if(props.modalName === 'Add') {
                reqBody = {supplierName};
                url = 'AddSupplier';
                addData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Edit') {
                reqBody = {supplierName};
                url = `UpdateSupplier?supplierId=${props.modalData.supplierId}`;
                editData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Delete') {
                reqBody = props.modalData;
                url = `DeleteSupplier?supplierId=${props.modalData.supplierId}`;
                deleteData(apiUrl+url, reqBody);
            } else if(props.modalName === 'Activate') {
                url = `ActivateSupplier?supplierId=${props.modalData.supplierId}`;
                editData(apiUrl+url);
            } else if(props.modalName === 'Deactivate') {
                url = `DeActivateSupplier?supplierId=${props.modalData.supplierId}`;
                editData(apiUrl+url);
            }
        }
    }

    return (
        <Modal
            isModalOpened={props.isModalOpened}
            onModalClose={onModalClose}
            afterOpenModal={afterOpenModal}
            modalName={modalName}
            onSaveChanges={saveChangesHandler}
            errorMessage={errorMessage}
        >
            { (props.modalName === 'Add' || props.modalName === 'Edit') &&
                <InputGroup 
                    label='Supplier'
                    value={supplierName}
                    changeHandler={supplierChangeHandler} 
                    hasError={hasError}
                    errorMessage='Please enter supplier name.'
                    autoFocus
                />
            }
            { (props.modalName === 'Delete' || props.modalName === 'Activate' || props.modalName === 'Deactivate') &&
                <div className={classes.text}>Do you want to {props.modalName} Supplier <b>({props.modalData.supplierName})</b>?</div>
            }
        </Modal>
    );

}

export default React.memo(SupplierModal);