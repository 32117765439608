import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const BarChart = props => {

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    getChartData();
  }, []);

  const getChartData = () => {
    if(!(Object.entries(props.chartData).length === 0 && props.chartData.constructor === Object)) {
      let dayData = [];
      let salesData = [];
      let incentiveData = [];
      let purchaseData = [];
      let commissionData = [];
      for (const [key, value] of Object.entries(props.chartData)) {
        dayData.push(value['day']);
        salesData.push(parseFloat(value['sales']).toFixed(2));
        incentiveData.push(parseFloat(value['incentive']).toFixed(2));
        purchaseData.push(parseFloat(value['purchase']).toFixed(2));
        commissionData.push(parseFloat(value['commission']).toFixed(2));
      }
      setSeries(
        [{
            name: 'Sales',
            data: salesData
            // data: [2000, 100, 50, 300, 4000, 700, 5000]
        }, {
            name: 'Incentive',
            data: incentiveData
            // data: [1000, 1500, 500, 3000, 400, 700, 5000]
        }, {
            name: 'Purchase',
            data: purchaseData
            // data: [2000, 100, 50, 300, 4000, 700, 5000]
        }, {
            name: 'Commission',
            data: commissionData
            // data: [1000, 1500, 500, 3000, 400, 700, 5000]
        }]
      );
      setOptions({
        chart: {
          type: 'bar',
          foreColor: '#98a6ad',
          fontFamily: 'Helvetica, Arial, sans-serif',
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        title: {
          // text: 'Weekly Graph',
          // align: 'left',
          // margin: 10,
          // offsetX: 6,
          // offsetY: 4,
          // style: {
          //   fontFamily: 'Nunito',
          //   color: 'rgb(51, 51, 51)', used
          //   color: '#838c96',
          //   fontSize: '14px',
          //   fontWeight: 700
          // }
        },
        colors: ['rgb(66, 210, 157)', 'rgb( 54, 136, 252)', 'rgb(250, 103, 103)', 'rgb(249, 188, 13)'],
        // colors: ['#6AB187', '#D2D6D1', '#ffc107', '#17a2b8'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '60%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: dayData,
          labels: {
            style: {
              fontSize: '11px',
              fontWeight: 500,
            }
          }
        },
        yaxis: {
          title: {
            text: '',
            style: {
              // color: '#838c96',
              color: 'rgb(51, 51, 51)',
              fontSize: '12px',
              fontWeight: 600,
            }
          },
          labels: {
            style: {
              fontSize: '11px',
              fontWeight: 400,
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            }
          }
        }
      });
    }
  }
  
  return (
    <Chart options={options} series={series} type='bar' height={420} />
  );

}

export default BarChart;